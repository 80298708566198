import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import getEnv from '../../config/Env';
import { baseQueryWithReauth, prepareHeaders } from '../api';

const baseUrl = getEnv('LOG_API_URL');

const logApiQuery = fetchBaseQuery({
    baseUrl,
    prepareHeaders,
});

export const logApi = createApi({
    reducerPath: 'logApi',
    baseQuery: (args, api, extraOptions) => baseQueryWithReauth(args, api, extraOptions, logApiQuery),
    endpoints: (builder) => ({
        getAuditToken: builder.mutation({
            query: (body) => ({
                url: '/audits/token',
                method: 'PUT',
                body,
            }),
        }),
    }),
});

export const { useGetAuditTokenMutation } = logApi;
