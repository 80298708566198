import { useGetGuardReportsQuery } from 'features/publications/guardApi';
import HelperFunctions from '../../../../global/HelperFunctions';
import { useGetGuardApiToken } from 'pages/publications_v2/views/guard/hooks/useGetGuardApiToken';

export function useGetGuardReports(guardEntityUri) {
    const token = useGetGuardApiToken();

    return useGetGuardReportsQuery(
        { uri: guardEntityUri, token },
        {
            selectFromResult: (result) => HelperFunctions.queryResult(result, false),
            skip: !guardEntityUri,
        },
    );
}
