import { useGetPublicationGroupsQuery } from 'features/publications/publicationApi';
import HelperFunctions from '../../global/HelperFunctions';
import { useActiveOrganisation } from 'hooks/useActiveOrganisation';
import { useMemo } from 'react';
import { createSelector } from '@reduxjs/toolkit';

const emptyArray = [];

export function useGetPublicationGroups(returnAsNested = true, returnAsResult = false) {
    const activeOrganisation = useActiveOrganisation();

    const filterGroups = useMemo(() => {
        // Return a unique selector instance for this page so that
        // the filtered results are correctly memoized
        return createSelector(
            (res) => res.data,
            (res, organisationId) => organisationId,
            (res, organisationId, returnAsNested) => returnAsNested,
            (data, organisationId, returnAsNested) => {
                if (!data) {
                    return emptyArray;
                }

                const publicationGroups = data.filter((_group) => _group.organisationId === organisationId);

                if (!returnAsNested) {
                    return publicationGroups;
                }

                return publicationGroups
                    .filter((_group) => _group.parent === null)
                    .map((_group) => ({
                        ..._group,
                        children: _group.children
                            .map((childUri) => publicationGroups.find((_item) => _item['@id'] === childUri))
                            .sort(HelperFunctions.dynamicSort('sortOrder')),
                    }))
                    .sort(HelperFunctions.dynamicSort('sortOrder'));
            },
        );
    }, []);

    const results = useGetPublicationGroupsQuery(undefined, {
        selectFromResult: (result) => {
            const items = filterGroups(result, activeOrganisation, returnAsNested);

            return {
                publicationGroups: items,
                isLoading: result.isUninitialized || (result.isFetching && items.length === 0),
            };
        },
    });

    if (returnAsResult) {
        return results;
    }

    return results.publicationGroups;
}
