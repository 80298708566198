import Block from '../BlockParser';
import DocumentHelper from 'pages/global/DocumentHelper';

export default function LeftSideBlocks({ area, areaBlocks, baseBlocks, leftSideProps }) {
    const blockIdsInLayout = DocumentHelper.getBlockIdsInLayout(baseBlocks);

    return (
        <>
            {baseBlocks
                .filter((block) => blockIdsInLayout.includes(block.key) === false)
                .map((block) => {
                    return (
                        <Block
                            block={block}
                            areaBlocks={areaBlocks}
                            baseBlocks={baseBlocks}
                            {...leftSideProps}
                            key={area.id + '_' + block.key}
                        />
                    );
                })}
        </>
    );
}
