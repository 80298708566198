import Constants from '../../../../config/Constants';
import Language from '../../../../language/Language';
import CustomIcons from '../../../global/CustomIcons';
import { LinkedBlockLabel } from '../_BlockParser/EditorToolbar';
import BlockName from '../_BlockParser/BlockName';
import { Block } from '../BlockParser';
import { BlockHeightWrapper } from './BlockHeightWrapper';
import { Badge } from 'react-bootstrap';
import { BlockType } from 'pages/documents_v2/views/edit_area/blocks/BlockType';
import { useTranslation } from 'react-i18next';
import { useIsBlockInLayout } from 'pages/documents_v2/hooks/useIsBlockInLayout';

export function BlockWrapper({ blockId, showToolbar = false, props }) {
    const { block, editorDisplaySection } = props;
    const { t } = useTranslation('documents');
    const blockIsInLayout = useIsBlockInLayout(block.key);

    const blockDisplayOptions = props.blockDisplayOptions
        ? props.blockDisplayOptions
        : Constants.defaultBlockDisplayOptions;
    const markBlockDeleted = blockDisplayOptions.markDeletedBlocks && block.deleted;
    const markBlockScheduledForkDeletion = blockDisplayOptions.markDeletedBlocks && block.scheduledForDeletion;
    const showDeleteUndo = blockDisplayOptions.canRestoreDeletedBlocks;

    return (
        <BlockHeightWrapper
            blockId={blockId}
            blockIsInLayout={blockIsInLayout}
            editorDisplaySection={editorDisplaySection}
            markAsDeleted={
                blockDisplayOptions.markDeletedBlocks && (markBlockDeleted || markBlockScheduledForkDeletion)
            }
        >
            <>
                {markBlockDeleted && (
                    <div className="py-2 uk-background-danger-light uk-text-danger text-center small">
                        {Language.getTranslation(showDeleteUndo ? 'blockWillBeDeletedOnSave' : 'blockDeleted')}
                        {showDeleteUndo && (
                            <a onClick={() => props.toggleDeleteBlock(blockId, false)}>
                                <CustomIcons icon="undo" className="ml-3 mr-1" />{' '}
                                {Language.getTranslation('undoDeleteBlock', 'buttons')}
                            </a>
                        )}
                    </div>
                )}
                {markBlockScheduledForkDeletion && (
                    <div className="py-2 uk-background-danger-light uk-text-danger text-center small">
                        {t('document.navbar.main.editor.left.blocks.badges.markedForDeletion')}
                    </div>
                )}

                <Block
                    {...props}
                    blockDisplayOptions={blockDisplayOptions}
                    blockId={blockId}
                    showToolbar={showToolbar}
                />

                {showToolbar && (
                    <div className="editor-toolbar">
                        <div className="uk-button-group uk-button-group-small uk-margin-small-right px-2">
                            {block.new && (
                                <Badge
                                    variant="success"
                                    className="mr-2 px-2"
                                    style={{ paddingTop: 2, paddingBottom: 2, lineHeight: '18px' }}
                                >
                                    {Language.getTranslation('blockIsNew')}
                                </Badge>
                            )}

                            <BlockType block={block} isInsideBlockLayout={blockIsInLayout} />

                            {block.type === Constants.blockTypes.linked && (
                                <LinkedBlockLabel className="px-2" block={block} />
                            )}
                        </div>
                    </div>
                )}

                <BlockName
                    block={block.type === Constants.blockTypes.linked ? props.baseBlock ?? {} : block}
                    editorDisplaySection={props.editorDisplaySection}
                />
            </>
        </BlockHeightWrapper>
    );
}
