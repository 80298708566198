import { useGetDocument } from 'pages/documents_v2/hooks/useGetDocument';
import LoadingSpinner from 'pages/global/LoadingSpinner';
import { useEffect, useState } from 'react';
import { generatePath, useHistory, useParams } from 'react-router-dom';
import { VIEW_PATH } from 'scenes/DocumentsV2';
import { INDEX_PATH } from 'scenes/PublicationsV2';
import { useGetTemplate } from 'pages/documents_v2/hooks/useGetTemplate';
import { useGetBaseVariant } from 'pages/documents_v2/hooks/useGetBaseVariant';
import HelperFunctions from 'pages/global/HelperFunctions';
import { useUpdateSectionsMutation } from 'features/documents/documents';
import { useGetPublicationDomains } from 'pages/publications_v2/hooks/useGetPublicationDomains';
import { useCurrentOrganisation } from 'hooks/useCurrentOrganisation';
import _ from 'lodash';
import { useGetTemplateCategory } from 'pages/documents_v2/hooks/useGetTemplateCategory';
import { useAddPublicationMutation } from '../../../features/publications/publicationApi';

export function ModelImport() {
    const { domainId } = useParams();
    const history = useHistory();
    const document = useGetDocument(undefined, true);
    const baseVariant = useGetBaseVariant();
    const template = useGetTemplate(document?.modelId, document?.modelSource);
    const [updateSections] = useUpdateSectionsMutation();
    const publicationDomains = useGetPublicationDomains();
    const organisation = useCurrentOrganisation();
    const [formData, setFormData] = useState();
    const templateCategory = useGetTemplateCategory(document);
    const [addPublication] = useAddPublicationMutation();

    useEffect(() => {
        if (
            formData === undefined &&
            document &&
            baseVariant &&
            template &&
            organisation &&
            publicationDomains.length > 0
        ) {
            if (document.sections.length > 0) {
                redirectToDocument();
            } else {
                setFormData(getFormData());
            }
        }
    }, [document, template, baseVariant, organisation, publicationDomains]);

    useEffect(() => {
        if (formData) {
            const handleUpdate = async () => {
                const sections = await updateDocument(document.id, formData);
                const newPublication = await createPublication(sections);

                if (newPublication) {
                    redirectToPublications(newPublication);
                } else {
                    redirectToDocument();
                }
            };

            handleUpdate();
        }
    }, [formData]);

    return <LoadingSpinner />;

    function getFormData() {
        const selectedSection = template.sections[0];

        const documentVariantsList = HelperFunctions.variantAsList(baseVariant);
        const variantIds = documentVariantsList
            .filter((variant) => !variant.variantGroup)
            .map((variant) => `${variant.id}`);

        return {
            title: getSectionTitle(selectedSection, document),
            documentId: document.id,
            documentVariants: variantIds,
            modelId: document.modelId,
            sourceSlug: document.modelSource,
            exportProperties: Array.isArray(selectedSection.exportProperties)
                ? [...selectedSection.exportProperties]
                : { ...selectedSection.exportProperties },
            properties: {
                ...selectedSection.properties,
                templateParameters: selectedSection.properties.templateParameters,
                parentId: selectedSection.id,
            },
            areas: selectedSection.areas.map((area) => {
                const isTemplate = _.get(area, 'properties.isTemplate', false);
                const preventVariantsForNewDocument = _.get(
                    area,
                    'properties.templateParameters.preventVariantsForNewDocument',
                    false,
                );

                return {
                    title: area.title,
                    exportProperties: Array.isArray(area.exportProperties)
                        ? [...area.exportProperties]
                        : { ...area.exportProperties },
                    properties: {
                        ...area.properties,
                        templateParameters: area.properties.templateParameters,
                        parentId: area.id,
                    },
                    sortOrder: area.sortOrder,
                    documentVariants: isTemplate || preventVariantsForNewDocument ? [baseVariant.id] : variantIds,
                };
            }),
        };
    }

    function getSectionTitle(section, document) {
        // If the section name is formatted like a placeholder (i.e. "{foo bar}"), return the Document's name
        if (section.title.startsWith('{') && section.title.endsWith('}')) {
            return document.name;
        }

        return section.title;
    }

    async function updateDocument(documentId, formData) {
        return new Promise((resolve) => {
            updateSections({ documentId, body: [formData] }).then(({ data }) => {
                resolve(data);
            });
        });
    }

    async function createPublication(sections = []) {
        return new Promise((resolve) => {
            try {
                const formData = getPublicationFormData(sections[0]);

                addPublication(formData).then(({ data }) => {
                    resolve(data);
                });
            } catch (e) {
                console.log(e);
                resolve(undefined);
            }
        });
    }

    function getPublicationFormData(defaultSection) {
        const variant = _.get(baseVariant, 'children[0].children[0]');
        const domain = publicationDomains[0] ?? undefined;

        return {
            organisationId: organisation.id,
            domainId: _.isEmpty(domainId) ? domain?.id : _.parseInt(domainId),
            templateId: getTemplateId(),
            name: variant.name,
            title: defaultSection.title,
            variantName: organisation.name,
            description: variant.name,
            htmlMetaFooter: '',
            html: true,
            htmlSlug: HelperFunctions.slugify(variant.name),
            pdf: true,
            pdfFileName: HelperFunctions.slugify(variant.name),
            logo: undefined,
            properties: {},
            contentDecoded: [
                {
                    contentType: 'section',
                    type: 'default',
                    style: 'default',
                    documentId: document.id,
                    sectionId: defaultSection.id,
                    variantId: variant.id,
                },
            ],
        };
    }

    function getTemplateId() {
        const documentTemplates = organisation?.documentTemplates ?? [];

        if (documentTemplates.length === 0) {
            return undefined;
        }

        if (!_.isEmpty(templateCategory?.templateDir)) {
            const template = documentTemplates.find(
                (_template) => _template.templateDir === templateCategory.templateDir,
            );

            if (template) {
                return template.id;
            }
        }

        return documentTemplates[0].id;
    }

    function redirectToDocument() {
        history.push(
            generatePath(VIEW_PATH, {
                documentId: document.id,
            }),
        );
    }

    function redirectToPublications(publication) {
        history.push(
            generatePath(INDEX_PATH, {
                folder: 'list',
                folderId: '0',
                publicationId: publication.id,
            }),
        );
    }
}
