import { useGetGuardAudits } from './hooks/useGetGuardAudits';
import { PaginatedTable, TableProvider } from 'components/PaginatedTable';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';
import { CircleFill, SendArrowUp, SendCheckFill } from 'react-bootstrap-icons';
import cx from 'classnames';
import { CopyToClipboard } from 'components/CopyToClipboard';
import { IconButton } from 'components/Buttons';
import { useSendAuditNotificationsMutation } from 'features/publications/guardApi';
import { useGetGuardApiToken } from 'pages/publications_v2/views/guard/hooks/useGetGuardApiToken';
import { useEffect, useState } from 'react';
import HelperFunctions from 'pages/global/HelperFunctions';

export function GuardEntityAudits({ auditable, guardEntity, parentUri }) {
    const { t } = useTranslation('publications');
    const { items, totalItems, isEmpty } = useGetGuardAudits(auditable['@id'], auditable['@type'], {}, parentUri);

    return (
        <div>
            <div className="font-weight-bold">{t('publications:guard.headings.auditLog')}</div>

            <TableProvider>
                <PaginatedTable
                    items={items}
                    totalItems={totalItems}
                    clientSidePagination={false}
                    renderSearch={false}
                    itemsPerPage={10}
                    colSpan={3}
                >
                    {({ items = [] }) => (
                        <>
                            <thead>
                                <tr>
                                    <th>{t('publications:guard.headings.dateTime')}</th>
                                    <th>{t('publications:guard.headings.results')}</th>
                                    <th>{t('publications:guard.headings.auditType')}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {/*{isLoading && <TableRowSkeleton cellCount={my ? 7 : 6} />}*/}

                                {isEmpty ? (
                                    <tr>
                                        <td className="text-muted" colSpan="3">
                                            {t('publications:guard.messages.noAuditsYet')}
                                        </td>
                                    </tr>
                                ) : (
                                    <>
                                        {items.map((guardAudit) => (
                                            <GuardAuditRow
                                                guardAudit={guardAudit}
                                                guardEntity={guardEntity}
                                                key={`audit-${guardAudit.id}`}
                                            />
                                        ))}
                                    </>
                                )}
                            </tbody>
                        </>
                    )}
                </PaginatedTable>
            </TableProvider>
        </div>
    );
}

function GuardAuditRow({ guardAudit, guardEntity }) {
    const { t, i18n } = useTranslation();

    const processedAt = DateTime.fromISO(guardAudit.processedAt)
        .setLocale(i18n.language)
        .toLocaleString(DateTime.DATETIME_MED);

    const isValid = guardAudit.result === 'valid';

    return (
        <tr>
            <td className="align-top">
                <div className="flex-center">
                    <CircleFill
                        size={8}
                        className={cx('mr-2', {
                            'text-success': isValid,
                            'text-danger': !isValid,
                        })}
                    />
                    {processedAt}
                </div>
            </td>
            <td>
                <AuditResult guardEntity={guardEntity} guardAudit={guardAudit} />
            </td>
            <td>{t('publications:guard.badge.typeHashCheck')}</td>
        </tr>
    );
}

export function AuditResult({ guardAudit, guardEntity }) {
    const { t } = useTranslation();
    const [sendAuditNotifications] = useSendAuditNotificationsMutation();
    const token = useGetGuardApiToken();
    const [isSubmitting, setSubmitting] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);

    useEffect(() => {
        if (showSuccess) {
            HelperFunctions.changesSaved().then(() => {
                setShowSuccess(false);
            });
        }
    }, [showSuccess]);

    const { result, contentHash, httpResponseCode = 200 } = guardAudit;

    return (
        <div>
            {renderStatus()}
            {httpResponseCode !== 200 && <>&nbsp;-&nbsp;{httpResponseCode}</>}

            {result !== 'valid' && (
                <IconButton
                    tooltip="Verstuur notificaties"
                    disabled={isSubmitting}
                    className="ml-3"
                    icon={
                        showSuccess ? <SendCheckFill className="text-success" size={18} /> : <SendArrowUp size={18} />
                    }
                    onClick={handleSendNotifications}
                />
            )}

            {contentHash && (
                <>
                    <span>&nbsp;-&nbsp;</span>
                    <span>
                        <span
                            className="text-monospace mr-2"
                            data-uk-tooltip={t('publications:guard.tooltips.hashOfFile')}
                        >
                            {contentHash.substring(0, 7)}
                        </span>
                        <CopyToClipboard text={contentHash} />
                    </span>
                </>
            )}
        </div>
    );

    function renderStatus() {
        if (result === 'inaccessible') {
            return (
                <span className="font-weight-bold text-danger text-uppercase">
                    {t('publications:guard.status.inaccessible')}
                </span>
            );
        }

        if (result === 'hash_invalid') {
            return (
                <span className="font-weight-bold text-danger text-uppercase">
                    {t('publications:guard.status.hashInvalid')}
                </span>
            );
        }

        if (result === 'valid') {
            return (
                <span className="font-weight-bold text-success text-uppercase">
                    {t('publications:guard.status.valid')}
                </span>
            );
        }

        return (
            <span className="font-weight-bold text-danger text-uppercase">
                {t('publications:guard.status.unknown')}
            </span>
        );
    }

    function handleSendNotifications() {
        setSubmitting(true);

        sendAuditNotifications({
            uri: `${guardEntity['@id']}/guard_audits/${guardAudit.id}`,
            token,
        }).then(() => {
            setSubmitting(false);
            setShowSuccess(true);
        });
    }
}
