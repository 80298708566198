import { ShieldShaded } from 'react-bootstrap-icons';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';

export function AuditableStatusIcon({ auditable, guardEntity, iconOnly = false }) {
    const { t } = useTranslation();

    const isValid = auditable.lastResult === 'valid';

    if (!guardEntity.watchEnabled) {
        return (
            <div className="flex-center flex-shrink-0" data-uk-tooltip={t('publications:guard.tooltips.paused')}>
                <ShieldShaded size={14} className="flex-shrink-0 text-muted mr-2" />
                {!iconOnly && <>{t('publications:guard.status.paused')}</>}
            </div>
        );
    }

    return (
        <div className="flex-center flex-shrink-0">
            <ShieldShaded
                data-uk-tooltip={t(`publications:guard.tooltips.${isValid ? 'up' : 'error'}`)}
                size={14}
                className={cx('flex-shrink-0 mr-2', {
                    'text-success': isValid,
                    'text-danger': !isValid,
                })}
            />
            {!iconOnly && (
                <>
                    {isValid ? (
                        <>{t('publications:guard.status.up')}</>
                    ) : (
                        <div className="font-weight-bold text-danger">{t('publications:guard.status.error')}</div>
                    )}
                </>
            )}
        </div>
    );
}
