import { BoxArrowUpRight, Fingerprint, Gem } from 'react-bootstrap-icons';
import { LightOutlineButton, SecondaryButton, SuccessButton } from 'components/Buttons';
import { useCreateEntityTokenMutation } from 'features/publications/guardApi';
import { useCurrentOrganisation } from 'hooks/useCurrentOrganisation';
import { useGetPublication } from 'pages/publications_v2/hooks/useGetPublication';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import Spinner from 'pages/global/Spinner';
import striptags from 'striptags';
import { useGetGuardAliases } from 'pages/publications_v2/views/guard/hooks/useGetGuardAliases';
import cx from 'classnames';
import getEnv from 'config/Env';

export function NftStatus({ guardEntity }) {
    return (
        <div className="mb-4">
            <div className="font-weight-bold mb-2" style={{ fontSize: '1rem' }}>
                Nft token
            </div>

            {guardEntity && <GuardEntityNftStatus guardEntity={guardEntity} />}
        </div>
    );
}

function GuardEntityNftStatus({ guardEntity }) {
    const { t, i18n } = useTranslation('publications');
    const { guardNftToken, nftTokenPending } = guardEntity;
    const { items } = useGetGuardAliases(guardEntity['@id']);
    const env = getEnv('APP_ENV');
    const docrevEnv = getEnv('DOCREV_ENV');
    const explorerEnv = env === 'dev' || docrevEnv === 'my_test' ? 'devnet' : 'mainnet';

    const hasAliasesNotInToken = items.some((alias) => alias.inToken === false);

    return (
        <div>
            <div className="d-flex">
                <div className="mr-3">
                    <Fingerprint
                        size={36}
                        className={cx({
                            'text-warning': hasAliasesNotInToken,
                            'text-danger': !guardNftToken,
                            'text-success': guardNftToken && !hasAliasesNotInToken,
                            'text-muted': nftTokenPending,
                        })}
                    />
                </div>
                <div>
                    {nftTokenPending ? (
                        <div>
                            <div className="flex-center">
                                <Spinner variant="muted" className="flex-shrink-0 mr-2" />
                                <span className="font-weight-bold text-muted mr-2">Bezig...</span>
                            </div>
                            <div className="text-muted small">Nft token wordt nu aangemaakt</div>
                        </div>
                    ) : (
                        <div>
                            <div className="d-flex">
                                <div
                                    className={cx('font-weight-bold', {
                                        'text-success': !hasAliasesNotInToken,
                                        'text-warning': hasAliasesNotInToken,
                                        'text-danger': !guardNftToken,
                                    })}
                                >
                                    {guardNftToken ? (
                                        <>{hasAliasesNotInToken ? 'Incompleet' : 'Geldig'}</>
                                    ) : (
                                        <>Geen Nft aangemaakt</>
                                    )}
                                </div>

                                {renderLinkToNft()}
                            </div>

                            {renderCreatedAtDate()}
                        </div>
                    )}
                </div>
            </div>

            {!nftTokenPending && (!guardNftToken || hasAliasesNotInToken) && (
                <CreateTokenButton
                    guardEntity={guardEntity}
                    aliases={items}
                    hasAliasesNotInToken={hasAliasesNotInToken}
                />
            )}
        </div>
    );

    function renderLinkToNft() {
        if (!guardNftToken) {
            return null;
        }

        return (
            <a
                href={`https://core.metaplex.com/explorer/${guardNftToken.publicKey}?env=${explorerEnv}`}
                target="_blank"
                className="text-body ml-2"
                data-uk-tooltip={t('publications:guard.tooltips.viewInBlockchain')}
                style={{ marginTop: -2 }}
            >
                <BoxArrowUpRight size={12} />
            </a>
        );
    }

    function renderCreatedAtDate() {
        if (!guardNftToken) {
            return null;
        }

        const createdAt = DateTime.fromISO(guardNftToken.createdAt)
            .setLocale(i18n.language)
            .toLocaleString(DateTime.DATETIME_MED);

        return <div className="text-muted small">Aangemaakt op {createdAt}</div>;
    }
}

function CreateTokenButton({ guardEntity, hasAliasesNotInToken = false, aliases = [] }) {
    const [showForm, setShowForm] = useState(false);
    const hasNft = guardEntity.hasOwnProperty('guardNftToken');

    const isUpdate = hasNft && hasAliasesNotInToken;

    if (showForm) {
        return (
            <div className="pt-3">
                <CreateTokenForm guardEntity={guardEntity} hideForm={() => setShowForm(false)} aliases={aliases} />
            </div>
        );
    }

    return (
        <div className="pt-3">
            <LightOutlineButton size="sm" className="pl-2" onClick={() => setShowForm(true)}>
                <Gem size={15} className="flex-shrink-0 mr-1" />
                {isUpdate ? 'Nft vernieuwen' : 'Nft aanmaken'}&hellip;
            </LightOutlineButton>
        </div>
    );
}

function CreateTokenForm({ guardEntity, hideForm, aliases = [] }) {
    const { t } = useTranslation();
    const currentOrganisation = useCurrentOrganisation();
    const { guardApiToken } = currentOrganisation;
    const publication = useGetPublication(guardEntity.publicationId);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [createEntityToken] = useCreateEntityTokenMutation();

    const formData = getFormData();
    const canCreate = guardEntity.inCirculationSince;
    const hasAliasesWithoutCirculation = aliases.some((alias) => !alias.inCirculationSince);

    return (
        <div className="dr-container p-3">
            <div>
                <div className="font-weight-bold mb-2">Nft aanmaken bevestigen</div>

                <div className="alert alert-primary">
                    <strong>Let op:</strong> door een Nft aan te maken worden de gegevens van deze bestanden vastgelegd
                    in de blockchain. De Nft kan niet meer aangepast of verwijderd worden.
                </div>

                <div className="mb-3">De Nft gaat deze data bevatten:</div>

                <div className="d-flex mb-1">
                    <div className="font-weight-bolder" style={{ minWidth: 120 }}>
                        Titel
                    </div>
                    <div>{formData.title}</div>
                </div>

                <div className="d-flex mb-1">
                    <div className="font-weight-bolder" style={{ minWidth: 120 }}>
                        Hash
                    </div>
                    <div>{guardEntity.contentHash}</div>
                </div>

                <div className="d-flex mb-3">
                    <div className="font-weight-bolder" style={{ minWidth: 120 }}>
                        Organisatie ID
                    </div>
                    <div>{formData.organisationIdentityId}</div>
                </div>

                {!guardEntity.inCirculationSince && (
                    <div className="alert alert-danger">
                        In omloop datum is niet ingesteld. Vul eerst de startdatum in.
                    </div>
                )}

                {hasAliasesWithoutCirculation && (
                    <div className="alert alert-danger">
                        Niet alle aliassen hebben de 'in omloop' datum ingesteld. Vul eerst de startdatum in voor iedere
                        alias.
                    </div>
                )}

                <div className="d-flex">
                    <SuccessButton
                        size="sm"
                        disabled={isSubmitting || !canCreate || hasAliasesWithoutCirculation}
                        onClick={() => createToken()}
                    >
                        {isSubmitting && <Spinner className="flex-shrink-0 mr-1" />}
                        Nft nu aanmaken
                    </SuccessButton>

                    <SecondaryButton size="sm" className="border-0 ml-1" onClick={hideForm}>
                        {t('global:btn.cancel')}
                    </SecondaryButton>
                </div>
            </div>
        </div>
    );

    function createToken() {
        setIsSubmitting(true);

        createEntityToken({
            uri: guardEntity['@id'],
            type: guardEntity['@type'],
            token: guardApiToken,
            body: formData,
        }).then(() => {
            setIsSubmitting(false);
        });
    }

    function getFormData() {
        let title = publication.title;

        if (!_.isEmpty(publication.description)) {
            title = title + ' - ' + publication.description;
        }

        return {
            uri: guardEntity['@id'],
            title: striptags(title.replace(/\s{2,}/g, ' ')),
            organisationIdentityId: publication.properties.guard.organisationIdentityId,
            organisationIdentityType: publication.properties.guard.organisationIdentityType,
        };
    }
}
