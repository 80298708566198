import HelperFunctions from './HelperFunctions';

export function getBlockData(block) {
    // Use when template Block
    if (block.baseContent && !block.latestContent) {
        return HelperFunctions.tryParseJSON(block.baseContent);
    }

    return parseBlockContent(block.latestContent);
}

export function findBlockDataFromArray(blockKey, blocks) {
    const layoutBlock = HelperFunctions.getByValue(blocks, 'key', blockKey);

    return parseBlockContent(layoutBlock.latestContent);
}

export function formatData(data) {
    return {
        rows: data.length,
        columns: data.length > 0 ? data[0].length : 0,
        data,
        readOnly: false,
    };
}

export function parseBlockContent(content) {
    const result = HelperFunctions.tryParseJSON(content);

    if (result) {
        return {
            readOnly: false,
            ...result,
        };
    }

    // Return empty object
    return formatData([[[], []]]);
}

export function parseBlockData(data) {
    return JSON.stringify(data);
}

export function filterAvailableBlocks(blockLayoutData, blocks = []) {
    const blockIds = blocks.map((block) => block.key);
    const newData = [];

    blockLayoutData.data.forEach((row) => {
        const newRow = [];

        row.forEach((cell) => {
            newRow.push(cell.filter((item) => blockIds.includes(item.id)));
        });

        newData.push(newRow);
    });

    return {
        ...blockLayoutData,
        data: newData,
    };
}

export function getBlocksFromData(blockLayoutData, blocks = []) {
    const blockIds = [];

    blockLayoutData.data.forEach((row) => {
        row.forEach((cell) => {
            cell.forEach((item) => {
                blockIds.push(item.id);
            });
        });
    });

    return blocks.filter((block) => blockIds.includes(block.key));
}

export function getBlockIdsInBlockLayout(block) {
    const allBlockKeys = [];
    const currentBlockData = HelperFunctions.tryParseJSON(block.latestContent || block.baseContent) ?? {};

    if (currentBlockData.data) {
        currentBlockData.data.forEach((row) => {
            if (row) {
                row.forEach((column) => {
                    if (column) {
                        column.forEach((item) => {
                            if (item.id) {
                                allBlockKeys.push(item.id);
                            }
                        });
                    }
                });
            }
        });
    }

    return allBlockKeys;
}
