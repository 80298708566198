import { useActiveOrganisation } from 'hooks/useActiveOrganisation';
import { useGetTableTemplatesQuery } from 'features/documents/documents';

const emptyArray = [];

export function useGetTableTemplates() {
    const activeOrganisation = useActiveOrganisation();

    const { tableTemplates } = useGetTableTemplatesQuery(activeOrganisation, {
        selectFromResult: ({ data }) => ({
            tableTemplates: data ?? emptyArray,
        }),
    });

    return tableTemplates;
}

export function useGetTableTemplate(id) {
    const tableTemplates = useGetTableTemplates();

    return tableTemplates.find((template) => template.id === id);
}
