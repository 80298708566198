import cx from 'classnames';
import { useState } from 'react';
import { CircleFill, CloudDownload, PlusCircle } from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';
import { TableRowSkeleton } from 'components/Skeleton';
import { useGetGuardReports } from 'pages/publications_v2/views/guard/hooks/useGetGuardReports';
import { DateTime } from 'luxon';
import { LightOutlineButton } from 'components/Buttons';
import { saveAs } from 'file-saver';
import HelperFunctions from 'pages/global/HelperFunctions';
import { downloadReport, useCreateGuardReportMutation } from 'features/publications/guardApi';
import { useGetGuardApiToken } from 'pages/publications_v2/views/guard/hooks/useGetGuardApiToken';

export function Reports({ guardEntity }) {
    const { t } = useTranslation();
    const { items, isLoading, isEmpty, totalItems } = useGetGuardReports(guardEntity['@id']);
    const [createGuardReport] = useCreateGuardReportMutation();
    const token = useGetGuardApiToken();

    return (
        <div>
            <div className="d-flex align-items-end mb-2">
                <div className="font-weight-bold mr-3" style={{ fontSize: '1rem' }}>
                    {t('publications:guard.headings.reports')}
                </div>

                <LightOutlineButton size="sm" onClick={createReport} className="pl-2">
                    <PlusCircle size={14} className="flex-shrink-0 mr-1" />
                    {t('publications:guard.buttons.createReport')}
                </LightOutlineButton>
            </div>

            <table className="table dr-table">
                <thead>
                    <tr>
                        <th>#</th>
                        <th>{t('publications:guard.headings.date')}</th>
                        <th>{t('publications:guard.headings.file')}</th>
                        <th>{t('publications:guard.headings.status')}</th>
                        <th>{t('publications:guard.headings.actions')}</th>
                    </tr>
                </thead>
                <tbody>
                    {isLoading && isEmpty ? (
                        <>
                            <TableRowSkeleton cellCount={5} rowCount={2} />
                        </>
                    ) : (
                        <>
                            {items.map((guardReport, index) => (
                                <Report
                                    report={guardReport}
                                    index={totalItems - index}
                                    key={`report-${guardReport.id}`}
                                />
                            ))}
                        </>
                    )}
                </tbody>
            </table>
        </div>
    );

    function createReport() {
        createGuardReport({ uri: guardEntity['@id'], token });
    }
}

function Report({ report, index }) {
    const { t, i18n } = useTranslation();
    const [isDownloading, setDownloading] = useState(false);
    const token = useGetGuardApiToken();

    return (
        <tr>
            <td>#{index}</td>
            <td>{DateTime.fromISO(report.createdAt).setLocale(i18n.language).toLocaleString(DateTime.DATETIME_MED)}</td>
            <td>{report.status === 'done' && <>{report.id}.pdf</>}</td>
            <td>
                <div className="flex-center">
                    <CircleFill
                        size={8}
                        className={cx('mr-2', {
                            'text-success': report.status === 'done',
                            'text-danger': report.status === 'failed',
                            'text-warning': report.status === 'in_progress',
                        })}
                    />
                    {t(`publications:guard.status.report.${report.status}`)}
                </div>
            </td>
            <td>
                <LightOutlineButton
                    size="sm"
                    disabled={report.status !== 'done' || isDownloading}
                    onClick={handleDownload}
                >
                    <CloudDownload size={14} />
                    <div>{t('publications:guard.buttons.downloadPdf')}</div>
                </LightOutlineButton>
            </td>
        </tr>
    );

    function handleDownload() {
        setDownloading(true);

        downloadReport(report['@id'], token)
            .then((response) => {
                saveAs(response.data, decodeURI(response.headers['x-suggested-filename'] ?? 'file'));
            })
            .catch(() => {
                HelperFunctions.alertModal(t('global:error.exportFailed'));
            })
            .finally(() => {
                setDownloading(false);
            });
    }
}
