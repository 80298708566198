import { useMemo } from 'react';
import { read, utils } from 'xlsx';
import { Table } from 'react-bootstrap';
import { getListStyle } from 'pages/documents_v2/views/edit_area/blocks/block_types/BlockLayoutBlock';
import { useTranslation } from 'react-i18next';

const MAX_ROWS_TO_SHOW = 5;

export function BlockLayoutFromCsv({ block, blockData }) {
    const { t } = useTranslation();

    const rows = useMemo(() => {
        const workbook = read(blockData.csvData, { type: 'string', raw: true, FS: ';' });
        const worksheet = workbook.Sheets[workbook.SheetNames[0]];

        const allRows = utils.sheet_to_json(worksheet, {
            header: 1,
            blankrows: false,
            defval: '',
            rawNumbers: true,
        });

        // Only show the first x rows
        return allRows.slice(0, MAX_ROWS_TO_SHOW);
    }, [blockData.csvData]);

    const showTableHeader = block.properties?.showTableHeader ?? true;
    const highlightFirstColumn = block.properties?.highlightFirstColumn ?? false;
    const columnWidth = rows[0] && rows[0].length > 0 ? Math.round(100 / rows[0].length) + '%' : '100%';

    return (
        <div>
            <div className="small text-muted mb-2">
                {t('global:pagination.numberOfItemsShowing', {
                    start: 1,
                    end: MAX_ROWS_TO_SHOW,
                    total: blockData.rows,
                })}
            </div>
            <div className="table-responsive">
                <Table bordered className="mb-2">
                    <tbody>
                        {rows.map((row, index) => (
                            <tr key={`block-${block.key}-row-${index}`}>
                                {row.map((col, colIndex) => {
                                    const isTableHeader =
                                        (showTableHeader && index === 0) || (highlightFirstColumn && colIndex === 0);

                                    return (
                                        <td
                                            width={columnWidth}
                                            style={getListStyle(false, isTableHeader)}
                                            key={`block-${block.key}-row-${index}-col-${colIndex}`}
                                        >
                                            <div>{col}</div>
                                        </td>
                                    );
                                })}
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </div>
        </div>
    );
}
