import BaseBlocks, { BaseBlocksForHistory } from './BaseBlocks';
import { views } from 'pages/documents_v2/config/views';
import { BlockVariants } from 'pages/documents_v2/views/edit/BlockVariants';
import { useSelector } from 'react-redux';
import { Preview } from './Preview';
import { BlockCompare } from 'pages/documents_v2/views/edit_area/BlockCompare';

export function EditAreaRightSideContent({
    showRightSide = true,
    rightSideProps,
    area,
    activeVariantId,
    selectedHistoryVersion,
}) {
    const rightSideEditorView = useSelector((state) => state.documents_v2.rightSideEditorView);

    if (!showRightSide) {
        return null;
    }

    if (rightSideEditorView === views.PREVIEW) {
        return <Preview />;
    }

    if (rightSideEditorView === views.VARIANTS) {
        return <BlockVariants />;
    }

    if (rightSideEditorView === views.BLOCK_COMPARE) {
        return <BlockCompare />;
    }

    if (rightSideEditorView === views.CHANGES) {
        if (selectedHistoryVersion) {
            return (
                <BaseBlocksForHistory
                    selectedHistoryVersion={selectedHistoryVersion}
                    rightSideProps={rightSideProps}
                    area={area}
                    activeVariantId={activeVariantId}
                />
            );
        }
        return (
            <BaseBlocks
                rightSideProps={rightSideProps}
                area={area}
                activeVariantId={activeVariantId}
            />
        );
    }

    return null;
}
