import { useMemo } from 'react';
import { useGetOpListsQuery } from 'features/operationsList/operationList';
import { useActiveOrganisation } from 'hooks/useActiveOrganisation';
import { createSelector } from '@reduxjs/toolkit';

export function useGetOpLists() {
    const organisationId = useActiveOrganisation();

    const selectOpLists = useMemo(() => {
        const emptyArray = [];

        // Return a unique selector instance for this page so that
        // the filtered results are correctly memoized
        return createSelector(
            (res) => res.data,
            (res, organisationId) => organisationId,
            (data, organisationId) =>
                data ? data.filter((opList) => opList.organisationId === organisationId) : emptyArray,
        );
    }, []);

    const { opLists } = useGetOpListsQuery(undefined, {
        selectFromResult: (result) => ({
            opLists: selectOpLists(result, organisationId),
        }),
    });

    return opLists;
}
