import { Component, Fragment, useEffect, useState } from 'react';
import Parser from 'html-react-parser';
import HelperFunctions from '../../../global/HelperFunctions';
import Constants from '../../../../config/Constants';
import InteractivePart from '../_BlockParser/InteractivePart';
import cx from 'classnames';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { useGetDocument } from 'pages/documents_v2/hooks/useGetDocument';

export default function LinkedBlock(props) {
    const document = useGetDocument();

    if (!document) {
        return null;
    }

    return <LinkedBlockAsComponent {...props} document={document} />;
}

class LinkedBlockAsComponent extends Component {
    constructor(props) {
        super(props);

        this.requiredFormFieldsStep1 = ['areaId', 'documentId'];
        this.requiredFormFieldsStep2 = ['blockId'];
        this.state = {
            formData: this.getFormData(),
            formIsValid: false,
            isLoading: true,
            showEditModal: false,
        };
    }

    handleFormChange = (event, name, originalEvent) => {
        if (originalEvent !== undefined) {
            originalEvent.preventDefault();
        }

        // Update data
        const formData = {
            ...this.state.formData,
            [name]: event.target.value,
        };

        this.setState({
            formData,
        });

        this.formIsValid(formData);
    };

    handleFormSubmit = (e) => {
        e.preventDefault();

        const formData = this.state.formData;

        if (formData.step === 1) {
            this.setState({
                formData: {
                    ...formData,
                    step: formData.step + 1,
                },
                formIsValid: false,
            });
        } else {
            // submit form
            this.setState(
                {
                    isLoading: true,
                    formData: {
                        ...this.state.formData,
                        isLoading: true,
                    },
                },
                () => {
                    const latestContent = JSON.stringify({
                        areaId: parseInt(formData.areaId),
                        blockId: parseInt(formData.blockId),
                        documentId: parseInt(formData.documentId),
                    });

                    // Notify the parent components that the content has changed
                    this.props.handleBlockChange(latestContent, false);

                    this.setState({
                        isLoading: false,
                        showEditModal: false,
                    });
                },
            );
        }
    };

    formIsValid = (formData) => {
        this.setState({
            formIsValid: HelperFunctions.formIsValid(
                formData,
                formData.step === 1 ? this.requiredFormFieldsStep1 : this.requiredFormFieldsStep2,
            ),
        });
    };

    getFormData = (block) => {
        if (block === undefined || !block.hasOwnProperty('latestContent') || block.latestContent === '') {
            return {
                areaId: '',
                documentId: this.props.document.id,
                isLoading: true,
                blockId: '',
                step: 1,
            };
        }

        const formData = JSON.parse(block.latestContent);
        formData.step = 1;

        return formData;
    };

    toggleModal = () => {
        const formData = this.getFormData(this.props.block);
        this.setState({
            formData,
            showEditModal: !this.state.showEditModal,
        });

        this.formIsValid(formData);
    };

    showModal = () => {
        if (!this.state.showEditModal) {
            const formData = this.getFormData(this.props.block);
            this.setState({
                formData,
                showEditModal: true,
            });

            this.formIsValid(formData);
        }
    };

    render() {
        const { activeVariant, block, blockDisplayOptions } = this.props;
        const { deleted = false } = block;
        const editable = activeVariant.parentId === null && blockDisplayOptions.table.editable && deleted === false;

        const output = <LinkedBlockContent {...this.props} />;
        return editable ? (
            <EditableBlock toggleModal={this.toggleModal} showModal={this.showModal}>
                {output}
            </EditableBlock>
        ) : (
            <div
                className={cx('area-read-only editor-right-side bg-light-gray', {
                    'is-deleted': deleted,
                })}
            >
                <div className="tiny-mce-content-body-linked">{output}</div>
            </div>
        );
    }
}

function EditableBlock(props) {
    return (
        <div className="area-text-block area-text-block-no-editor linked-block" onClick={props.showModal}>
            {props.children}
        </div>
    );
}

function LinkedBlockContent(props) {
    const { baseBlock, block, context, editorDisplaySection } = props;
    const { t } = useTranslation('documents');

    let readOnly = props.readOnly;

    if (block.hasOwnProperty('scheduledForDeletion') && block.scheduledForDeletion === true) {
        readOnly = true;
    }

    if (context === Constants.blockContext.checkChanges && editorDisplaySection === 'left') {
        return (
            <>
                {block.diffContent !== null && block.diffContent !== '' ? (
                    Parser(block.diffContent, {
                        replace: (domNode) => {
                            if (
                                domNode.name === 'change' &&
                                domNode.hasOwnProperty('attribs') &&
                                domNode.attribs.hasOwnProperty('data-diff-id')
                            ) {
                                const domNodeId = parseInt(domNode.attribs['data-diff-id']);
                                let part = {};
                                block.changes.forEach((partItem) => {
                                    if (partItem.diffId === domNodeId) {
                                        part = partItem;
                                    }
                                });
                                if (part !== undefined) {
                                    return (
                                        <InteractivePart
                                            part={part}
                                            selectPart={() => props.selectPart(part.id, block.id)}
                                            isSelected={
                                                props.selectedPart.block === block.id &&
                                                props.selectedPart.part === part.id
                                            }
                                        />
                                    );
                                } else {
                                    return <Fragment />;
                                }
                            } else if (domNode.name === 'change') {
                                return <Fragment />;
                            }
                        },
                    })
                ) : block.latestContent !== '' ? (
                    Parser(block.latestContent)
                ) : (
                    <div className="uk-margin-small-top uk-padding-small uk-background-muted uk-text-small">
                        <p>{t('document.navbar.main.editor.left.blocks.types.linked.linkedBlockEmpty')}</p>
                    </div>
                )}
            </>
        );
    }

    if (readOnly) {
        if (baseBlock) {
            if (baseBlock.renderedDiffContent !== '') {
                return <>{Parser(baseBlock.renderedDiffContent)}</>;
            }

            if (baseBlock.latestContent !== '') {
                return <>{Parser(baseBlock.latestContent)}</>;
            }
        }

        return (
            <div className="small" style={{ lineHeight: 'unset' }}>
                {t('document.navbar.main.editor.left.blocks.types.linked.linkedBlockEmpty')}
            </div>
        );
    }

    return <LinkedBlockForEditor {...props} />;
}

function LinkedBlockForEditor({ activeVariant, block, document, baseBlock }) {
    const latestContentFromBaseBlock = baseBlock?.latestContent ?? '';
    const { t } = useTranslation('documents');

    const [storedContent, setStoredContent] = useState(block.latestContent);
    const [isDirty, setDirty] = useState(false);

    useEffect(() => {
        if (block.latestContent !== storedContent) {
            setDirty(true);
        }
    }, [block.latestContent]);

    useEffect(() => {
        if (isDirty && block.latestContent !== storedContent) {
            // Area was saved
            setDirty(false);
            setStoredContent(block.latestContent);
        }
    }, [latestContentFromBaseBlock]);

    if (baseBlock === undefined) {
        return (
            <div className="small">
                {_.isEmpty(block.latestContent) ? (
                    <>
                        <span className="text-warning">
                            {t('document.navbar.main.editor.left.blocks.types.linked.linkedBlockEmpty')}
                        </span>
                    </>
                ) : (
                    <>
                        <span className="text-secondary">
                            {t('document.navbar.main.editor.left.blocks.types.linked.linkedBlockPreview')}
                        </span>
                    </>
                )}
            </div>
        );
    }

    if (isDirty) {
        return (
            <span className="small text-secondary">
                {t('document.navbar.main.editor.left.blocks.types.linked.linkedBlockPreview')}
            </span>
        );
    }

    if (_.isEmpty(latestContentFromBaseBlock)) {
        return (
            <div className="small text-warning">
                {t('document.navbar.main.editor.left.blocks.types.linked.linkedBlockEmpty')}
            </div>
        );
    }

    const content = HelperFunctions.prepareTags(latestContentFromBaseBlock, document, activeVariant);

    return (
        <div
            className={cx('mce-content-body', {
                'block-deleted': block.deleted !== undefined && block.deleted === true,
            })}
        >
            {Parser(content)}
        </div>
    );
}
