import React, { Component, useContext, useEffect, useState } from 'react';
import { generatePath, useHistory, useParams } from 'react-router-dom';
import HelperFunctions from '../../global/HelperFunctions';
import VariantViewButtons from './VariantViewButtons';
import Constants from '../../../config/Constants';
import getEnv from '../../../config/Env';
import LeftSideBlocks from './_CheckArea/LeftSideBlocks';
import { useDispatch } from 'react-redux';
import { AreaNav } from './AreaNav';
import { AreaLayout, AreaLayoutNav, AreaLayoutPageLeft, AreaLayoutPageRight } from './AreaLayout';
import { AreaContext } from '../../documents_v2/views/Area';
import { EditAreaRightSideHeader } from './_EditArea/EditAreaRightSideHeader';
import { EditAreaRightSideContent } from './_EditArea/EditAreaRightSideContent';
import { useGetDocument } from '../../documents_v2/hooks/useGetDocument';
import { useGetDocumentVariants } from '../../documents_v2/hooks/useGetDocumentVariants';
import { useGetBaseVariant } from '../../documents_v2/hooks/useGetBaseVariant';
import {
    documentApi,
    useDeleteBlockMutation,
    useProcessBlocksChangesMutation,
    useResetBlocksChangesMutation,
    useUnlockAreaMutation,
    useUpdateBlocksMutation,
} from 'features/documents/documents';
import { EDIT_AREA_PATH, VIEW_PATH } from 'scenes/DocumentsV2';
import { LockedArea } from '../../documents_v2/views/view/LockedArea';
import { useGetAreaBlocks } from '../../documents_v2/hooks/useGetAreaBlocks';
import queryString from 'query-string';
import { useTranslation } from 'react-i18next';
import { useGetAreaBaseBlocks } from 'pages/documents_v2/hooks/useGetAreaBaseBlocks';
import LoadingSpinner from 'pages/global/LoadingSpinner';

function CheckAreaModal() {
    const { t: tGlobal } = useTranslation('global');
    const [unlockArea] = useUnlockAreaMutation();
    const [deleteBlock] = useDeleteBlockMutation();
    const [updateBlocks] = useUpdateBlocksMutation();
    const [resetBlocksChanges] = useResetBlocksChangesMutation();
    const [processBlocksChanges] = useProcessBlocksChangesMutation();
    const dispatch = useDispatch();
    const history = useHistory();
    let { areaId } = useParams();

    // Parse to Int
    areaId = parseInt(areaId);

    const document = useGetDocument();
    const documentVariants = useGetDocumentVariants();
    const baseVariant = useGetBaseVariant();
    const { activeVariantId } = useContext(AreaContext);
    const areaData = useGetAreaBlocks();
    const { baseBlocks, isLoading } = useGetAreaBaseBlocks();

    // Current user has access?
    const allowedRoles = [Constants.userDocumentRole.documentManager, Constants.userDocumentRole.finalEditor];
    const accessDenied = document && !allowedRoles.includes(document.currentUserRole);

    useEffect(() => {
        if (accessDenied) {
            // user is not allowed access
            HelperFunctions.alertModal(tGlobal('error.accessDenied')).then(() => {
                history.push(
                    generatePath(VIEW_PATH, {
                        documentId: document.id,
                    }),
                );
            });
        }
    }, [accessDenied]);

    if (accessDenied) {
        return null;
    }

    if (!document || documentVariants.length === 0 || !baseVariant || isLoading) {
        return (
            <div className="pt-5">
                <LoadingSpinner />
            </div>
        );
    }

    return (
        <LockedArea action="check">
            {areaData && (
                <CheckAreaState
                    areaId={areaId}
                    area={areaData.area}
                    areaData={areaData}
                    baseBlocks={baseBlocks}
                    handleRedirect={handleRedirect}
                    document={{
                        ...document,
                        baseVariant,
                        documentVariants,
                    }}
                    activeVariantId={activeVariantId}
                    close={close}
                    tGlobal={tGlobal}
                    deleteBlock={deleteBlock}
                    updateBlocks={updateBlocks}
                    resetBlocksChanges={resetBlocksChanges}
                    processBlocksChanges={processBlocksChanges}
                />
            )}
        </LockedArea>
    );

    function unlock() {
        return new Promise((resolve) => {
            unlockArea(areaId).then(() => {
                resolve();
            });
        });
    }

    function redirect(url) {
        unlock().then(() => {
            // Invalidate cache
            dispatch(documentApi.util.invalidateTags([{ type: 'Document', id: document.id }]));

            history.push(url);
        });
    }

    function close() {
        const url = queryString.stringifyUrl({
            url: generatePath(VIEW_PATH, {
                documentId: document.id,
            }),
            query: { sid: areaData?.area?.sectionId },
        });

        redirect(url);
    }

    function handleRedirect(url) {
        history.push(url);
    }
}

function CheckAreaState(props) {
    const [selectedPart, setSelectedPart] = useState({ part: false, block: false });

    return (
        <CheckArea
            {...props}
            selectedPart={selectedPart}
            toggleSelectedPart={toggleSelectedPart}
            clearSelectedPart={clearSelectedPart}
        />
    );

    function toggleSelectedPart(part, block) {
        if (part === selectedPart.part && block === selectedPart.block) {
            setSelectedPart({ part: false, block: false });
        } else {
            setSelectedPart({ part, block });
        }
    }

    function clearSelectedPart() {
        setSelectedPart({ part: false, block: false });
    }
}

class CheckArea extends Component {
    constructor(props) {
        super(props);
        this.confirmClose = this.confirmClose.bind(this);
        this.processBlockChanges = this.processBlockChanges.bind(this);
        this.processAllChanges = this.processAllChanges.bind(this);
        this.processDeletedBlock = this.processDeletedBlock.bind(this);
        this.processSelectedChange = this.processSelectedChange.bind(this);
        this.redirectToArea = this.redirectToArea.bind(this);
        this.save = this.save.bind(this);
        this.switchVariant = this.switchVariant.bind(this);

        this.state = {
            baseBlocks: [], // list of blocks used for the right side
            changesDetected: false,
            changesSaved: false, // use to show 'changes saved'-message
            nrOfChanges: 0,
            refreshingContent: false, // set to true to slightly fade out the content to indicate save in progress
        };

        // some arrays for keeping track of processing of deleted blocks
        this.blocksScheduledForDeletion = []; // these are not yet processed
        this.deletedBlocks = []; // these are processed and will be deleted once the save button is clicked
        this.restoredBlocks = []; // these are processed and will be restored once the save button is clicked
    }

    changesSaved() {
        this.setState({ changesSaved: true }, () => {
            HelperFunctions.changesSaved().then(() => {
                this.setState({ changesSaved: false });
            });
        });
    }

    close() {
        this.props.close();
    }

    confirmClose() {
        const _this = this;
        if (this.state.changesDetected) {
            this.confirmCancelChanges()
                .then(function () {
                    _this.close();
                })
                .catch(function () {
                    // Rejected, do nothing
                });
        } else {
            _this.close();
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        // New Variant selected
        if (this.props.activeVariantId !== prevProps.activeVariantId) {
            this.switchVariant(this.props.activeVariantId, this.props.area, this.props.baseBlocks);
            this.props.clearSelectedPart();
        }
    }

    componentDidMount = async () => {
        const { activeVariantId, area, baseBlocks = [] } = this.props;
        this.switchVariant(activeVariantId, area, baseBlocks);
    };

    confirmCancelChanges() {
        return new Promise((resolve, reject) => {
            HelperFunctions.confirmModal(
                this.props.tGlobal('confirm.confirmCancelChanges'),
                'danger',
                false,
                this.props.tGlobal('confirm.yesContinue'),
                this.props.tGlobal('btn.cancel'),
            )
                .then(() => {
                    resolve();
                })
                .catch(() => {
                    reject();
                });
        });
    }

    processBlockChanges(blockId, action) {
        const { baseBlocks = [], nrOfChanges } = this.state;
        let updateNrOfChanges = false;

        this.setState({
            baseBlocks: baseBlocks.map((block) => {
                if (
                    block.id === blockId &&
                    block.changes !== undefined &&
                    block.changes.length > 0 &&
                    !block.processed
                ) {
                    const hasChanges = block.changes.some((part) => part.processed === false);

                    if (hasChanges) {
                        updateNrOfChanges = true;
                    }

                    return {
                        ...block,
                        changes: block.changes.map((part) => {
                            return {
                                ...part,
                                processed: true,
                                accepted: action === 'accept',
                            };
                        }),
                    };
                }

                return block;
            }),
            nrOfChanges: updateNrOfChanges ? nrOfChanges - 1 : nrOfChanges,
            changesDetected: true,
        });
    }

    processAllChanges(action) {
        if (this.state.nrOfChanges < 1 || this.state.baseBlocks.length < 1) {
            // no changes or blocks available
            // how did you even get to this point?? :O
            return;
        }

        const baseBlocks = this.state.baseBlocks.map((block) => {
            if (block.scheduledForDeletion) {
                if (action === 'accept') {
                    // add it to the list to be deleted once the save button is clicked
                    this.deletedBlocks.push(block.id);

                    // accept the deletion
                    return {
                        ...block,
                        deleted: true,
                        deletionAccepted: true, // marks this block, so we can still view it until the user hit the save button
                        scheduledForDeletion: false,
                    };
                }

                // add it to the list to be restored once the save button is clicked
                this.restoredBlocks.push(block.id);

                // reject the deletion, restore the block
                return {
                    ...block,
                    scheduledForDeletion: false,
                };
            }

            if (block.changes !== undefined && block.changes.length > 0 && !block.processed) {
                return {
                    ...block,
                    changes: block.changes.map((change) => {
                        return {
                            ...change,
                            processed: true,
                            accepted: action === 'accept',
                        };
                    }),
                };
            }

            return block;
        });

        this.setState({
            baseBlocks,
            nrOfChanges: 0,
            changesDetected: true,
        });
    }

    processDeletedBlock(blockId, action) {
        if (!blockId) {
            return;
        }

        if (action === 'accept') {
            // accept the deletion
            const baseBlocks = this.state.baseBlocks.map((block) => {
                if (block.id === blockId) {
                    return {
                        ...block,
                        deleted: true,
                        deletionAccepted: true, // marks this block, so we can still view it until the user hit the save button
                        scheduledForDeletion: false,
                    };
                }

                return block;
            });

            this.setState({
                baseBlocks,
                changesDetected: true,
                nrOfChanges: this.state.nrOfChanges - 1,
                refreshingContent: false,
            });

            // add it to the list to be deleted once the save button is clicked
            this.deletedBlocks.push(blockId);
        } else {
            // reject the deletion, restore the block
            const baseBlocks = this.state.baseBlocks.map((block) => {
                if (block.id === blockId) {
                    return {
                        ...block,
                        scheduledForDeletion: false,
                    };
                }

                return block;
            });

            this.setState({
                baseBlocks,
                changesDetected: true,
                nrOfChanges: this.state.nrOfChanges - 1,
                refreshingContent: false,
            });

            // add it to the list to be restored once the save button is clicked
            this.restoredBlocks.push(blockId);
        }

        this.blocksScheduledForDeletion = this.removeValueFromArray(this.blocksScheduledForDeletion, parseInt(blockId));
    }

    processDeletedBlocks = () => {
        return new Promise(async (resolve) => {
            if (this.deletedBlocks.length === 0 && this.restoredBlocks.length === 0) {
                resolve(true);
                return;
            }

            if (this.deletedBlocks.length === 0) {
                resolve(true);
                return;
            }

            // Use for loop here, we want to do it one by one
            for await (const blockId of this.deletedBlocks) {
                // await DocumentApi.deleteBlock(blockId);
                await this.props.deleteBlock(blockId);
            }

            this.deletedBlocks = [];
            resolve(true);
        })
            .then(async () => {
                if (this.restoredBlocks.length === 0) {
                    return;
                }

                const formData = this.restoredBlocks.map((blockId) => {
                    return { id: blockId, scheduledForDeletion: false };
                });

                return await this.props
                    .updateBlocks({
                        areaId: this.props.areaId,
                        variantId: this.props.activeVariantId,
                        body: formData,
                    })
                    .then(({ data }) => {
                        this.restoredBlocks = [];
                        return data;
                    });
            })
            .then((data) => {
                return data;
            });
    };

    processSelectedChange(action) {
        const { baseBlocks = [], nrOfChanges } = this.state;
        const { selectedPart } = this.props;

        if (!selectedPart || !selectedPart.part || baseBlocks.length < 1) {
            // no change selected or no blocks present
            // how did you even get to this point?? :O
            return;
        }

        this.setState({
            baseBlocks: baseBlocks.map((block) => {
                if (block.key === selectedPart.block && block.changes !== undefined && block.changes.length > 0) {
                    return {
                        ...block,
                        changes: block.changes.map((part) => {
                            if (part.id === selectedPart.part) {
                                return {
                                    ...part,
                                    processed: true,
                                    accepted: action === 'accept',
                                };
                            }

                            return part;
                        }),
                    };
                }

                return block;
            }),
            nrOfChanges: nrOfChanges - 1,
            changesDetected: true,
        });
    }

    redirectToArea(areaId) {
        const redirect = () => {
            const url = generatePath(EDIT_AREA_PATH, {
                documentId: this.props.document.id,
                view: 'checkArea',
                areaId,
            });
            this.props.handleRedirect(url);
        };

        if (this.state.changesDetected) {
            this.confirmCancelChanges().then(() => {
                redirect();
            });
        } else {
            redirect();
        }
    }

    removeValueFromArray(arr, val) {
        if (arr === undefined || arr.length < 1) {
            return [];
        }

        for (let i = 0; i < arr.length; i++) {
            if (arr[i] === val) {
                arr.splice(i, 1);
                i--;
            }
        }

        return arr;
    }

    getUpdatedBlocks = (baseBlocks) => {
        const updatedBlocks = [];

        baseBlocks.forEach((block) => {
            // prepare data for api
            if (block.changes.length > 0) {
                const processedChanges = block.changes.filter((change) => {
                    return change.processed;
                });

                if (processedChanges.length > 0) {
                    updatedBlocks.push({ id: block.id, changes: processedChanges });
                }
            }
        });

        return updatedBlocks;
    };

    save() {
        this.setState({ refreshingContent: true }, () => {
            this.processDeletedBlocks()
                .then(() => {
                    return new Promise((resolve) => {
                        // Remove the deleted Block from the state
                        const baseBlocks = this.state.baseBlocks.filter((baseBlock) => {
                            return this.deletedBlocks.includes(baseBlock.id) === false;
                        });

                        const updatedBlocks = this.getUpdatedBlocks(baseBlocks);

                        if (updatedBlocks.length === 0) {
                            if (this.props.area.unprocessedEdits > 0) {
                                this.props
                                    .resetBlocksChanges({
                                        areaId: this.props.areaId,
                                        variantId: this.props.activeVariantId,
                                        body: baseBlocks,
                                    })
                                    .then(() => {
                                        resolve();
                                    });
                            } else {
                                resolve();
                            }
                        } else {
                            this.props
                                .processBlocksChanges({
                                    areaId: this.props.areaId,
                                    variantId: this.props.activeVariantId,
                                    body: updatedBlocks,
                                })
                                .then(() => {
                                    resolve();
                                })
                                .catch((context) => {
                                    getEnv('APP_ENV') === Constants.environments.dev &&
                                        console.log('error updating areaBlocks:', context);
                                })
                                .finally(() => {
                                    resolve();
                                });
                        }
                    });
                })
                .finally(() => {
                    this.setState(
                        {
                            changesDetected: false,
                            refreshingContent: false,
                        },
                        () => {
                            this.changesSaved();
                        },
                    );
                });
        });
    }

    switchVariant(activeVariantId, area, baseBlocks = []) {
        // Set number of edits
        const edits = HelperFunctions.getByValue(area.variantEdits, 'id', activeVariantId);

        this.setState({
            nrOfChanges: edits === undefined ? 0 : edits.count,
            baseBlocks,
            changesDetected: false,
            refreshingContent: false,
        });
    }

    render() {
        const { document } = this.props;
        const activeVariant = HelperFunctions.getByValue(document.documentVariants, 'id', this.props.activeVariantId);

        const leftSideProps = {
            activeVariant,
            activeVariantId: this.props.activeVariantId,
            areaId: this.props.areaId,
            context: Constants.blockContext.checkChanges,
            blockDisplayOptions: {
                ...Constants.defaultBlockDisplayOptions,
                markDeletedBlocks: true,
                text: {
                    ...Constants.defaultBlockDisplayOptions.text,
                    interactiveChanges: document.baseVariant.id === this.props.activeVariantId,
                },
                table: {
                    ...Constants.defaultBlockDisplayOptions.table,
                    markChanges: true,
                    interactiveChanges: true,
                },
            },
            document,
            documentStatus: document.status,
            editorDisplaySection: Constants.editorDisplaySections.left,
            packageGroups: document.packageGroups,
            processBlockChanges: this.processBlockChanges,
            processDeletedBlock: this.processDeletedBlock,
            readOnly: true,
            selectedPart: this.props.selectedPart,
            selectPart: this.props.toggleSelectedPart,
        };

        return (
            <AreaLayout justifyCenter>
                <AreaLayoutNav
                    area={this.props.area}
                    activeVariantId={this.props.activeVariantId}
                    redirectToArea={this.redirectToArea}
                    handleClose={this.confirmClose}
                >
                    <AreaNav
                        area={this.props.area}
                        handleSave={this.save}
                        changesDetected={this.state.changesDetected}
                        refreshingContent={this.state.refreshingContent}
                        changesSaved={this.state.changesSaved}
                        nrOfChanges={this.state.nrOfChanges}
                        selectedPart={this.props.selectedPart}
                        processAllChanges={this.processAllChanges}
                        processSelectedChange={this.processSelectedChange}
                    />
                </AreaLayoutNav>

                <AreaLayoutPageLeft area={this.props.area} changesDetected={this.state.changesDetected}>
                    <LeftSideContent baseBlocks={this.state.baseBlocks} leftSideProps={leftSideProps} />
                </AreaLayoutPageLeft>

                <AreaLayoutPageRight>
                    <EditAreaRightSideHeader area={this.props.area} />
                    <EditAreaRightSideContent />
                </AreaLayoutPageRight>
            </AreaLayout>
        );
    }
}

function LeftSideContent({ baseBlocks, leftSideProps }) {
    const areaData = useGetAreaBlocks();

    if (!areaData) {
        return null;
    }

    const { area, areaBlocks } = areaData;

    return (
        <div className="check-area">
            <div style={{ height: 110 }}>
                <div className="d-flex align-items-center">
                    <VariantViewButtons area={area} />
                </div>
            </div>

            <LeftSideBlocks area={area} areaBlocks={areaBlocks} baseBlocks={baseBlocks} leftSideProps={leftSideProps} />
        </div>
    );
}

export default CheckAreaModal;
