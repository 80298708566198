import { useGetAreaBaseBlocksQuery } from 'features/documents/documents';

const emptyArray = [];

export function useGetAreaBaseBlocks(areaId, variantId) {
    const { blocks } = useGetAreaBaseBlocksQuery(
        { areaId, variantId },
        {
            selectFromResult: ({ currentData }) => ({
                blocks: currentData ?? emptyArray,
            }),
        },
    );

    return blocks;
}
