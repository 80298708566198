import { useMemo } from 'react';
import DocumentHelper from 'pages/global/DocumentHelper';
import { useGetAreaBlocks } from 'pages/documents_v2/hooks/useGetAreaBlocks';
import { useGetAreaBaseBlocks } from 'pages/documents_v2/hooks/useGetAreaBaseBlocks';

const emptyArray = [];

export function useGetBlockIdsInLayout() {
    const areaData = useGetAreaBlocks();

    const blockIdsInLayout = useMemo(() => {
        if (!areaData) {
            return emptyArray;
        }

        return DocumentHelper.getBlockIdsInLayout(areaData.areaBlocks);
    }, [areaData]);

    return blockIdsInLayout;
}

export function useGetBaseBlockIdsInLayout() {
    const { baseBlocks } = useGetAreaBaseBlocks();

    const blockIdsInLayout = useMemo(() => {
        return DocumentHelper.getBlockIdsInLayout(baseBlocks);
    }, [baseBlocks]);

    return blockIdsInLayout;
}
