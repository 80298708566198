import { useState } from 'react';
import cx from 'classnames';
import { CaretRightFill, ChatFill, FileMinus, Pencil } from 'react-bootstrap-icons';
import { useGetDocument } from '../../hooks/useGetDocument';
import { useDispatch, useSelector } from 'react-redux';
import { MdFormatListNumbered } from 'react-icons/md';
import { FaTags } from 'react-icons/fa';
import { ImEyeBlocked, ImPageBreak } from 'react-icons/im';
import { BiTrashAlt } from 'react-icons/bi';
import { FilterFieldDoc } from 'pages/translation/forms/FieldHelper';
import { useGetDocumentVariants } from '../../hooks/useGetDocumentVariants';
import { setFilterDoc } from 'features/documents/documentsSlice';
import { Select } from 'components/Select';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { useGetDocumentUsers } from 'pages/documents_v2/hooks/useGetDocumentUsers';

export default function DocumentSidebar() {
    const [showContents, setShowContents] = useState(true);
    const [showVariant, setShowVariant] = useState(false);
    const [showTeamContents, setShowTeamContents] = useState(false);
    const [showWeerContents, setShowWeerContents] = useState(false);
    const document = useGetDocument();
    const { t } = useTranslation('documents');

    const toggleContents = () => {
        setShowContents((prevState) => !prevState);
    };

    const toggleVariant = () => {
        setShowVariant((prevState) => !prevState);
    };

    const toggleTeamContents = () => {
        setShowTeamContents((prevState) => !prevState);
    };

    const toggleWeerContents = () => {
        setShowWeerContents((prevState) => !prevState);
    };

    const itemClass = 'px-3 mt-2 d-flex font-size-sm';
    const iconsClass = 'px-3 mt-2 d-flex justify-content-between align-items-center font-size-sm';
    const nowrap = {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    };
    const blueBg = 'flex-end text-color bg-transparent-blue ml-3 p-0 pr-1 pl-1 font-weight-bold flex-shrink-0';
    const orangeBg = 'flex-end text-warning bg-transparent-orange ml-3 p-0 pr-1 pl-1 font-weight-bold flex-shrink-0';
    const innerDiv = 'd-flex';
    const spans = 'mb-0';

    const sections = [
        {
            title: t('document.navbar.main.sidebar.summary.title'),
            state: showContents,
            toggleFunction: toggleContents,
            content: (
                <div className={iconsClass}>
                    <span style={nowrap}>{t('document.navbar.main.sidebar.summary.numEdits')}</span>
                    <div className={orangeBg}>{document?.unprocessedEdits}</div>
                </div>
            ),
        },
        {
            title: t('document.navbar.main.sidebar.selectVariant.title'),
            state: showVariant,
            toggleFunction: toggleVariant,
            content: (
                <div className={itemClass}>
                    <IncludesVariant />
                </div>
            ),
            shouldRender: document?.type !== 'template', // Do not render if document type is 'template'
        },
        {
            title: t('document.navbar.main.sidebar.team.title'),
            state: showTeamContents,
            toggleFunction: toggleTeamContents,
            content: (
                <div className={itemClass}>
                    <UserList />
                </div>
            ),
        },
        {
            title: t('document.navbar.main.sidebar.displayOptions.title'),
            state: showWeerContents,
            toggleFunction: toggleWeerContents,
            content: (
                <>
                    <div className={iconsClass}>
                        <div className={innerDiv}>
                            <FilterFieldDoc name="showHeading" />
                            <span className={spans}>{t('document.navbar.main.sidebar.displayOptions.headings')}</span>
                        </div>
                        <div className={blueBg}>
                            <span className="text-muted font-size-sm">H1</span>
                        </div>
                    </div>
                    <div className={iconsClass}>
                        <div className={innerDiv}>
                            <FilterFieldDoc name="showEdits" />
                            <span className={spans}>{t('document.navbar.main.sidebar.displayOptions.changes')}</span>
                        </div>
                        <div className={orangeBg}>
                            <Pencil />
                        </div>
                    </div>
                    <div className={iconsClass}>
                        <div className={innerDiv} style={nowrap}>
                            <FilterFieldDoc name="showDeletedArticles" />
                            <span className={spans}>
                                {t('document.navbar.main.sidebar.displayOptions.deletedArticles')}
                            </span>
                        </div>
                        <div className={blueBg}>
                            <BiTrashAlt />
                        </div>
                    </div>
                    <div className={iconsClass}>
                        <div className={innerDiv} style={nowrap}>
                            <FilterFieldDoc name="showDeletedChapters" />
                            <span className={spans}>
                                {t('document.navbar.main.sidebar.displayOptions.deletedChapters')}
                            </span>
                        </div>
                        <div className={blueBg}>
                            <BiTrashAlt />
                        </div>
                    </div>
                    <div className={iconsClass}>
                        <div className={innerDiv}>
                            <FilterFieldDoc name="showComments" />
                            <span className={spans}>{t('document.navbar.main.sidebar.displayOptions.comments')}</span>
                        </div>
                        <div className={orangeBg}>
                            <ChatFill />
                        </div>
                    </div>
                    <div className={iconsClass}>
                        <div className={innerDiv}>
                            <FilterFieldDoc name="showLabels" />
                            <span className={spans}>{t('document.navbar.main.sidebar.displayOptions.labels')}</span>
                        </div>
                        <div className={blueBg}>
                            <FaTags />
                        </div>
                    </div>
                    <div className={iconsClass}>
                        <div className={innerDiv}>
                            <FilterFieldDoc name="showNumbering" />
                            <span className={spans}>{t('document.navbar.main.sidebar.displayOptions.numbering')}</span>
                        </div>
                        <div className={orangeBg}>
                            <MdFormatListNumbered />
                        </div>
                    </div>
                    <div className={iconsClass}>
                        <div className={innerDiv}>
                            <FilterFieldDoc name="showArticlePrefix" />
                            <span className={spans}>{t('document.navbar.main.sidebar.displayOptions.prefix')}</span>
                        </div>
                        <div className={blueBg}>
                            <span className="font-size-sm">1.b</span>
                        </div>
                    </div>
                    <div className={iconsClass}>
                        <div className={innerDiv}>
                            <FilterFieldDoc name="showPageForcing" />
                            <span className={spans}>
                                {t('document.navbar.main.sidebar.displayOptions.pageForcing')}
                            </span>
                        </div>
                        <div className={blueBg}>
                            <ImPageBreak />
                        </div>
                    </div>
                    <div className={iconsClass}>
                        <div className={innerDiv} style={nowrap}>
                            <FilterFieldDoc name="showTitleInDoc" />
                            <span className={spans}>
                                {t('document.navbar.main.sidebar.displayOptions.noTitleDisplay')}
                            </span>
                        </div>
                        <div className={orangeBg}>
                            <ImEyeBlocked />
                        </div>
                    </div>
                    <div className={iconsClass}>
                        <div className={innerDiv} style={nowrap}>
                            <FilterFieldDoc name="showSkippedArticles" />
                            <span className={spans}>
                                {t('document.navbar.main.sidebar.displayOptions.skippedArticles')}
                            </span>
                        </div>
                        <div className={blueBg}>
                            <FileMinus />
                        </div>
                    </div>
                </>
            ),
        },
    ];

    return (
        <>
            {sections.map(
                (section, index) =>
                    section.shouldRender !== false && (
                        <div className="mt-4 mb-4" key={index}>
                            <div
                                className="mb-3 px-3 d-flex justify-content-between"
                                onClick={section.toggleFunction} // Replace toggleFunction with section.toggleFunction
                            >
                                <span className="text-color font-weight-bold cursor-pointer">{section.title}</span>
                                <CaretRightFill
                                    size={14}
                                    className={cx('ml-2', {
                                        'rotate-90': section.state,
                                    })}
                                />
                            </div>
                            {section.state && section.content}
                        </div>
                    ),
            )}
        </>
    );
}

function UserList() {
    const { t } = useTranslation();
    const documentUsers = useGetDocumentUsers();

    return (
        <div>
            {documentUsers.map((documentUser) => (
                <div className="mb-2" key={`user-${documentUser.userId}`}>
                    <div>{documentUser.user.fullName}</div>
                    <div className="text-muted">
                        {t(`documents:document.navbar.main.sidebar.team.roles.${documentUser.role}`)}
                    </div>
                </div>
            ))}
        </div>
    );
}

function IncludesVariant() {
    const documentVariants = useGetDocumentVariants();
    const dispatch = useDispatch();
    const { variant } = useSelector((state) => state.documents.sidebarFilters);
    const { t } = useTranslation('documents');

    function setFieldValue(key, value) {
        dispatch(setFilterDoc({ key, value }));
    }

    const handleOptionChange = (option) => {
        setFieldValue('variant', option?.value?.id);
    };

    const filterOptions = ({ value }, input) => {
        if (_.isEmpty(input)) {
            return true;
        }

        const title = value.prefix + value.name;

        return title.toLowerCase().includes(input.toLowerCase());
    };

    const selectOptions = documentVariants.map((variant) => {
        if (variant.variantGroup) {
            return {
                label: (
                    <span className="text-color sidebar-titles variant-group-title">{variant.name.toUpperCase()}</span>
                ),
                value: variant,
                isDisabled: true,
            };
        } else {
            return {
                label: (
                    <span className="text-darkBlueAlt font-size-base">
                        {variant.name === 'default' ? (
                            `${t('document.navbar.main.sidebar.team.none')}`
                        ) : (
                            <>
                                {variant.prefix && (
                                    <span className="font-weight-bold font-size-sm mr-1">{variant.prefix} </span>
                                )}
                                {variant.name}
                            </>
                        )}
                    </span>
                ),
                value: variant,
            };
        }
    });

    const selectedVariant = selectOptions.find((option) => option.value.id === variant);

    return (
        <div className="select-variant-content">
            <Select
                id="variant-view-filter"
                defaultValue={selectedVariant}
                options={selectOptions}
                handleChange={handleOptionChange}
                filterOption={filterOptions}
                isClearable={true}
                isMulti={false}
            />
        </div>
    );
}
