import { useGetAreasQuery } from 'features/documents/documents';
import HelperFunctions from 'pages/global/HelperFunctions';
import { useMemo } from 'react';
import { createSelector } from '@reduxjs/toolkit';

const emptyArray = [];

export function useGetAreas(sectionId, documentId) {
    const sortAreas = useMemo(() => {
        // Return a unique selector instance for this page so that
        // the filtered results are correctly memoized
        return createSelector(
            (res) => res.data,
            (data) => {
                if (data) {
                    return [...data].sort(HelperFunctions.dynamicSort('sortOrder'));
                }

                return emptyArray;
            },
        );
    }, []);

    return useGetAreasQuery(
        { sectionId, documentId },
        {
            selectFromResult: (result) => {
                const items = sortAreas(result);
                const { isUninitialized, isFetching, isLoading } = result;

                return {
                    ...result,
                    areas: items,
                    isEmpty: items.length === 0 && !isUninitialized && !isLoading && !isFetching,
                    isLoading: isUninitialized || (isFetching && items.length === 0),
                };
            },
        },
    );
}
