import { FieldArray, Form as FForm, Formik } from 'formik';
import { Col, Row } from 'react-bootstrap';
import { LoadingSpinner } from 'pages/global/Spinner';
import { FormField, InputField } from 'pages/publications_v2/helpers/FieldHelper';
import { ArrowRepeat, DashCircle, PlayCircle, PlusCircle, StopCircle, XCircleFill } from 'react-bootstrap-icons';
import { DateTime } from 'luxon';
import Constants from '../../../../config/Constants';
import cx from 'classnames';
import * as yup from 'yup';
import { useGetDocument } from '../../hooks/useGetDocument';
import { IconButton, LightOutlineButton, WarningButton } from 'components/Buttons';
import { useUpdateDocumentMutation } from 'features/documents/documents';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import HelperFunctions from 'pages/global/HelperFunctions';

export default function Revisions() {
    const { t } = useTranslation('documents');

    return (
        <>
            <div className="dr-container p-4">
                <div className="subheader">
                    <h3>{t('document.navbar.settings.tabs.planning.title')}</h3>
                </div>
                <div className="dr-instellingen-container">
                    <RevisionsForm />
                </div>
            </div>
        </>
    );
}

function RevisionsForm() {
    const { t } = useTranslation('documents');
    const document = useGetDocument(undefined, false, true);
    const [updateDocument] = useUpdateDocumentMutation();

    if (!document) {
        return null;
    }

    return (
        <Formik
            initialValues={{
                deadline: document.deadline ? DateTime.fromSeconds(document.deadline).toISODate() : '',
                revisions:
                    document.revisions.map((_revision) => {
                        return {
                            ..._revision,
                            dateStartUniversal: DateTime.fromSeconds(_revision.dateStart).toISODate(),
                            dateEndUniversal: DateTime.fromSeconds(_revision.dateEnd).toISODate(),
                        };
                    }) ?? [],
            }}
            onSubmit={handleSubmit}
            validationSchema={RevisionsSchema}
            enableReinitialize
        >
            {({ values, dirty, isSubmitting, isValid }) => {
                const lastRevision = values.revisions.at(-1);

                return (
                    <FForm autoComplete="off">
                        <Row>
                            <Col md={8}>
                                <div className="font-weight-bold">
                                    <FormField
                                        name="deadline"
                                        label={
                                            <label className="mb-2 font-weight-bolder label-title">
                                                {t('document.navbar.settings.tabs.planning.deadline')}
                                            </label>
                                        }
                                        props={{
                                            type: 'date',
                                            min: lastRevision?.dateEndUniversal ?? undefined,
                                            style: { maxWidth: 180, marginLeft: 18 },
                                        }}
                                    />
                                </div>
                                <label className="mb-2 font-weight-bolder label-title">
                                    {t('document.navbar.settings.tabs.planning.rounds')}
                                </label>
                            </Col>
                        </Row>

                        <FieldArray name="revisions">
                            {({ remove, push }) => (
                                <>
                                    {values.revisions.map((_revision, index) => (
                                        <Revision
                                            revision={_revision}
                                            revisions={values.revisions}
                                            remove={() => remove(index)}
                                            index={index}
                                            document={document}
                                            key={index}
                                        />
                                    ))}

                                    <div className="mt-4 mb-3">
                                        <LightOutlineButton
                                            size="sm"
                                            className="pl-2"
                                            onClick={() => {
                                                push({
                                                    dateStartUniversal: '',
                                                    dateEndUniversal: '',
                                                });
                                            }}
                                        >
                                            <PlusCircle className="mr-1" size={14} />
                                            {t('document.navbar.settings.tabs.planning.addRound')}
                                        </LightOutlineButton>
                                    </div>
                                </>
                            )}
                        </FieldArray>

                        <WarningButton type="submit" disabled={isSubmitting || !dirty || !isValid}>
                            {t('btn.save')}
                        </WarningButton>
                        <LoadingSpinner isSubmitting={isSubmitting} />
                    </FForm>
                );
            }}
        </Formik>
    );

    function handleSubmit(values, { setSubmitting }) {
        const formData = {
            deadline: DateTime.fromISO(values.deadline).toSeconds(),
            revisions: values.revisions.map((_revision, index) => {
                return {
                    ..._revision,
                    dateStart: DateTime.fromISO(_revision.dateStartUniversal).toSeconds(),
                    dateEnd: DateTime.fromISO(_revision.dateEndUniversal).toSeconds(),
                    id: index + 1, // BC fix
                    revisionId: _revision.id, // BC fix
                    revisionNumber: index + 1,
                };
            }),
        };

        updateDocument({
            id: document.id,
            uri: `/documents/${document.id}`,
            body: formData,
            method: 'PATCH',
        }).then(() => {
            setSubmitting(false);
        });
    }
}

function Revision({ revision, revisions = [], remove, index, document }) {
    const { t } = useTranslation('documents');
    const [updateDocument] = useUpdateDocumentMutation();
    const [isReviewLoading, setReviewLoading] = useState(false);

    const previousRevision = index > 0 ? revisions[index - 1] ?? undefined : undefined;
    const { status = Constants.revisionStatus.draft } = revision;

    const isDraft = status === Constants.revisionStatus.draft;
    const canEdit = status === Constants.revisionStatus.draft;

    const inProgress = status === Constants.revisionStatus.inProgress;
    const finished = status === Constants.revisionStatus.closed;
    const canEndReview = document.canStartNextRevision === true;

    const canEditDateEnd = canEdit || inProgress;

    const showStartButton =
        revision.hasOwnProperty('id') &&
        isDraft &&
        (previousRevision === undefined || previousRevision.status === Constants.revisionStatus.closed);

    return (
        <Row className="align-items-center mb-2">
            <Col md={3}>
                <div className="flex-center">
                    <div className={cx('flex-center', { 'font-weight-bold': inProgress, 'text-muted': finished })}>
                        {inProgress && (
                            <ArrowRepeat
                                size={18}
                                className="mr-1"
                                data-uk-tooltip="Ronde is actief"
                                style={{ transform: 'rotate(45deg)' }}
                            />
                        )}
                        {finished && (
                            <DashCircle size={18} className="mr-1" data-uk-tooltip="Reviewronde is beëindigd" />
                        )}
                        {t('document.navbar.settings.tabs.planning.round')} {index + 1}
                    </div>

                    {canEdit && (
                        <IconButton
                            icon={<XCircleFill className="text-danger" size={16} />}
                            className="flex-shrink-0 ml-2"
                            tooltip="Ronde verwijderen"
                            disabled={canEdit === false}
                            onClick={remove}
                        />
                    )}
                </div>
            </Col>
            <Col md={9} className="d-flex align-items-center">
                <InputField
                    name={`revisions.${index}.dateStartUniversal`}
                    props={{
                        type: 'date',
                        value: revision.dateStartUniversal,
                        min: previousRevision ? previousRevision.dateEndUniversal : undefined,
                        style: { width: 160 },
                        disabled: canEdit === false,
                        required: true,
                    }}
                />

                <span className="mx-2">t/m</span>

                <InputField
                    name={`revisions.${index}.dateEndUniversal`}
                    props={{
                        type: 'date',
                        value: revision.dateEndUniversal,
                        min: revision.dateStartUniversal,
                        style: { width: 160 },
                        disabled: canEditDateEnd === false,
                        required: true,
                    }}
                />

                {inProgress && (
                    <LightOutlineButton
                        disabled={isReviewLoading || !canEndReview}
                        size="sm"
                        className="flex-shrink-0 ml-3 pl-2"
                        onClick={handleEndReview}
                    >
                        <StopCircle size={14} className="text-danger mr-1" />
                        Beëindigen&hellip;
                    </LightOutlineButton>
                )}

                {showStartButton && (
                    <LightOutlineButton
                        disabled={isReviewLoading}
                        size="sm"
                        className="flex-shrink-0 ml-3 pl-2"
                        onClick={handleStartReview}
                    >
                        <PlayCircle size={14} className="flex-shrink-0 text-success mr-1" />
                        {t('documents.documentAction.dropdownItem.startReview')}&hellip;
                    </LightOutlineButton>
                )}
            </Col>
        </Row>
    );

    function handleStartReview() {
        let msg = `Ronde ${index + 1} nu starten?`;

        if (revisions.length > 0 && index > 0) {
            msg =
                msg +
                ' Alle tellers zullen weer op nul gezet worden en verwijderde hoofdstukken en artikelen worden niet meer getoond. De wijzigingsgeschiedenis blijft gewoon zichtbaar.';
        }

        HelperFunctions.confirmModal(msg, undefined, false, 'Ja, nu starten', t('global:btn.cancel'))
            .then(() => {
                setReviewLoading(true);

                updateDocument({
                    id: document.id,
                    uri: `/documents/${document.id}/revisions/start`,
                    body: {},
                }).then(() => {
                    setReviewLoading(false);
                });
            })
            .catch(() => {});
    }

    function handleEndReview() {
        HelperFunctions.confirmModal(
            `Ronde ${index + 1} nu beëindigen?`,
            undefined,
            false,
            'Ja, beëindigen',
            t('global:btn.cancel'),
        )
            .then(() => {
                setReviewLoading(true);

                updateDocument({
                    id: document.id,
                    uri: `/revisions/${revision.id}/finish`,
                    body: {},
                }).then(() => {
                    setReviewLoading(false);
                });
            })
            .catch(() => {});
    }
}

const RevisionsSchema = yup.object().shape({
    revisions: yup.array().of(
        yup.object().shape({
            dateStartUniversal: yup.string().required(),
            dateEndUniversal: yup.string().required(),
        }),
    ),
});
