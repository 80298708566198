import Block from '../BlockParser';
import { useGetAreaHistoryBlocks } from 'pages/documents_v2/views/edit_area/hooks/useGetAreaHistoryBlocks';
import { useGetAreaBaseBlocks, useGetAreaBaseBlocksNotInLayout } from 'pages/documents_v2/hooks/useGetAreaBaseBlocks';
import DocumentHelper from 'pages/global/DocumentHelper';
import LoadingSpinner from 'pages/global/LoadingSpinner';

export default function BaseBlocks({ activeVariantId, area, rightSideProps }) {
    const { baseBlocks } = useGetAreaBaseBlocks();
    const baseBlocksNotInLayout = useGetAreaBaseBlocksNotInLayout();

    if (!area) {
        return null;
    }

    return (
        <div className="area-preview">
            {baseBlocksNotInLayout.map((_block) => {
                return (
                    <Block
                        baseBlocks={baseBlocks}
                        block={_block}
                        baseBlock={_block}
                        {...rightSideProps}
                        key={area.id + '_' + activeVariantId + '_' + _block.key}
                    />
                );
            })}
        </div>
    );
}

export function BaseBlocksForHistory({ activeVariantId, area, rightSideProps, selectedHistoryVersion }) {
    const { blocks, isLoading } = useGetAreaHistoryBlocks(area?.id, selectedHistoryVersion);

    if (!area || isLoading) {
        return <LoadingSpinner />;
    }

    const blockIdsInLayout = DocumentHelper.getBlockIdsInLayout(blocks);

    return (
        <div className="area-preview">
            {blocks
                .filter((_block) => !blockIdsInLayout.includes(_block.key))
                .map((_block) => {
                    return (
                        <Block
                            baseBlocks={blocks}
                            block={_block}
                            baseBlock={_block}
                            {...rightSideProps}
                            key={area.id + '_' + activeVariantId + '_' + _block.key}
                        />
                    );
                })}
        </div>
    );
}
