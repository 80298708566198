import { useParams } from 'react-router-dom';
import SettingsSidebarButton from '../../../../../components/SettingsSidebar/SettingsSidebarButton';
import { entityTypes } from 'pages/translation/config/Constants';
import { idToUri } from 'pages/global/UriHelper';
import { BlockName } from './BlockName';
import Constants from '../../../../../config/Constants';
import TextBlock from './block_types/TextBlock';
import React, { useContext, useEffect } from 'react';
import { useFormikContext } from 'formik';
import { useBlockUpdate } from '../../../hooks/useBlockUpdate';
import cx from 'classnames';
import { Draggable } from 'react-beautiful-dnd';
import { RxDragHandleDots2 } from 'react-icons/rx';
import { ArrowReturnLeft } from 'react-bootstrap-icons';
import { BlockLayoutBlock } from './block_types/BlockLayoutBlock';
import { FileBlock } from './block_types/FileBlock';
import { LabelBlock } from './block_types/LabelBlock';
import { LinkedBlock } from './block_types/LinkedBlock';
import { ReimbursementBlock } from './block_types/ReimbursementBlock';
import { TableBlock } from './block_types/TableBlock';
import { BlockHeightWrapper } from './BlockHeightWrapper';
import { useGetDocument } from '../../../hooks/useGetDocument';
import { useEntityTemplateParameters } from '../../../hooks/useEntityTemplateParameters';
import { BlockToolbar } from './BlockToolbar';
import { BlockType } from './BlockType';
import { useTranslation } from 'react-i18next';
import isEqual from 'react-fast-compare';
import { getBlockIdsInBlockLayout } from 'pages/global/BlockLayoutHelper';
import { BooleanParam, useQueryParam } from 'use-query-params';
import { SelectForCompareBlock } from 'pages/documents_v2/views/edit_area/blocks/SelectForCompareBlock';
import { EditAreaContext } from 'pages/documents_v2/views/edit_area/EditArea';

export function Block({
    block,
    parentBlock,
    renderedIndex,
    index,
    isDraggable = true,
    showSettings = true,
    readOnly = false,
    isInsideBlockLayout = false,
    handleBlockChange,
    searchTerm,
    selectedSearchBlock,
}) {
    const editAreaContext = useContext(EditAreaContext);
    const blockUpdate = useBlockUpdate(block.key);
    const [blockSelectEnabled] = useQueryParam('blockSelectEnabled', BooleanParam);

    useEffect(() => {
        if (blockUpdate) {
            const properties = _.get(blockUpdate, 'values.settings.properties', {});
            const documentVariants = _.get(blockUpdate, 'values.settings.documentVariants');

            // content, index, prop
            editAreaContext.updateBlock(properties, index, 'properties');

            if (documentVariants) {
                editAreaContext.updateBlock(documentVariants, index, 'documentVariants');
            }
        }
    }, [blockUpdate]);

    const markAsDeleted = block.deleted || block.scheduledForDeletion;
    const blockClassName = cx(`edit-area-block edit-area-block-type-${block.type} d-flex align-items-start`, {
        [`blockLayout-type-${block.properties.type ?? 'default'}`]: block.type === Constants.blockTypes.blockLayout,
    });

    if (readOnly) {
        return <>{renderReadOnlyBlock()}</>;
    }

    if (blockSelectEnabled) {
        return <SelectForCompareBlock block={block}>{renderReadOnlyBlock()}</SelectForCompareBlock>;
    }

    return (
        <DraggableBlock
            block={block}
            parentBlock={parentBlock}
            renderedIndex={renderedIndex}
            isDraggable={isDraggable}
            markAsDeleted={markAsDeleted}
            blockClassName={blockClassName}
            index={index}
            isInsideBlockLayout={isInsideBlockLayout}
            showSettings={showSettings}
            handleBlockChange={handleBlockChange}
            searchTerm={searchTerm}
            selectedSearchBlock={selectedSearchBlock}
        />
    );

    function renderReadOnlyBlock() {
        return (
            <div
                className={cx(blockClassName, {
                    'is-deleted': markAsDeleted,
                })}
            >
                <BlockContent
                    block={block}
                    parentBlock={parentBlock}
                    index={index}
                    readOnly={true}
                    isInsideBlockLayout={isInsideBlockLayout}
                    handleBlockChange={handleBlockChange}
                    searchTerm={searchTerm}
                    selectedSearchBlock={selectedSearchBlock}
                />
            </div>
        );
    }
}

function DraggableBlock({
    block,
    parentBlock,
    renderedIndex,
    isDraggable,
    markAsDeleted,
    blockClassName,
    index,
    isInsideBlockLayout,
    showSettings,
    handleBlockChange,
    searchTerm,
    selectedSearchBlock,
}) {
    return (
        <Draggable draggableId={`${block.key}`} index={renderedIndex} isDragDisabled={!isDraggable || markAsDeleted}>
            {(provided, snapshot) => (
                <div
                    className={cx(blockClassName, {
                        'is-deleted': markAsDeleted,
                        'is-dragging': snapshot.isDragging,
                    })}
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                >
                    <>
                        <div className="block-drag-handle">
                            {isDraggable && <BlockDragHandle dragHandleProps={provided.dragHandleProps} />}
                        </div>

                        <BlockContent
                            block={block}
                            parentBlock={parentBlock}
                            index={index}
                            isDragging={snapshot.isDragging}
                            isInsideBlockLayout={isInsideBlockLayout}
                            handleBlockChange={handleBlockChange}
                            searchTerm={searchTerm}
                            selectedSearchBlock={selectedSearchBlock}
                        />

                        {showSettings && <BlockSettingsIcon block={block} />}
                    </>
                </div>
            )}
        </Draggable>
    );
}

function BlockContent({
    block,
    parentBlock,
    index,
    isDragging = false,
    readOnly = false,
    isInsideBlockLayout = false,
    handleBlockChange,
    searchTerm,
    selectedSearchBlock,
}) {
    const document = useGetDocument();
    const restrictions = useEntityTemplateParameters(block, 'block', document);

    if (readOnly || !restrictions?.canChangeContent) {
        return (
            <div className="flex-grow-1 position-relative" style={{ maxWidth: '100%' }}>
                <div className="editor-toolbar">
                    <BlockType block={block} />
                </div>

                <div className="edit-area-block-content bg-white">
                    <BlockTypeContent
                        block={block}
                        index={index}
                        isDragging={isDragging}
                        readOnly={readOnly}
                        restrictions={restrictions}
                        handleBlockChange={handleBlockChange}
                        searchTerm={searchTerm}
                        selectedSearchBlock={selectedSearchBlock}
                    />
                </div>
            </div>
        );
    }

    return (
        <BlockHeightWrapper blockId={block.id} editorDisplaySection="left" isInsideBlockLayout={isInsideBlockLayout}>
            <MemoizedRenderBlockInner
                block={block}
                parentBlock={parentBlock}
                index={index}
                isInsideBlockLayout={isInsideBlockLayout}
                readOnly={readOnly}
                restrictions={restrictions}
                handleBlockChange={handleBlockChange}
                isDragging={isDragging}
                searchTerm={searchTerm}
                selectedSearchBlock={selectedSearchBlock}
            />
        </BlockHeightWrapper>
    );
}

const MemoizedRenderBlockInner = React.memo(RenderBlockInner, isEqual);

function RenderBlockInner({
    block,
    parentBlock,
    index,
    isInsideBlockLayout,
    isDragging,
    readOnly,
    restrictions,
    handleBlockChange,
    searchTerm,
    selectedSearchBlock,
}) {
    const isDeleted = block.deleted || block.scheduledForDeletion;
    const showToolbar = !isDeleted;
    return (
        <>
            {showToolbar && (
                <>
                    <BlockName block={block} />
                    <BlockToolbar
                        block={block}
                        index={index}
                        isInsideBlockLayout={isInsideBlockLayout}
                        parentBlock={parentBlock}
                    />
                </>
            )}

            <div className="edit-area-block-content bg-white">
                {isDeleted && <RestoreBlock block={block} index={index} />}

                <BlockTypeContent
                    block={block}
                    index={index}
                    isDragging={isDragging}
                    readOnly={readOnly}
                    restrictions={restrictions}
                    handleBlockChange={handleBlockChange}
                    searchTerm={searchTerm}
                    selectedSearchBlock={selectedSearchBlock}
                />
            </div>
        </>
    );
}

export function BlockTypeContent({
    block,
    index,
    isDragging,
    readOnly = false,
    restrictions,
    handleBlockChange,
    searchTerm,
    selectedSearchBlock,
    areaBlocks = undefined,
}) {
    const { type } = block;

    const isReadOnly =
        readOnly === true || block.deleted || block.scheduledForDeletion || restrictions?.canChangeContent === false;

    if (type === Constants.blockTypes.text) {
        return (
            <TextBlock
                block={block}
                index={index}
                readOnly={isReadOnly}
                handleBlockChange={handleBlockChange}
                searchTerm={searchTerm}
                selectedSearchBlock={selectedSearchBlock}
            />
        );
    }

    if (type === Constants.blockTypes.blockLayout) {
        return (
            <BlockLayoutBlock
                block={block}
                index={index}
                isDragging={isDragging}
                readOnly={isReadOnly}
                restrictions={restrictions}
                handleBlockChange={handleBlockChange}
                areaBlocks={areaBlocks}
            />
        );
    }

    if (type === Constants.blockTypes.file) {
        return <FileBlock block={block} index={index} restrictions={restrictions} readOnly={isReadOnly} />;
    }

    if (type === Constants.blockTypes.label) {
        return <LabelBlock block={block} index={index} restrictions={restrictions} readOnly={isReadOnly} />;
    }

    if (type === Constants.blockTypes.linked) {
        return <LinkedBlock block={block} />;
    }

    if (type === Constants.blockTypes.reimbursement) {
        return <ReimbursementBlock block={block} index={index} readOnly={isReadOnly} restrictions={restrictions} />;
    }

    if (type === Constants.blockTypes.table) {
        return <TableBlock block={block} index={index} restrictions={restrictions} readOnly={isReadOnly} />;
    }

    return <div>Block type {type} is not supported.</div>;
}

function RestoreBlock({ block, index }) {
    const { values, setFieldValue } = useFormikContext();
    const canUndo = !block.scheduledForDeletion;
    const { areaBlocks } = values;
    const { t } = useTranslation('documents');

    const handleBlockRestore = () => {
        if (block.type === Constants.blockTypes.blockLayout) {
            const blockIds = [block.id].concat(getBlockIdsInBlockLayout(block));

            const newAreaBlocks = areaBlocks.map((areaBlock) => {
                if (blockIds.includes(areaBlock.key)) {
                    return {
                        ...areaBlock,
                        deleted: undefined,
                    };
                }

                return areaBlock;
            });

            setFieldValue('areaBlocks', newAreaBlocks);
        } else {
            setFieldValue(`areaBlocks.${index}.deleted`, undefined);
        }
    };

    return (
        <div className="restore-block-content py-2 text-danger text-center small">
            {block.scheduledForDeletion
                ? t('document.navbar.main.editor.left.blocks.badges.markedForDeletion')
                : t('document.navbar.main.editor.left.blocks.badges.removeWhenSaving')}

            {canUndo && (
                <a onClick={handleBlockRestore}>
                    <ArrowReturnLeft size={15} className="mr-2 ml-3" />
                    {t('document.navbar.main.editor.left.blocks.badges.restoreBlock')}
                </a>
            )}
        </div>
    );
}

function BlockDragHandle({ dragHandleProps }) {
    return (
        <div
            className="flex-shrink-0 drag-handle text-color"
            style={{
                fontSize: 22,
                zIndex: 100,
                borderRight: 0,
                marginLeft: -30,
                width: 35,
                transition: 'opacity 0.2s ease-in-out',
            }}
            {...dragHandleProps}
        >
            <RxDragHandleDots2 />
        </div>
    );
}

function BlockSettingsIcon({ block }) {
    const params = useParams();
    const { documentId, areaId } = params;

    return (
        <div className="hide-when-dragging flex-shrink-0 pt-2 pl-1">
            <SettingsSidebarButton
                context={{
                    entity: block,
                    entityType: entityTypes.BLOCK,
                    documentId: parseInt(documentId),
                    areaId: parseInt(areaId),
                    uri: idToUri(block.id, 'Block'),
                    parentUri: idToUri(documentId, 'Document'),
                }}
            />
        </div>
    );
}
