const Constants = {
    areaLayout: {
        attention: 'attention',
        default: 'default',
        special: 'special',
    },
    areaTypes: {
        area: 'area',
        linkedArea: 'linked_area',
    },
    areaUserGroup: {
        opened: 'opened',
        skipped: 'skipped',
        waiting: 'waiting',
    },
    areaUserStatus: {
        done: 'done',
        inProgress: 'inProgress',
        unstarted: 'unstarted',
    },
    blockContext: {
        editor: 'editor',
        checkChanges: 'checkChanges',
        list: 'list',
    },
    blockDiffClasses: {
        diffins: 'diffins',
        diffdel: 'diffdel',
        diffmod: 'diffmod',
        ins: 'ins',
        del: 'del',
    },
    blockDiffStatus: {
        accepted: 'accepted',
        open: 'open',
        rejected: 'rejected',
    },
    blockTypes: {
        compensationTable: 'compensationTable',
        linked: 'linked',
        reimbursement: 'reimbursement',
        table: 'table',
        text: 'text',
        textExplanation: 'textExplanation',
        list: 'list',
        blockLayout: 'blockLayout',
        file: 'file',
        label: 'label',
    },
    blockUpdateGroup: {
        action: {
            edit: 'edit',
            check: 'check',
        },
    },
    comments: {
        tags: [
            { id: 1, name: 'Vraag', color: 'blue' },
            { id: 2, name: 'Actie', color: 'alert' },
            { id: 3, name: 'Opmerking', color: 'default' },
        ],
    },
    coverage: {
        'n/a': '1',
        coverage: '2',
        no_coverage: '3',
    },
    coverageTranslation: {
        1: 'N.v.t.',
        2: 'Ja',
        3: 'Nee',
    },
    defaultBlockDisplayOptions: {
        markDeletedBlocks: false,
        showBlockToolbar: false,
        table: {
            displayMode: 'table', // 'table' or 'placeholder'
            editable: false,
            markChanges: false,
            interactiveChanges: false, // can never be true if markChanges is false
        },
        text: {
            // not in use yet
            displayMode: 'plain', // 'plain' or 'tinymce'
            markChanges: false,
            interactiveChanges: false, // can never be true if markChanges is false
        },
    },
    documentRoleValues: {
        documentOwner: 'Documenteigenaar',
        documentManager: 'Document beheerder',
        finalEditor: 'Eindredacteur',
        editor: 'Redacteur',
        spectator: 'Toeschouwer',
    },
    documentStatus: {
        draft: 'draft',
        published: 'published',
        reviewInProgress: 'reviewInProgress',
        reviewFinished: 'reviewFinished',
        clone_in_progress: 'clone_in_progress',
    },
    documentStatusValues: {
        published: 'Gepubliceerd',
        reviewInProgress: 'Review actief',
        reviewFinished: 'Review afgerond',
        draft: 'Concept',
        open: 'Nieuw',
        finished: 'Afgerond',
        deleted: 'Verwijderd',
    },
    documentType: {
        default: 'default',
        library: 'library',
        terms_conditions: 'terms_conditions',
        model: 'template',
    },
    editorDisplaySections: {
        left: 'left',
        right: 'right',
    },
    editorModes: {
        edit: 'edit',
        check: 'check',
        view: 'view',
    },
    environments: {
        dev: 'dev',
        prod: 'prod',
    },
    language: {
        defaultLanguage: 'nl',
    },
    labelFolderContentTypes: {
        comments: 'Opmerkingen',
        articles: 'Artikelen',
        blocks: '(Tekst) blokken document',
        labelBlocks: 'Keuzelijsten',
        reimbursements: 'Vergoedingen',
        reimbursements_filter: 'Vergoedingen filter',
        reimbursementDescriptions: 'Omschrijving vergoedingen',
        products: 'Polissoorten',
        propositions: 'Proposities',
        collectives: 'Collectiviteiten',
        publications: 'Publicaties',
        tasks: 'Taken',
    },
    labelFolderEntities: {
        comments: [],
        articles: ['section', 'area'],
        blocks: ['block'],
        labelBlocks: ['labelBlock'],
        reimbursements: ['reimbursement'],
        reimbursements_filter: ['reimbursement'],
        reimbursementDescriptions: [],
        products: ['product'],
        propositions: ['variantPackage'],
        collectives: ['collective'],
        publications: ['publication'],
        tasks: ['task'],
    },
    listBlock: {
        typeText: 'text',
        typeTitle: 'title',
        typeList: 'list',
    },
    fileBlock: {
        alignOptions: {
            left: 'Links',
            center: 'Midden',
            right: 'Rechts',
        },
    },
    folders: {
        folder: 'folder',
        trash: 'trash',
    },
    modules: {
        comments: 'comments',
        documents: 'documents',
        guard: 'guard',
        packages: 'packages',
        publications: 'publications',
        reimbursements: 'reimbursements',
        tags: 'tags',
        users: 'users',
        labels: 'labels',
        'one-liner': 'one-liner',
        operations_list: 'operations_list',
        translations: 'translations',
        templates: 'templates',
        openai: 'openai',
        publication_access_control: 'publication_access_control',
        single_sign_on: 'single_sign_on',
        task_team: 'task_team',
    },
    opEntry: {
        typeOfEdits: {
            content_only: 'Content change',
            text_only: 'Textual change',
            coverage_changed: 'Coverage changed',
            new_article_no_generic: 'New article',
            article_removed_no_generic: 'Article removed',
            not_changed: 'Unchanged',
            variant_changed: 'Variant changed',
            variant_leniancy: 'Leniency',
            terms_and_conditions: 'Terms and Conditions',
        },
        statusCodes: {
            final: 'final',
            not_final: 'not_final',
        },
        departmentStatus: {
            finished: 'finished',
            archived: 'archived',
        },
    },
    opListUserRoles: {
        spectator: 'spectator',
        editor: 'editor',
        manager: 'manager',
        admin: 'admin',
        teamManager: 'teamManager',
    },
    opEntryLabel: {
        status: {
            nieuw: 'nieuw',
            in_behandeling_zssp: 'in_behandeling_zssp',
            productmanagement: 'productmanagement',
            itsma_ingediend: 'itsma_ingediend',
            afgerond: 'afgerond',
        },
    },
    opTaskFieldTypes: {
        text: 'Simpel tekstveld',
        textarea: 'Tekstblok',
        select: 'Dropdown selectie',
    },
    permissions: {
        createDocument: 'createDocument',
        createProduct: 'createProduct',
        createReimbursement: 'createReimbursement',
        editAccountProfile: 'editAccountProfile',
        editOperationsList: 'editOperationsList',
        editOrganisationLabels: 'editOrganisationLabels',
        editOrganisationProfile: 'editOrganisationProfile',
        editOrganisationUsers: 'editOrganisationUsers',
        editOwnDocuments: 'editOwnDocuments',
        editOwnProfile: 'editOwnProfile',
        editPackages: 'editPackages',
        editPublications: 'editPublications',
        editTags: 'editTags',
        editLabels: 'editLabels',
        editVariants: 'editVariants',
        editProducts: 'editProducts',
        editReimbursements: 'editReimbursements',
        publishPublications: 'publishPublications',
        viewOrganisationLabels: 'viewOrganisationLabels',
        viewOrganisationUsers: 'viewOrganisationUsers',
        viewOwnDocuments: 'viewOwnDocuments',
        viewPackages: 'viewPackages',
        viewPublications: 'viewPublications',
        viewTables: 'viewTables',
        editTables: 'editTables',
        viewVariants: 'viewVariants',
        menu: {
            documents: 'Document.Read',
            media: 'Media.Write.All',
            home: 'menuHome',
            labels: 'Label.Write.All',
            variants: 'menuVariants',
            products: 'menuProducts',
            packages: 'menuPackages',
            publications: 'Publication.Read',
            tags: 'Tags.Write.All',
            users: 'User.Write.All',
            reimbursements: 'Reimbursement.Read',
            operations_list: 'OperationList.Read',
            translations: 'Translation.Read',
            task_team: 'TaskTeam.Read',
        },
    },
    publication: {
        defaultSectionLayout: [
            {
                id: 'section',
                prefix: '',
                prefixStart: '',
                tableOfContents: true,
                contentCollapsable: true,
                defaultCollapsed: true,
                startOnNewPage: true,
            },
            {
                id: 'heading2',
                prefix: 'sub1',
                prefixStart: '',
                tableOfContents: true,
                contentCollapsable: true,
                defaultCollapsed: true,
            },
            {
                id: 'heading3',
                prefix: 'sub2',
                prefixStart: '',
                tableOfContents: true,
                contentCollapsable: false,
                defaultCollapsed: false,
            },
            {
                id: 'heading4',
                prefix: 'sub3',
                prefixStart: '',
                tableOfContents: false,
                contentCollapsable: false,
                defaultCollapsed: false,
            },
            {
                id: 'heading5',
                prefix: 'sub3',
                prefixStart: '',
                tableOfContents: false,
                contentCollapsable: false,
                defaultCollapsed: false,
            },
            {
                id: 'heading6',
                prefix: 'sub3',
                prefixStart: '',
                tableOfContents: false,
                contentCollapsable: false,
                defaultCollapsed: false,
            },
        ],
        type: {
            default: 'default',
            reimbursement_diff: 'reimbursement_diff',
            reimbursements_only: 'reimbursements_only',
        },
        contentTypes: {
            toc: 'toc',
            section: 'section',
            emptyPage: 'emptyPage',
            backCover: 'backCover',
        },
        patterns: {
            ipidVerbondVariant:
                '(.*),\\s?(schadeverzekeraar|levensverzekeraar|zorgverzekeraar|natura-uitvaartverzekeraar|financieel dienstverlener|gevolmachtigde agent)(,\\s?vergunning:\\s?(\\d+))?\\s?(\\([A-Z]{2}\\))',
        },
    },
    publicationStatus: {
        draft: 'draft',
        published: 'published',
        unpublished: 'unpublished',
        publishing: 'publishing',
        publishing_failed: 'publishing_failed',
    },
    publicationToken: {
        action_publish: 'publish',
        action_unpublish: 'unpublish',
        preview: 'preview',
        export_changes: 'export_changes',
    },
    publicationPreviewTokenStatus: {
        pending: 'pending',
        processing: 'processing',
        processed: 'processed',
        failed: 'failed',
    },
    opListExportTokenStatus: {
        pending: 'pending',
        processing: 'processing',
        processed: 'processed',
        failed: 'failed',
    },
    publicationVersionStatus: {
        draft: 'draft',
        scheduledForPublishing: 'scheduled_for_publishing',
        readyToPublish: 'ready_to_publish',
        published: 'published',
        scheduledForUnpublishing: 'scheduled_for_unpublishing',
        unpublished: 'unpublished',
        processing_document: 'processing_document',
        in_queue: 'in_queue',
        processing_failed: 'processing_failed',
    },
    revisionStatus: {
        approved: 'approved',
        closed: 'closed',
        draft: 'draft',
        inProgress: 'inProgress',
        waitingForApproval: 'waitingForApproval',
    },
    userDocumentRole: {
        documentManager: 'documentManager',
        editor: 'editor',
        finalEditor: 'finalEditor',
        spectator: 'spectator',
    },
    translationContainerTeamRoles: {
        manager: 'manager',
        finalEditor: 'finalEditor',
        editor: 'editor',
        spectator: 'spectator',
    },
    reimbursementTeamRoles: {
        spectator: 'spectator',
        editor: 'editor',
        finalEditor: 'finalEditor',
        manager: 'manager',
        admin: 'admin',
    },
    userFormContext: {
        create: 'create',
        myProfile: 'myProfile',
        update: 'update',
    },
    userRoles: {
        superAdmin: 'ROLE_SUPER_ADMIN',
        operationListOnly: 'ROLE_OPERATION_LIST_ONLY',
        operationListManager: 'ROLE_OPERATION_LIST_MANAGER',
        operationListEditor: 'ROLE_OPERATION_LIST_EDITOR',
        organisationAdmin: 'ROLE_ORGANISATION_ADMIN',
        billingManager: 'ROLE_BILLING_MANAGER',
        documentManager: 'ROLE_DOCUMENT_MANAGER',
        userManager: 'ROLE_USER_MANAGER',
        publicationManager: 'ROLE_PUBLICATION_MANAGER',
        publicationSpectator: 'ROLE_PUBLICATION_SPECTATOR',
        publisher: 'ROLE_PUBLISHER',
        reimbursementManager: 'ROLE_REIMBURSEMENT_MANAGER',
        mediaManager: 'ROLE_MEDIA_MANAGER',
        tagManager: 'ROLE_TAG_MANAGER',
        labelManager: 'ROLE_LABEL_MANAGER',
        templateManager: 'ROLE_TEMPLATE_MANAGER',
        templateUser: 'ROLE_TEMPLATE_USER',
        translationManager: 'ROLE_TRANSLATION_MANAGER',
        kioskManager: 'ROLE_KIOSK_MANAGER',
        taskTeamManager: 'ROLE_TASK_TEAM_MANAGER',
        taskTeamUser: 'ROLE_TASK_TEAM_USER',
    },
    variantPackageStatus: {
        Ongewijzigd: 'ongewijzigd',
        Gewijzigd: 'gewijzigd',
        Nieuw: 'nieuw',
        Vervallen: 'vervallen',
    },
};

export const AccountRestrictions = {
    document: {
        allowOnlyFromDocumentTemplate: 'document.allowOnlyFromDocumentTemplate',
    },
};

export const Permissions = {
    Account: {
        'Billing.Manage': 'Account.Billing.Manage',
        Create: 'Account.Create',
        'Read.All': 'Account.Read.All',
        'Write.All': 'Account.Write.All',
    },
    Document: {
        Create: 'Document.Create',
        Read: 'Document.Read',
    },
    DocumentFolder: {
        'Read.All': 'DocumentFolder.Read.All',
        'Write.All': 'DocumentFolder.Write.All',
    },
    LabelEntity: {
        Create: 'LabelEntity.Create',
        'Read.All': 'LabelEntity.Read.All',
        'Write.All': 'LabelEntity.Write.All',
    },
    Media: {
        'Read.All': 'Media.Read.All',
        'Write.All': 'Media.Write.All',
    },
    OperationList: {
        Create: 'OperationList.Create',
        'Read.All': 'OperationList.Read.All',
        'Write.All': 'OperationList.Write.All',
        'Read.Team': 'OperationList.Read.Team',
        'Write.Team': 'OperationList.Write.Team',
    },
    OperationListEntry: {
        Create: 'OperationListEntry.Create',
    },
    Publication: {
        Create: 'Publication.Create',
        'Publish.All': 'Publication.Publish.All',
        'Read.All': 'Publication.Read.All',
        'Write.All': 'Publication.Write.All',
        'Read.Team': 'Publication.Read.Team',
        'Write.Team': 'Publication.Write.Team',
    },
    PublicationFolder: {
        'Read.All': 'PublicationFolder.Read.All',
        'Write.All': 'PublicationFolder.Write.All',
    },
    Reimbursements: {
        Read: 'Reimbursement.Read',
        'Read.Team': 'Reimbursement.Read.Team',
        'Write.Team': 'Reimbursement.Write.Team',
        'Write.All': 'Reimbursement.Write.All',
    },
    TaskTeam: {
        Create: 'TaskTeam.Create',
        Read: 'TaskTeam.Read',
        'Read.All': 'TaskTeam.Read.All',
        'Write.All': 'TaskTeam.Write.All',
        'Manage': {
            Settings: 'TaskTeam.Manage.Settings',
            Teams: 'TaskTeam.Manage.Teams',
        },
    },
    Templates: {
        Create: 'Template.Create',
        'Read.All': 'Templates.Read.All',
        'Write.All': 'Templates.Write.All',
    },
    Kiosk: {
        Create: 'Kiosk.Create',
        'Read.All': 'Kiosk.Read.All',
        'Write.All': 'Kiosk.Write.All',
    },
    ReimbursementCategory: {
        Create: 'ReimbursementCategory.Create',
    },
    Translation: {
        Create: 'Translation.Create',
    },
    User: {
        'Read.Own': 'User.Read.Own',
        'Write.Own': 'User.Write.Own',
        'Read.All': 'User.Read.All',
        'Write.All': 'User.Write.All',
    },
    SsoConfig: {
        Create: 'SsoConfig.Create',
        'Read.All': 'SsoConfig.Read.All',
        'Write.All': 'SsoConfig.Write.All',
    },
    GuardConfig: {
        Create: 'GuardConfig.Create',
        'Read.All': 'GuardConfig.Read.All',
        'Write.All': 'GuardConfig.Write.All',
    },
};

export default Constants;
