import { useGetGuardEntities } from 'pages/publications_v2/views/guard/hooks/useGetGuardEntities';
import { ShieldShaded } from 'react-bootstrap-icons';
import { pendingStatusCodes } from 'pages/publications_v2/hooks/useGetPublicationVersions';
import Constants from 'config/Constants';
import cx from 'classnames';

export function PublicationVersionGuardStatus({ publication, version }) {
    const versionIsPending = pendingStatusCodes.includes(version.status);
    const versionFailed = version.status === Constants.publicationVersionStatus.processing_failed;

    if (versionIsPending || versionFailed) {
        const { publishedFiles = [] } = version;
        const versionFileCount = publishedFiles.length;

        return (
            <div className="flex-center text-muted">
                <ShieldShaded size={16} className="mr-2" />
                0/{versionFileCount}
            </div>
        );
    }

    return <PublicationVersionStatusInner publication={publication} version={version} />;
}

function PublicationVersionStatusInner({ publication, version }) {
    const { guardEntities } = useGetGuardEntities(publication.id, undefined, false, ['read', 'read_with_aliases']);

    const { publishedFiles = [] } = version;
    const versionFileCount = publishedFiles.length;

    const entitiesForVersion = guardEntities.filter((guardEntity) => guardEntity.publicationVersionId === version.id);
    const aliases = getAliases();
    const aliasCount = aliases.length;
    const totalCount = versionFileCount + aliasCount;

    const hasErrors = hasEntitiesWithErrors();

    const hasEntitiesWithoutToken = entitiesForVersion.some(
        (guardEntity) => !guardEntity.hasOwnProperty('guardNftToken'),
    );
    const hasAliasesNotInToken = aliases.some((alias) => alias.inToken === false);

    const allFilesMonitored = versionFileCount - entitiesForVersion.length <= 0;

    const danger = entitiesForVersion.length > 0 && hasErrors;
    const warning = !danger && (hasEntitiesWithoutToken || hasAliasesNotInToken || !allFilesMonitored);
    const success = !danger && !warning;

    return (
        <div
            className={cx('flex-center', {
                'text-success': success,
                'text-warning': warning,
                'text-danger': danger,
            })}
        >
            <ShieldShaded size={16} className="mr-2" />
            {entitiesForVersion.length + aliasCount}/{totalCount}
        </div>
    );

    function getAliases() {
        let aliases = [];

        entitiesForVersion.forEach((guardEntity) => {
            const guardAliases = guardEntity.guardAliases ?? [];
            aliases.push(...guardAliases);
        });

        return aliases;
    }

    function hasEntitiesWithErrors() {
        return entitiesForVersion.concat(...aliases).some((auditable) => auditable.lastResult !== 'valid');
    }
}
