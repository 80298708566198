import Block from '../BlockParser';
import HelperFunctions from '../../../global/HelperFunctions';
import { useGetAreaBaseBlocks } from 'pages/documents_v2/hooks/useGetAreaBaseBlocks';
import { useGetAreaBlocksNotInLayout } from 'pages/documents_v2/hooks/useGetAreaBlocks';

export default function AreaBlocks({ areaBlocks, activeVariantId, leftSideBlockProps = {} }) {
    const { baseBlocks } = useGetAreaBaseBlocks();
    const areaBlocksNotInLayout = useGetAreaBlocksNotInLayout();

    return (
        <>
            {areaBlocksNotInLayout.map((block) => (
                <Block
                    areaBlocks={areaBlocks}
                    baseBlocks={baseBlocks}
                    baseBlock={HelperFunctions.getByValue(baseBlocks, 'id', block.id)}
                    block={block}
                    {...leftSideBlockProps}
                    key={'variant_' + activeVariantId + '_block_' + block.id}
                />
            ))}
        </>
    );
}
