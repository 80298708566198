import { useGetGuardApiToken } from 'pages/publications_v2/views/guard/hooks/useGetGuardApiToken';
import { useGetUserQuery } from 'features/publications/guardApi';

export function useGetGuardUser() {
    const token = useGetGuardApiToken();

    const { user } = useGetUserQuery(
        { token },
        {
            selectFromResult: ({ data }) => ({
                user: data,
            }),
        },
    );

    return user;
}
