import { useParams } from 'react-router-dom';
import { useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { TbPlaylistAdd } from 'react-icons/tb';
import { useTranslation } from 'react-i18next';
import { AuditTrailModal } from 'pages/documents/misc/AuditTrailModal';

export default function AuditTrail() {
    const [isShowModal, setShowModal] = useState(false);
    const { t } = useTranslation('documents');
    const { documentId } = useParams();

    return (
        <>
            <div className="dr-container p-4">
                <div className="subheader">
                    <h3>{t('document.navbar.settings.tabs.audit.title')}</h3>
                </div>
                <div className="dr-instellingen-container">
                    <Row>
                        <Col xl={8}>
                            <Button size="sm" className="outline-small-button" onClick={() => setShowModal(true)}>
                                <TbPlaylistAdd size={16} className="mr-2" />
                                {t('document.navbar.settings.tabs.audit.addAudit')}
                            </Button>

                            {isShowModal && (
                                <AuditTrailModal
                                    close={() => setShowModal(false)}
                                    formData={{ documentId: parseInt(documentId) }}
                                />
                            )}
                        </Col>
                    </Row>
                </div>
            </div>
        </>
    );
}
