import Parser from 'html-react-parser';
import cx from 'classnames';

export default function InteractivePart({ part, selectPart, isSelected = false }) {
    if (part.processed) {
        return (
            <>
                {!part.accepted && part.oldText !== undefined && part.oldText !== '' && <>{Parser(part.oldText)}</>}
                {part.accepted && part.newText !== undefined && part.newText !== '' && <>{Parser(part.newText)}</>}
            </>
        );
    }

    return (
        <span
            className={cx('part', {
                selected: isSelected,
            })}
            onClick={() => selectPart()}
        >
            {part.oldText !== undefined && part.oldText !== '' && <del>{Parser(part.oldText)}</del>}
            {part.newText !== undefined && part.newText !== '' && <ins>{Parser(part.newText)}</ins>}
        </span>
    );
}
