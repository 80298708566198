import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import getEnv from '../../config/Env';
import { baseQueryWithReauth, prepareHeaders, providesList } from '../api';
import queryString from 'query-string';
import { idToUri } from 'pages/global/UriHelper';
import { entityTypes } from 'pages/translation/config/Constants';
import baseAPI from '../../api/ApiConnector_v2';

const baseUrl = getEnv('PUBLICATION_API_URL');
const uriPrefix = '/api';

const publicationApiQuery = fetchBaseQuery({
    baseUrl,
    prepareHeaders,
});

export const publicationApi = createApi({
    reducerPath: 'publicationApi',
    baseQuery: (args, api, extraOptions) => baseQueryWithReauth(args, api, extraOptions, publicationApiQuery),
    tagTypes: [
        'Publication',
        'PublicationUser',
        'UserGroup',
        'PublicationGroup',
        'PublicationVersion',
        'OpListExportToken',
        'PublicationPreview',
    ],
    endpoints: (builder) => ({
        addPropositionSummaryToken: builder.mutation({
            query: (body) => {
                return {
                    url: `${uriPrefix}/proposition_summary_tokens`,
                    method: 'POST',
                    body,
                };
            },
        }),
        addPublicationUserPassword: builder.mutation({
            query: (body) => {
                return {
                    url: `${uriPrefix}/publication_user_passwords`,
                    method: 'POST',
                    body,
                };
            },
            invalidatesTags: [{ type: 'PublicationUserPassword', id: 'LIST' }],
        }),
        deletePublicationUserPassword: builder.mutation({
            query: (uri) => {
                return {
                    url: uri,
                    method: 'DELETE',
                };
            },
            invalidatesTags: [{ type: 'PublicationUserPassword', id: 'LIST' }],
        }),
        putForBatch: builder.mutation({
            query: ({ uri, body }) => {
                return {
                    url: uri,
                    method: 'PUT',
                    body,
                };
            },
        }),
        postForBatch: builder.mutation({
            query: ({ uri, body }) => {
                return {
                    url: uri,
                    method: 'POST',
                    body,
                };
            },
        }),
        deleteForBatch: builder.mutation({
            query: (uri) => {
                return {
                    url: uri,
                    method: 'DELETE',
                };
            },
        }),
        getPublicationUserPasswords: builder.query({
            query: (id) => `${uriPrefix}/publications/${id}/publication_user_passwords`,
            transformResponse: (response) => response['hydra:member'],
            providesTags: (result) => providesList(result, 'PublicationUserPassword'),
        }),
        addPublicationUser: builder.mutation({
            query: (body) => {
                return {
                    url: `${uriPrefix}/publication_users`,
                    method: 'POST',
                    body,
                };
            },
            invalidatesTags: [
                { type: 'PublicationUser', id: 'LIST' },
                { type: 'UserGroup', id: 'LIST' },
            ],
        }),
        addUserGroup: builder.mutation({
            query: (body) => {
                return {
                    url: `${uriPrefix}/user_groups`,
                    method: 'POST',
                    body,
                };
            },
            invalidatesTags: [{ type: 'UserGroup', id: 'LIST' }],
        }),
        deletePublicationUser: builder.mutation({
            query: (uri) => {
                return {
                    url: uri,
                    method: 'DELETE',
                };
            },
            invalidatesTags: [
                { type: 'PublicationUser', id: 'LIST' },
                { type: 'UserGroup', id: 'LIST' },
            ],
        }),
        deleteUserGroup: builder.mutation({
            query: (uri) => {
                return {
                    url: uri,
                    method: 'DELETE',
                };
            },
            invalidatesTags: [
                { type: 'PublicationUser', id: 'LIST' },
                { type: 'UserGroup', id: 'LIST' },
            ],
        }),
        editPublicationUser: builder.mutation({
            query: ({ uri, body }) => {
                return {
                    url: uri,
                    method: 'PUT',
                    body,
                };
            },
            invalidatesTags: [
                { type: 'PublicationUser', id: 'LIST' },
                { type: 'UserGroup', id: 'LIST' },
            ],
        }),
        editPublicationUserPassword: builder.mutation({
            query: ({ uri, body }) => {
                return {
                    url: uri,
                    method: 'PUT',
                    body,
                };
            },
            invalidatesTags: (result, error, arg) => [{ type: 'PublicationUserPassword', id: arg.uri }],
        }),
        editUserGroup: builder.mutation({
            query: ({ uri, body }) => {
                return {
                    url: uri,
                    method: 'PUT',
                    body,
                };
            },
            invalidatesTags: [
                { type: 'PublicationUser', id: 'LIST' },
                { type: 'UserGroup', id: 'LIST' },
            ],
        }),
        getUserGroups: builder.query({
            query: () => `${uriPrefix}/user_groups`,
            transformResponse: (response) => response['hydra:member'],
            providesTags: (result) => providesList(result, 'UserGroup'),
        }),
        getPublications: builder.query({
            query: () => `${uriPrefix}/publications`,
            transformResponse: (response) => response['hydra:member'],
            providesTags: (result) => providesList(result, 'Publication'),
        }),
        getPublication: builder.query({
            query: (id) => `${uriPrefix}/publications/${id}`,
            // transformResponse: (response) => response['hydra:member'],
            providesTags: (result) => [{ type: 'Publication', id: result['@id'] }],
        }),
        editPublication: builder.mutation({
            query: ({ uri, body }) => ({
                url: uri,
                method: 'PATCH',
                headers: { 'Content-Type': 'application/merge-patch+json' },
                body: JSON.stringify(body),
            }),
            invalidatesTags: (result) => [{ type: 'Publication', id: result['@id'] }],
        }),
        addPublication: builder.mutation({
            query: (body) => ({
                url: `${uriPrefix}/publications`,
                method: 'POST',
                body,
            }),
            invalidatesTags: [{ type: 'Publication', id: 'LIST' }],
        }),
        publishPublication: builder.mutation({
            query: (body) => ({
                url: `${uriPrefix}/publication_tokens`,
                method: 'POST',
                body,
            }),
            invalidatesTags: (result) => [{ type: 'Publication', id: result['@id'] }],
        }),
        batchPublishPublications: builder.mutation({
            query: (body) => ({
                url: `${uriPrefix}/batch_publication_tokens`,
                method: 'POST',
                body,
            }),
            invalidatesTags: (result) => [{ type: 'PublicationVersion', id: 'LIST' }],
        }),
        deletePublications: builder.mutation({
            query: (body) => ({
                url: `${uriPrefix}/batch_deletes`,
                method: 'POST',
                body,
            }),
            invalidatesTags: () => [{ type: 'Publication', id: 'LIST' }],
        }),
        deletePublicationVersion: builder.mutation({
            query: ({ uri, publicationUri }) => ({
                url: uri,
                method: 'DELETE',
            }),
            invalidatesTags: (result, error, arg) => [{ type: 'Publication', id: arg.publicationUri }],
        }),
        getPublicationPreviewTokens: builder.query({
            query: (uri) => `${uri}/publication_preview_tokens`,
            transformResponse: (response) => response['hydra:member'],
            providesTags: (result, error, arg) => [
                {
                    type: 'PublicationPreview',
                    id: arg,
                },
            ],
        }),
        createPreviewToken: builder.mutation({
            query: (body) => ({
                url: `${uriPrefix}/publication_preview_tokens`,
                method: 'POST',
                body,
            }),
            invalidatesTags: (result) => [{ type: 'PublicationPreview', id: result.publication }],
        }),
        createOpListExportToken: builder.mutation({
            query: (body) => ({
                url: `${uriPrefix}/op_list_export_tokens`,
                method: 'POST',
                body,
            }),
            invalidatesTags: () => [{ type: 'OpListExportToken', id: 'LIST' }],
        }),
        getOpListExportTokens: builder.query({
            query: (opDepartmentId) => `${uriPrefix}/op_list_export_tokens?opDepartmentId=${opDepartmentId}`,
            transformResponse: (response) => response['hydra:member'],
            providesTags: (result) => providesList(result, 'OpListExportToken'),
        }),
        getDocumentVariantPublications: builder.query({
            query: (variantIds = []) =>
                `${uriPrefix}/publications/document-variants?` +
                queryString.stringify(
                    {
                        variant: variantIds,
                    },
                    { arrayFormat: 'bracket' },
                ),
            transformResponse: (response) => response['hydra:member'],
        }),
        getPublicationGroups: builder.query({
            query: () => `${uriPrefix}/publication_groups`,
            transformResponse: (response) => response['hydra:member'],
            providesTags: (result) => providesList(result, 'PublicationGroup'),
        }),
        createPublicationGroup: builder.mutation({
            query: (body) => ({
                url: `${uriPrefix}/publication_groups`,
                method: 'POST',
                body,
            }),
            invalidatesTags: () => [{ type: 'PublicationGroup', id: 'LIST' }],
        }),
        deletePublicationGroup: builder.mutation({
            query: (uri) => ({
                url: uri,
                method: 'DELETE',
            }),
            invalidatesTags: () => [{ type: 'PublicationGroup', id: 'LIST' }],
        }),
        editPublicationGroup: builder.mutation({
            query: ({ uri, body }) => ({
                url: uri,
                method: 'PATCH',
                headers: { 'Content-Type': 'application/merge-patch+json' },
                body: JSON.stringify(body),
            }),
            invalidatesTags: (result) => [{ type: 'PublicationGroup', id: result['@id'] }],
        }),
        editPublicationGroups: builder.mutation({
            query: (changes) => ({
                url: `${uriPrefix}/batch_edits`,
                method: 'POST',
                body: {
                    publicationGroups: changes,
                },
            }),
            invalidatesTags: () => [{ type: 'PublicationGroup', id: 'LIST' }],
            async onQueryStarted(body, { dispatch, queryFulfilled }) {
                const patchResult = dispatch(
                    publicationApi.util.updateQueryData('getPublicationGroups', undefined, (draft) => {
                        draft.forEach((_group) => {
                            const groupPatch = body.find((patch) => patch.id === _group.id);

                            if (groupPatch) {
                                Object.assign(_group, {
                                    ..._group,
                                    ...groupPatch.changes,
                                });
                            }
                        });
                    }),
                );
                try {
                    await queryFulfilled;
                } catch {
                    patchResult.undo();
                }
            },
        }),
        editPublications: builder.mutation({
            query: (changes) => ({
                url: `${uriPrefix}/batch_edits`,
                method: 'POST',
                body: {
                    publications: changes,
                },
            }),
            invalidatesTags: () => [
                { type: 'Publication', id: 'LIST' },
                { type: 'PublicationGroup', id: 'LIST' },
            ],
            async onQueryStarted(body, { dispatch, queryFulfilled }) {
                const patchResult = dispatch(
                    publicationApi.util.updateQueryData('getPublications', undefined, (draft) => {
                        draft.forEach((publication) => {
                            const publicationPatch = body.find((patch) => patch.id === publication.id);

                            if (publicationPatch) {
                                Object.assign(publication, {
                                    ...publication,
                                    ...publicationPatch.changes,
                                });
                            }
                        });
                    }),
                );
                try {
                    await queryFulfilled;
                } catch {
                    patchResult.undo();
                }
            },
        }),
        getPublicationUsers: builder.query({
            query: () => `${uriPrefix}/publication_users`,
            transformResponse: (response) => response['hydra:member'],
            providesTags: (result) => providesList(result, 'PublicationUser'),
        }),
        getUserPublicationPasswords: builder.query({
            query: (id) => `${uriPrefix}/publication_users/${id}/publication_user_passwords`,
            transformResponse: (response) => response['hydra:member'],
            providesTags: (result) => providesList(result, 'PublicationUserPassword'),
        }),
        getUserPasswordActions: builder.query({
            query: (uri) => `${uri}/user_password_actions`,
            transformResponse: (response) => response['hydra:member'],
            providesTags: (result, error, arg) => [{ type: 'PublicationUserPassword', id: arg }],
        }),
        refreshKioskData: builder.mutation({
            query: (body) => {
                return {
                    url: `${uriPrefix}/publications/refresh-kiosk-data`,
                    method: 'POST',
                    body,
                };
            },
        }),
        updateToken: builder.mutation({
            query: (body) => {
                return {
                    url: `${uriPrefix}/publication_tokens/update`,
                    method: 'POST',
                    body,
                };
            },
            invalidatesTags: (result, error, arg) => [
                {
                    type: 'Publication',
                    id: idToUri(arg.publicationId, entityTypes.PUBLICATION),
                },
            ],
        }),
        getPublicationVersions: builder.query({
            query: (id) => `${uriPrefix}/publications/${id}/publication_versions`,
            transformResponse: (response) => response['hydra:member'],
            providesTags: (result, error, arg) =>
                [
                    {
                        type: 'Publication',
                        id: `/api/publications/${arg}`,
                    },
                ].concat(providesList(result, 'PublicationVersion')),
        }),
        generatePublicationVersionHashes: builder.mutation({
            query: (uri) => {
                return {
                    url: `${uri}/hashes`,
                    method: 'POST',
                    body: {},
                };
            },
            invalidatesTags: (result, error, arg) => [
                {
                    type: 'PublicationVersion',
                    id: arg,
                },
            ],
        }),
    }),
});

export function downloadVersionFile(uri, fileType) {
    return baseAPI.post(`${baseUrl}${uri}/download/${fileType}`, undefined, {
        responseType: 'blob',
    });
}

export const {
    useAddPropositionSummaryTokenMutation,
    useAddPublicationUserPasswordMutation,
    useAddPublicationUserMutation,
    useAddUserGroupMutation,
    useDeletePublicationUserMutation,
    useDeletePublicationUserPasswordMutation,
    useDeleteUserGroupMutation,
    useEditPublicationUserMutation,
    useEditPublicationUserPasswordMutation,
    useEditUserGroupMutation,
    useGetUserGroupsQuery,
    useGetPublicationsQuery,
    useGetPublicationQuery,
    useEditPublicationMutation,
    useAddPublicationMutation,
    usePublishPublicationMutation,
    useDeletePublicationVersionMutation,
    useDeletePublicationsMutation,
    useGetPublicationPreviewTokensQuery,
    useCreatePreviewTokenMutation,
    useGetDocumentVariantPublicationsQuery,
    useGetPublicationUsersQuery,
    useGetUserPublicationPasswordsQuery,
    useGetPublicationGroupsQuery,
    useCreatePublicationGroupMutation,
    useDeletePublicationGroupMutation,
    useEditPublicationGroupMutation,
    useEditPublicationGroupsMutation,
    useEditPublicationsMutation,
    useGetUserPasswordActionsQuery,
    useGetPublicationUserPasswordsQuery,
    useRefreshKioskDataMutation,
    useUpdateTokenMutation,
    useDeleteForBatchMutation,
    usePutForBatchMutation,
    usePostForBatchMutation,
    useGetPublicationVersionsQuery,
    useBatchPublishPublicationsMutation,
    useCreateOpListExportTokenMutation,
    useGetOpListExportTokensQuery,
    useGeneratePublicationVersionHashesMutation,
} = publicationApi;
