import { useEffect, useState } from 'react';
import getEnv from '../../../config/Env';
import { useTranslation } from 'react-i18next';
import { FormModal } from 'pages/global/FormModal';
import { Button, Modal } from 'react-bootstrap';
import { useGetAuditTokenMutation } from 'features/documents/logApi';

export function AuditTrailModal({ close, formData }) {
    const { t } = useTranslation('documents');
    const [previewToken, setPreviewToken] = useState();
    const [getAuditToken] = useGetAuditTokenMutation();

    const previewUrl = getEnv('LOG_API_URL') + '/audit/document/';

    useEffect(() => {
        getAuditToken(formData).then((result) => {
            if (result.hasOwnProperty('data')) {
                setPreviewToken(result.data);
            }
        });
    }, [formData]);

    return (
        <FormModal size="xl" show onHide={close} title="Audit trail">
            <Modal.Body className="p-0">
                {previewToken && (
                    <iframe
                        style={{ minHeight: '70vh' }}
                        className="w-100"
                        src={previewUrl + previewToken.token}
                        title="Audit trail iframe"
                    />
                )}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="btn btn-secondary" onClick={close}>
                    {t('global:btn.close')}
                </Button>
            </Modal.Footer>
        </FormModal>
    );
}
