import { getTable, RenderTable } from '../../documents/misc/_Blocks/Table';
import { useGetTableTemplate } from 'hooks/useGetTableTemplates';

export function TableBlock({ opEntryBlock, showChanges }) {
    const table = getTable(opEntryBlock, showChanges);
    const dataSet = table.data[0] ?? [];
    const template = useGetTableTemplate(parseInt(table.templateId));

    if (!template) {
        return null;
    }

    return (
        <RenderTable
            rows={template.rows}
            columns={template.columns}
            tableData={dataSet?.rows ?? []}
            template={template}
        />
    );
}
