import Constants from '../../../../config/Constants';
import AreaHistory from './AreaHistory';
import { useSelector } from 'react-redux';
import { actions, views } from 'pages/documents_v2/config/views';
import { BlockVariantsHeader } from 'pages/documents_v2/views/edit/BlockVariantsHeader';
import { useGetDocument } from 'pages/documents_v2/hooks/useGetDocument';
import EditTemplateBlocks from './EditTemplateBlocks';
import { useParams } from 'react-router-dom';

export function EditAreaRightSideHeader({
    area,
    showRightSide = true,
    history = [],
    switchVersionHistory,
    selectedHistoryVersion,
}) {
    const { status, modelId } = useGetDocument();
    const rightSideEditorView = useSelector((state) => state.documents_v2.rightSideEditorView);
    const { view } = useParams();

    if (!showRightSide) {
        return null;
    }

    const blockGroupHasHistory = history.length > 0;

    return (
        <>
            {rightSideEditorView === views.VARIANTS && (
                <div style={{ height: 110 }}>{area.hasOwnProperty('id') && <BlockVariantsHeader area={area} />}</div>
            )}

            {rightSideEditorView === views.CHANGES && (
                <>
                    {status !== Constants.documentStatus.draft && (
                        <div style={{ height: 110 }}>
                            {blockGroupHasHistory && (
                                <AreaHistory
                                    history={history}
                                    switchVersionHistory={switchVersionHistory}
                                    selectedHistoryVersion={selectedHistoryVersion}
                                />
                            )}
                        </div>
                    )}
                </>
            )}

            {rightSideEditorView === views.TEMPLATE && modelId && view === actions.EDIT && (
                <EditTemplateBlocks area={area} />
            )}
        </>
    );
}
