import { DragDropContext } from 'react-beautiful-dnd';
import cx from 'classnames';
import React, { useCallback, useState } from 'react';
import { SelectAllCheckbox } from '../index/BulkActions';
import { RootLevelGroup, RootPublications } from './RootPublications';
import _ from 'lodash';
import HelperFunctions from '../../../global/HelperFunctions';
import { Permissions } from '../../../../config/Constants';
import RestrictedContent from '../../../global/RestrictedContent';
import { Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { publicationApi, useEditPublicationsMutation } from '../../../../features/publications/publicationApi';
import { useActiveOrganisation } from '../../../../hooks/useActiveOrganisation';
import { useGetPublicationGroups } from '../../hooks/useGetPublicationGroups';
import { PublicationGroup } from './PublicationGroup';
import LoadingSpinner from 'pages/global/LoadingSpinner';

export default function PublicationsWrapper({ publicationId, folderId, handleMove }) {
    const [isDragging, setDragging] = useState(false);
    const [editPublications] = useEditPublicationsMutation();
    const [getPublications] = publicationApi.endpoints.getPublications.useLazyQuerySubscription();
    const activeOrganisation = useActiveOrganisation();

    const onDragEndMemo = useCallback(onDragEnd, []);

    return (
        <DragDropContext onDragEnd={onDragEndMemo} onDragStart={() => setDragging(true)}>
            <div
                className={cx('data-table data-table-publications mt-3', {
                    'is-dragging': isDragging,
                })}
            >
                {folderId ? (
                    <PublicationGroup folderId={folderId} publicationId={publicationId} />
                ) : (
                    <>
                        <RootPublications folderId={folderId} publicationId={publicationId} />
                        <RootLevelGroups folderId={folderId} publicationId={publicationId} handleMove={handleMove} />
                    </>
                )}
            </div>
        </DragDropContext>
    );

    async function onDragEnd(result) {
        setDragging(false);
        const { destination, source } = result;

        // dropped outside the list
        if (!destination) {
            return;
        }

        const allPublications = await getAllPublications();
        const sourceGroup = source.droppableId === 'main' ? null : source.droppableId;
        const destinationGroup = destination.droppableId === 'main' ? null : destination.droppableId;

        // Sort within group
        if (sourceGroup === destinationGroup) {
            let newPublications = _.cloneDeep(
                allPublications
                    .filter((_pub) => _pub.publicationGroup === destinationGroup)
                    .sort(HelperFunctions.dynamicSort('sortOrder')),
            );

            const [removed] = newPublications.splice(source.index, 1);
            newPublications.splice(destination.index, 0, removed);
            newPublications = newPublications.map((_pub, index) => {
                return {
                    ..._pub,
                    sortOrder: index,
                };
            });

            const changes = newPublications.map((_pub) => {
                return {
                    id: _pub.id,
                    changes: {
                        sortOrder: _pub.sortOrder,
                    },
                };
            });

            // Update APi
            editPublications(changes);
        } else {
            // Remove from old group
            let oldPublications = _.cloneDeep(
                allPublications
                    .filter((_pub) => _pub.publicationGroup === sourceGroup)
                    .sort(HelperFunctions.dynamicSort('sortOrder')),
            );
            const [removed] = oldPublications.splice(source.index, 1);

            if (!removed) {
                return;
            }

            // Add to new group
            removed.publicationGroup = destinationGroup;
            let newPublications = _.cloneDeep(
                allPublications
                    .filter((_pub) => _pub.publicationGroup === destinationGroup)
                    .sort(HelperFunctions.dynamicSort('sortOrder')),
            );
            newPublications.splice(destination.index, 0, removed);

            newPublications = newPublications.map((_pub, index) => {
                return {
                    ..._pub,
                    sortOrder: index,
                };
            });

            const changes = newPublications.map((_pub) => {
                return {
                    id: _pub.id,
                    changes: {
                        publicationGroup: _pub.publicationGroup,
                        sortOrder: _pub.sortOrder,
                    },
                };
            });

            // Update APi
            editPublications(changes);
        }
    }

    async function getAllPublications() {
        const { data = [] } = await getPublications(undefined, true);

        return data.filter((_publication) => _publication.organisationId === activeOrganisation);
    }
}

function RootLevelGroups({ folderId, publicationId, handleMove }) {
    const { publicationGroups, isLoading } = useGetPublicationGroups(true, true);

    if (isLoading) {
        return (
            <div className="p-3">
                <LoadingSpinner inline />
            </div>
        );
    }

    return (
        <>
            {publicationGroups.map((_group, index) => (
                <RootLevelGroup
                    folderId={folderId}
                    publicationId={publicationId}
                    group={_group}
                    level={0}
                    handleMove={handleMove}
                    index={index}
                    isLast={publicationGroups.length - 1 === index}
                    key={`group-${_group.id}`}
                />
            ))}
        </>
    );
}

export function GroupHeader({ groupUri, hasMulti = false }) {
    const { t } = useTranslation('publications');

    return (
        <div className="d-flex align-items-center py-2 pl-1 pr-2">
            <RestrictedContent permission={Permissions.Publication['Write.All']}>
                <div style={{ width: 50 }}>
                    <div style={{ paddingLeft: 24, marginTop: 8 }}>
                        <SelectAllCheckbox groupUri={groupUri} />
                    </div>
                </div>
            </RestrictedContent>

            <Container fluid className="small font-weight-bolder pt-2">
                <Row>
                    <div
                        className={cx({
                            'col-md-4': !hasMulti,
                            'col-md-3': hasMulti,
                        })}
                    >
                        <div className="d-flex align-items-center pl-2">
                            <div className="text-muted">{t('publications.columnTitles.name')}</div>
                        </div>
                    </div>

                    <div className="col-md-1 text-muted text-center">{t('publications.columnTitles.guard')}</div>

                    <div className="col-md-2 text-muted">{t('publications.columnTitles.status')}</div>

                    {hasMulti && (
                        <div className="col-md-2 text-muted">{t('publications.columnTitles.publishedVersions')}</div>
                    )}

                    <div
                        className={cx('text-muted', {
                            'col-md-5': !hasMulti,
                            'col-md-4': hasMulti,
                        })}
                    >
                        {t('publications.columnTitles.document')}
                    </div>
                </Row>
            </Container>
        </div>
    );
}
