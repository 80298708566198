import { useParams } from 'react-router-dom';
import { useGetAreaBaseBlocksQuery } from 'features/documents/documents';
import { useContext, useMemo } from 'react';
import { AreaContext } from '../views/Area';
import { useGetBaseBlockIdsInLayout } from 'pages/documents_v2/hooks/useGetBlockIdsInLayout';

const emptyArray = [];

export function useGetAreaBaseBlocks() {
    const { areaId } = useParams();
    const { activeVariantId } = useContext(AreaContext);

    return useGetAreaBaseBlocksQuery(
        { areaId: parseInt(areaId), variantId: activeVariantId },
        {
            selectFromResult: ({ currentData, isUninitialized, isLoading, isFetching }) => {
                const items = currentData ?? emptyArray;

                return {
                    baseBlocks: items,
                    isEmpty: items.length === 0 && !isUninitialized && !isLoading && !isFetching,
                    isLoading: isUninitialized || (isFetching && items.length === 0),
                };
            },
            skip: activeVariantId === 0,
        },
    );
}

export function useGetAreaBaseBlocksNotInLayout() {
    const { baseBlocks } = useGetAreaBaseBlocks();
    const blockIdsInLayout = useGetBaseBlockIdsInLayout();

    const blocks = useMemo(() => {
        return baseBlocks.filter((_block) => !blockIdsInLayout.includes(_block.id));
    }, [baseBlocks, blockIdsInLayout]);

    return blocks;
}
