import Parser from 'html-react-parser';
import { LinkedBlockLabel, ToolbarLabel } from './_BlockParser/EditorToolbar';
import Constants from '../../../config/Constants';
import { Table } from './_Blocks/Table';
import Reimbursement from './_BlockParser/Reimbursement';
import TextBlock from './_Blocks/TextBlock';
import LinkedBlock from './_Blocks/LinkedBlock';
import FileBlock from './_Blocks/FileBlock';
import LabelBlock from './_Blocks/LabelBlock';
import { BlockWrapper } from './_Blocks/BlockWrapper';
import { BlockHeightWrapper } from './_Blocks/BlockHeightWrapper';
import { TextExplanation } from './_Blocks/TextExplanation';
import BlockLayout from './_BlockParser/BlockLayout';
import { useTranslation } from 'react-i18next';
import { LightOutlineButton } from 'components/Buttons';
import { HandThumbsDown, HandThumbsUp } from 'react-bootstrap-icons';

export default function BlockContainer(props) {
    const { t } = useTranslation('documents');
    const { block, context, editorDisplaySection } = props;

    const blockId = block.key !== undefined && block.key !== null ? block.key : block.id;

    // Hide the block on the right side if it's new and has no base content yet
    if (
        context === Constants.blockContext.checkChanges &&
        editorDisplaySection === Constants.editorDisplaySections.right &&
        block.type !== Constants.blockTypes.blockLayout &&
        block.new &&
        block.baseContent === ''
    ) {
        return null;
    }

    // Hide the block on the right side if the deletion has been accepted but the save button has not been clicked
    if (context === Constants.blockContext.checkChanges && block.deleted && !block.deletionAccepted) {
        return null;
    }

    const blockDisplayOptions = props.blockDisplayOptions
        ? props.blockDisplayOptions
        : Constants.defaultBlockDisplayOptions;

    const markBlockDeleted = blockDisplayOptions.markDeletedBlocks && block.deleted;
    const markBlockScheduledForkDeletion = blockDisplayOptions.markDeletedBlocks && block.scheduledForDeletion;
    const showToolbar =
        blockDisplayOptions.showBlockToolbar !== undefined ? blockDisplayOptions.showBlockToolbar : true;

    if (context === Constants.blockContext.editor || context === Constants.blockContext.list) {
        return <BlockWrapper blockId={blockId} showToolbar={showToolbar} props={props} />;
    }

    if (context === Constants.blockContext.checkChanges) {
        return (
            <BlockHeightWrapper
                blockId={blockId}
                editorDisplaySection={editorDisplaySection}
                markAsDeleted={
                    blockDisplayOptions.markDeletedBlocks && (markBlockDeleted || markBlockScheduledForkDeletion)
                }
            >
                <>
                    {markBlockDeleted && (
                        <div className="py-2 uk-background-danger-light text-danger text-center small">
                            {t('document.navbar.main.editor.left.blocks.blockDeleted')}
                        </div>
                    )}

                    {markBlockScheduledForkDeletion && (
                        <div className="py-2 uk-background-danger-light text-danger text-center small">
                            <div className="mb-2">
                                {t('document.navbar.main.editor.left.blocks.badges.markedForDeletion')}
                            </div>

                            {blockDisplayOptions.text.interactiveChanges && (
                                <div className="d-flex justify-content-center">
                                    <LightOutlineButton
                                        size="sm"
                                        className="px-2 mr-2"
                                        onClick={() => props.processDeletedBlock(blockId, 'accept')}
                                    >
                                        <HandThumbsUp size={14} className="mr-2" />
                                        {t('document.navbar.main.editor.left.blocks.acceptDeletion')}
                                    </LightOutlineButton>

                                    <LightOutlineButton
                                        size="sm"
                                        className="px-2"
                                        onClick={() => props.processDeletedBlock(blockId, 'reject')}
                                    >
                                        <HandThumbsDown size={14} className="mr-2" />
                                        {t('document.navbar.main.editor.left.blocks.rejectDeletion')}
                                    </LightOutlineButton>
                                </div>
                            )}
                        </div>
                    )}

                    <Block {...props} blockDisplayOptions={blockDisplayOptions} blockId={blockId} />

                    <div className="editor-toolbar">
                        <div className="uk-button-group uk-button-group-small uk-margin-small-right align-items-center">
                            {block.new && (
                                <span
                                    className="mr-2 px-2 badge badge-success"
                                    style={{ paddingTop: 2, paddingBottom: 2, lineHeight: '18px' }}
                                    data-uk-tooltip={t('document.navbar.main.editor.left.blocks.tooltips.blockInNew')}
                                >
                                    {t('document.navbar.main.editor.left.blocks.badges.newBlock')}
                                </span>
                            )}

                            {block.type === Constants.blockTypes.linked && <LinkedBlockLabel block={block} />}

                            {block.type === Constants.blockTypes.label && <ToolbarLabel text="Keuze opties" />}
                        </div>
                    </div>
                </>
            </BlockHeightWrapper>
        );
    }

    return <div>Unsupported context '{context}'</div>;
}

export const Block = (props) => {
    const { block, blockId, handleBlockChange } = props;

    switch (block.type) {
        case Constants.blockTypes.text:
            return <TextBlock {...props} />;
        case Constants.blockTypes.table:
            return (
                <Table
                    {...props}
                    handleBlockChange={(content, ignoreChanges) => handleBlockChange(blockId, content, ignoreChanges)}
                />
            );
        case Constants.blockTypes.reimbursement:
            return (
                <Reimbursement
                    {...props}
                    handleBlockChange={(content, ignoreChanges) => handleBlockChange(blockId, content, ignoreChanges)}
                />
            );
        case Constants.blockTypes.linked:
            return (
                <LinkedBlock
                    {...props}
                    handleBlockChange={(content, ignoreChanges) => handleBlockChange(blockId, content, ignoreChanges)}
                />
            );
        case Constants.blockTypes.blockLayout:
            return <BlockLayout {...props} />;
        case Constants.blockTypes.file:
            return (
                <FileBlock
                    {...props}
                    handleBlockChange={(content, ignoreChanges) => handleBlockChange(blockId, content, ignoreChanges)}
                />
            );
        case Constants.blockTypes.label:
            return (
                <LabelBlock
                    block={block}
                    context={props.context}
                    readOnly={props.readOnly}
                    showTextEditor={props.showTextEditor}
                    editorDisplaySection={props.editorDisplaySection}
                    markChanges={props.blockDisplayOptions.text.markChanges}
                    processBlockChanges={props.processBlockChanges}
                    handleBlockChange={(content, properties) => handleBlockChange(blockId, content, false, properties)}
                />
            );
        case Constants.blockTypes.textExplanation:
            return <TextExplanation block={block} />;
    }

    return <Placeholder blockId={blockId} {...props} />;
};

const Placeholder = (props) => {
    const { block } = props;
    return (
        <div className="uk-margin-top uk-padding-small uk-position-relative uk-background-muted uk-text-muted uk-text-center">
            {block.type === Constants.blockTypes.table ? (
                Parser(block.latestContent)
            ) : (
                <p className="uk-margin-remove">{block.latestContent}</p>
            )}
        </div>
    );
};
