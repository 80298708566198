import { useParams } from 'react-router-dom';
import { useGetDocumentQuery } from 'features/documents/documents';

/**
 * @param {string|number} [id]
 * @param {boolean} [showAreas=false]
 */
export function useGetDocument(id, showAreas = false, refetchOnMountOrArgChange = false) {
    const { documentId } = useParams();
    const documentIdForQuery = id ? parseInt(id) : parseInt(documentId);

    const { document } = useGetDocumentQuery(
        { id: documentIdForQuery, showAreas },
        {
            selectFromResult: ({ currentData }) => ({
                document: currentData,
            }),
            skip: !documentIdForQuery,
            refetchOnMountOrArgChange,
        },
    );

    return document;
}
