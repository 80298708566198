import { useGetGuardAliasesQuery } from 'features/publications/guardApi';
import HelperFunctions from '../../../../global/HelperFunctions';
import { useGetGuardApiToken } from 'pages/publications_v2/views/guard/hooks/useGetGuardApiToken';

export function useGetGuardAliases(guardEntityUri) {
    const guardApiToken = useGetGuardApiToken();

    let params = {};

    return useGetGuardAliasesQuery(
        { uri: guardEntityUri, token: guardApiToken, params },
        {
            selectFromResult: (result) => HelperFunctions.queryResult(result, false),
            skip: !guardEntityUri,
        },
    );
}
