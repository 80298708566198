import { useFormikContext } from 'formik';
import Parser from 'html-react-parser';
import { useTranslation } from 'react-i18next';
import { useGetAreaBaseBlocks } from 'pages/documents_v2/hooks/useGetAreaBaseBlocks';

export function LinkedBlock({ block }) {
    const { getFieldMeta } = useFormikContext();
    const { t } = useTranslation('documents');
    const fieldMeta = getFieldMeta('areaBlocks');
    const initialBlock = fieldMeta.initialValue.find((_block) => _block.key === block.key);

    // The content of the linked block is stored in the baseBlocks
    const linkedBlock = useGetLinkedBlock(block.key);

    if ((block.latestContent === '' && !linkedBlock) || (linkedBlock && linkedBlock.latestContent === '')) {
        return <EmptyBlockWarning />;
    }

    const isDirty = block.id === null || block.latestContent !== initialBlock?.latestContent;

    if (isDirty) {
        return <PreviewNotAvailableWarning />;
    }

    // Fallback for loading state
    if (!linkedBlock) {
        return null;
    }

    return (
        <div className="p-4 bg-light-gray" style={{ borderRadius: 5 }}>
            <div className="tiny-mce-content-body-linked">{Parser(linkedBlock.latestContent)}</div>
        </div>
    );
}

function useGetLinkedBlock(blockKey) {
    const { baseBlocks } = useGetAreaBaseBlocks();

    return baseBlocks.find((baseBlock) => baseBlock.key === blockKey);
}

function EmptyBlockWarning() {
    const { t } = useTranslation('documents');

    return (
        <div className="p-4">
            <div className="small text-warning">
                {t('document.navbar.main.editor.left.blocks.types.linked.linkedBlockEmpty')}
            </div>
        </div>
    );
}

function PreviewNotAvailableWarning() {
    const { t } = useTranslation('documents');

    return (
        <div className="p-4">
            <div className="small text-secondary">
                {t('document.navbar.main.editor.left.blocks.types.linked.linkedBlockPreview')}
            </div>
        </div>
    );
}
