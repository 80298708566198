import { LastResultsBars } from './LastResultsBars';
import { useGetGuardAliases } from '../hooks/useGetGuardAliases';
import { GuardEntityAudits } from '../GuardEntityAudits';
import cx from 'classnames';
import { useState } from 'react';
import { CaretRightFill } from 'react-bootstrap-icons';
import { AuditableStatusIcon } from '../AuditableStatusIcon';
import { Badge } from 'react-bootstrap';
import { AuditableDashboard } from './AuditableDashboard';
import { AuditableInCirculationIcon } from '../AuditableInCirculationIcon';
import { useTranslation } from 'react-i18next';
import { TableRowSkeleton } from 'components/Skeleton';
import { AuditableNftTokenIcon } from 'pages/publications_v2/views/guard/AuditableNftTokenIcon';

export function Auditables({ guardEntity }) {
    const { t } = useTranslation();
    const { items, isLoading, isEmpty } = useGetGuardAliases(guardEntity['@id']);
    const [expandedItem, setExpandedItem] = useState();

    return (
        <div className="mb-4">
            <div className="font-weight-bold mb-2" style={{ fontSize: '1rem' }}>
                {t('publications:guard.headings.documents')}
            </div>

            <table className="table dr-table">
                <thead>
                    <tr>
                        <th className="px-0" style={{ minWidth: 24 }} />
                        <th className="pr-1">{t('publications:guard.headings.type')}</th>
                        <th>{t('publications:guard.headings.url')}</th>
                        <th className="px-1">Nft token</th>
                        <th className="px-1">{t('publications:guard.headings.inCirculation')}</th>
                        <th colSpan={2}>{t('publications:guard.headings.status')}</th>
                    </tr>
                </thead>
                <tbody>
                    {isLoading && isEmpty ? (
                        <>
                            <TableRowSkeleton cellCount={6} rowCount={2} />
                        </>
                    ) : (
                        <>
                            <Auditable
                                auditable={guardEntity}
                                guardEntity={guardEntity}
                                isExpanded={expandedItem === guardEntity['@id']}
                                toggleExpandedItem={toggleExpandedItem}
                            />

                            {items.map((guardAlias) => (
                                <Auditable
                                    auditable={guardAlias}
                                    guardEntity={guardEntity}
                                    isAlias={true}
                                    isExpanded={expandedItem === guardAlias['@id']}
                                    toggleExpandedItem={toggleExpandedItem}
                                    key={`alias-${guardAlias.id}`}
                                />
                            ))}
                        </>
                    )}
                </tbody>
            </table>
        </div>
    );

    function toggleExpandedItem(uri) {
        setExpandedItem(expandedItem === uri ? undefined : uri);
    }
}

function Auditable({ auditable, guardEntity, isAlias = false, toggleExpandedItem, isExpanded = false }) {
    const { t } = useTranslation();

    return (
        <>
            <tr
                className={cx('cursor-pointer', { 'is-expanded': isExpanded })}
                onClick={() => toggleExpandedItem(auditable['@id'])}
            >
                <td className="px-0">
                    <CaretRightFill
                        size={12}
                        className={cx('ml-2', {
                            'rotate-90': isExpanded,
                        })}
                    />
                </td>
                <td className="pr-1">
                    {isAlias ? (
                        <Badge variant="info" className="m-0">
                            {t('publications:guard.badge.typeAlias')}
                        </Badge>
                    ) : (
                        <Badge variant="primary" className="m-0">
                            {t('publications:guard.badge.typeSource')}
                        </Badge>
                    )}
                </td>
                <td style={{ wordBreak: 'break-all' }} className={cx({ 'font-weight-bold text-body': isExpanded })}>
                    {auditable.url.replace('https://', '')}
                </td>
                <td className="px-1">
                    <AuditableNftTokenIcon auditable={auditable} />
                </td>
                <td className="px-1">
                    <AuditableInCirculationIcon auditable={auditable} />
                </td>
                <td className="pr-2">
                    <AuditableStatusIcon auditable={auditable} guardEntity={guardEntity} />
                </td>
                <td className="pl-2">
                    <LastResultsBars auditable={auditable} parentUri={isAlias ? guardEntity['@id'] : undefined} />
                </td>
            </tr>
            {isExpanded && (
                <AuditableExpanded
                    auditable={auditable}
                    guardEntity={guardEntity}
                    parentUri={isAlias ? guardEntity['@id'] : undefined}
                />
            )}
        </>
    );
}

function AuditableExpanded({ auditable, guardEntity, parentUri }) {
    return (
        <tr className="expanded-row-details">
            <td colSpan={7}>
                <div className="px-3 pb-3 pt-1">
                    <AuditableDashboard auditable={auditable} guardEntity={guardEntity} parentUri={parentUri} />

                    <div className="dr-container px-3 pt-3">
                        <GuardEntityAudits auditable={auditable} guardEntity={guardEntity} parentUri={parentUri} />
                    </div>
                </div>
            </td>
        </tr>
    );
}
