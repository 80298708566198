import { useGetGuardEntitiesQuery } from 'features/publications/guardApi';
import { useGetGuardApiToken } from 'pages/publications_v2/views/guard/hooks/useGetGuardApiToken';

const emptyArray = [];

export function useGetGuardEntities(
    publicationId = undefined,
    publicationVersionId = undefined,
    skip = false,
    groups = [],
) {
    const guardApiToken = useGetGuardApiToken();

    let params = {};

    if (publicationId) {
        params.publicationId = publicationId;
    }

    if (publicationVersionId) {
        params.publicationVersionId = publicationVersionId;
    }

    if (publicationId || publicationVersionId) {
        params.pagination = false;
    }

    if (groups.length > 0) {
        params.groups = groups;
    }

    return useGetGuardEntitiesQuery(
        { token: guardApiToken, params },
        {
            selectFromResult: ({ data, isUninitialized, isFetching }) => {
                const guardEntities = data ?? emptyArray;

                return {
                    guardEntities,
                    isLoading: isUninitialized || (isFetching && guardEntities.length === 0),
                };
            },
            skip,
        },
    );
}
