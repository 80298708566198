import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';
import { useGetUserFullName } from 'hooks/useGetUserFullName';
import ReactSelect, { components } from 'react-select';
import { ClockHistory } from 'react-bootstrap-icons';

export default function AreaHistory({ history = [], switchVersionHistory, selectedHistoryVersion }) {
    const { t } = useTranslation('documents');

    const defaultOption = {
        value: undefined,
        label: t('document.navbar.main.editor.right.RightSideViewSwitch.currentVersion'),
    };

    const historyOptions = history.map((version) => {
        return {
            value: version,
            label: <HistoryLabel version={version} />,
        };
    });

    const options = [defaultOption].concat(historyOptions);

    return (
        <div>
            <HistorySelect
                label={
                    <div className="flex-center">
                        <ClockHistory size={16} className="mr-2" />
                        {t('document.navbar.main.editor.right.RightSideViewSwitch.historyLabel')}
                    </div>
                }
                id="history-select"
                isMulti={false}
                options={options}
                selectedOption={
                    selectedHistoryVersion
                        ? historyOptions.find(
                              (option) =>
                                  option.value.startGroupId === selectedHistoryVersion.startGroupId &&
                                  option.value.endGroupId === selectedHistoryVersion.endGroupId,
                          )
                        : undefined
                }
                handleChange={handleChange}
                defaultValue={defaultOption}
            />

            {selectedHistoryVersion && <ActiveVersion selectedHistoryVersion={selectedHistoryVersion} />}
        </div>
    );

    function handleChange({ value }) {
        switchVersionHistory(value);
    }
}

function HistorySelect({
    label,
    id,
    options = [],
    isMulti = false,
    isClearable = false,
    handleChange,
    defaultValue = undefined,
    selectedOption,
    filterOption,
    isDisabled = false,
}) {
    const { t } = useTranslation('documents');

    const style = {
        wrapper: {
            border: '1px solid #ccc',
            borderRadius: 4,
        },
        label: {
            fontSize: 13,
            lineHeight: '40px',
            color: '#6c757d',
        },
    };

    const SingleValue = ({ children, ...props }) => {
        if (!props.data.value) {
            return <components.SingleValue {...props}>{props.data.label}</components.SingleValue>;
        }

        const version = props.data.value;

        // Start date
        const startDate = DateTime.fromSeconds(version.startDate).toLocaleString(DateTime.DATE_MED);
        const startTime = DateTime.fromSeconds(version.startDate).toLocaleString(DateTime.TIME_24_SIMPLE);

        // End date
        const endDate = DateTime.fromSeconds(version.endDate).toLocaleString(DateTime.DATE_MED);
        const endTime = DateTime.fromSeconds(version.endDate).toLocaleString(DateTime.TIME_24_SIMPLE);

        return (
            <components.SingleValue {...props}>
                <span>
                    {startDate} {startTime}{' '}
                    {(startDate !== endDate || startTime !== endTime) &&
                        t('document.navbar.main.editor.right.RightSideViewSwitch.until')}{' '}
                    {startDate !== endDate && endDate} {startTime !== endTime && endTime}
                </span>
            </components.SingleValue>
        );
    };

    return (
        <div style={style.wrapper}>
            <div className="d-flex align-items-center">
                {label && (
                    <label htmlFor={id} className="font-weight-bold flex-shrink-0 mb-0 ml-3 mr-2" style={style.label}>
                        {label}
                    </label>
                )}
                <ReactSelect
                    value={selectedOption}
                    inputId={id}
                    placeholder={`${t('document.navbar.main.sidebar.selectVariant.titleSelect')}...`}
                    className="flex-grow-1"
                    isMulti={isMulti}
                    isClearable={isClearable}
                    isDisabled={isDisabled}
                    defaultValue={defaultValue}
                    options={options}
                    onChange={handleChange}
                    components={{ SingleValue }}
                    filterOption={filterOption}
                    menuPortalTarget={document.body}
                    noOptionsMessage={(obj) => <>Geen opties</>}
                    styles={{
                        control: (baseStyles, state) => ({
                            ...baseStyles,
                            borderWidth: 0,
                            boxShadow: state.isFocused ? '0px 0 3px 2px #225a8a21' : undefined,
                            minHeight: 40,
                        }),
                        menuPortal: (baseStyles) => ({ ...baseStyles, zIndex: 1300 }),
                        option: (baseStyles, state) => ({
                            ...baseStyles,
                            fontSize: 13,
                        }),
                        multiValue: (baseStyles) => ({
                            ...baseStyles,
                            color: '#003763',
                            backgroundColor: '#eaf1fc',
                        }),
                        multiValueLabel: (baseStyles) => ({
                            ...baseStyles,
                            color: '#003763',
                            fontWeight: 500,
                            fontSize: '75%',
                        }),
                        placeholder: (baseStyles) => ({
                            ...baseStyles,
                            color: '#b8b8b8',
                            fontSize: 13,
                            lineHeight: '16px',
                        }),
                    }}
                    theme={(theme) => ({ ...theme, borderRadius: 4 })}
                />
            </div>
        </div>
    );
}

function HistoryLabel({ version }) {
    const creator = useGetUserFullName(version.createdBy, false);
    const { t } = useTranslation('documents');

    // Start date
    const startDate = DateTime.fromSeconds(version.startDate).toLocaleString(DateTime.DATE_MED);
    const startTime = DateTime.fromSeconds(version.startDate).toLocaleString(DateTime.TIME_24_SIMPLE);

    // End date
    const endDate = DateTime.fromSeconds(version.endDate).toLocaleString(DateTime.DATE_MED);
    const endTime = DateTime.fromSeconds(version.endDate).toLocaleString(DateTime.TIME_24_SIMPLE);

    return (
        <>
            <span>
                {startDate} {startTime}{' '}
                {(startDate !== endDate || startTime !== endTime) &&
                    t('document.navbar.main.editor.right.RightSideViewSwitch.until')}{' '}
                {startDate !== endDate && endDate} {startTime !== endTime && endTime}
            </span>
            <br />
            <span className="text-muted small">
                {creator}
                {version.numberOfEdits > 0 || version.numberOfChecks > 0 ? ': ' : ''}
                {version.numberOfEdits > 0 && (
                    <>
                        {t('documents:document.navbar.main.editor.right.RightSideViewSwitch.changesSuggested', {
                            count: version.numberOfEdits,
                        })}
                    </>
                )}
                {version.numberOfChecks > 0 && (
                    <>
                        {t('documents:document.navbar.main.editor.right.RightSideViewSwitch.changesProcessed_other', {
                            count: version.numberOfChecks,
                        })}
                    </>
                )}
            </span>
        </>
    );
}

function ActiveVersion({ selectedHistoryVersion }) {
    const { t } = useTranslation('documents');
    const activeVersionUser = useGetUserFullName(selectedHistoryVersion.createdBy, false);

    return (
        <div className="pt-2 ml-2 small">
            {t('document.navbar.main.editor.right.RightSideViewSwitch.byUser')}&nbsp;{activeVersionUser}:&nbsp;
            {t('documents:document.navbar.main.editor.right.RightSideViewSwitch.changesSuggested', {
                count: selectedHistoryVersion.numberOfEdits,
            })}
            ,&nbsp;
            {t('documents:document.navbar.main.editor.right.RightSideViewSwitch.changesProcessed_other', {
                count: selectedHistoryVersion.numberOfChecks,
            })}
        </div>
    );
}
