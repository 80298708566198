import { useGetAreaHistoryBlocksQuery } from 'features/documents/documents';
import { useContext } from 'react';
import { AreaContext } from 'pages/documents_v2/views/Area';

const emptyArray = [];

export function useGetAreaHistoryBlocks(areaId, activeVersion) {
    const { activeVariantId } = useContext(AreaContext);

    return useGetAreaHistoryBlocksQuery(
        { areaId, variantId: activeVariantId, start: activeVersion?.startGroupId, end: activeVersion?.endGroupId },
        {
            selectFromResult: ({ currentData, isUninitialized, isLoading, isFetching }) => {
                const items = currentData ?? emptyArray;

                return {
                    blocks: items,
                    isEmpty: items.length === 0 && !isUninitialized && !isLoading && !isFetching,
                    isLoading: isUninitialized || (isFetching && items.length === 0),
                };
            },
            skip: !areaId || !activeVersion,
        },
    );
}
