import HelperFunctions from './HelperFunctions';
import Constants from '../../config/Constants';

const DocumentHelper = {
    getBlockIdsInLayout(blocks = []) {
        const allBlockKeys = [];

        blocks
            .filter(_block => _block.type === Constants.blockTypes.blockLayout)
            .forEach(_block => {
                const currentBlockData = HelperFunctions.tryParseJSON(_block.latestContent);

                if (currentBlockData && currentBlockData.hasOwnProperty('data') && !!currentBlockData.data) {
                    currentBlockData.data.forEach(_row => {
                        _row.forEach(_column => {
                            _column.forEach(_item => {
                                allBlockKeys.push(_item.id);
                            });
                        });
                    });
                }
            });

        return allBlockKeys;
    },
    getPrefix(object, variantId) {
        let prefix = null;

        if (object.properties !== undefined && object.properties !== null && object.properties.hasOwnProperty('titlePrefixes') && object.properties.titlePrefixes.length > 0) {
            const titlePrefixes = object.properties.titlePrefixes;

            if (variantId !== undefined) {
                const titlePrefix = HelperFunctions.getByValue(titlePrefixes, 'variantId', variantId);

                if (titlePrefix !== undefined && titlePrefix.hasOwnProperty('text')) {
                    prefix = titlePrefix.text;
                }
            }
        }

        return prefix;
    },
};

export default DocumentHelper;
