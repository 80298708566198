import { useContext, useEffect, useState } from 'react';
import { AreaContext } from '../views/Area';
import { useGetDocument } from '../hooks/useGetDocument';
import { useGetVariant } from '../hooks/useGetVariant';
import { useParams } from 'react-router-dom';
import { FormModal } from 'pages/global/FormModal';
import { Col, Form, Modal, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { DocRevSelect } from 'pages/publications_v2/helpers/FieldHelper';
import LoadingSpinner from 'pages/global/LoadingSpinner';
import _ from 'lodash';
import { useGetAreaBlocksQuery } from 'features/documents/documents';
import Constants from 'config/Constants';
import { useGetBaseVariant } from 'pages/documents_v2/hooks/useGetBaseVariant';
import cx from 'classnames';
import Block from 'pages/documents/misc/BlockParser';
import HelperFunctions from 'pages/global/HelperFunctions';
import { useGetDocuments } from 'pages/documents_v2/hooks/useGetDocuments';
import { SecondaryButton, WarningButton } from 'components/Buttons';

export function LinkedBlockModal({ block, handleClose, handleBlockChange }) {
    const { t } = useTranslation('documents');
    const document = useGetDocument();
    const { activeVariantId } = useContext(AreaContext);
    const activeVariant = useGetVariant(activeVariantId);

    const defaultData = HelperFunctions.tryParseJSON(block.latestContent, {
        areaId: undefined,
        blockId: undefined,
        documentId: document.id,
    });

    const [selectedDocument, setSelectedDocument] = useState(defaultData.documentId);
    const [selectedArea, setSelectedArea] = useState(defaultData.areaId);
    const [selectedBlock, setSelectedBlock] = useState(defaultData.blockId);

    return (
        <FormModal
            show={true}
            onHide={handleClose}
            title={t('document.navbar.main.editor.left.blocks.types.linked.linkedBlock')}
            size="xl"
            scrollable={true}
        >
            <Modal.Body>
                <DocumentSelect defaultDocumentId={defaultData.documentId} onDocumentSelect={onDocumentSelect} />
                <AreaSelect
                    selectedDocument={selectedDocument}
                    selectedArea={selectedArea}
                    setSelectedArea={setSelectedArea}
                    setSelectedBlock={setSelectedBlock}
                />
                <BlockSelect
                    selectedDocument={selectedDocument}
                    selectedArea={selectedArea}
                    selectedBlock={selectedBlock}
                    setSelectedBlock={setSelectedBlock}
                    activeVariant={activeVariant}
                />
            </Modal.Body>

            <Modal.Footer>
                <SecondaryButton onClick={handleClose}>{t('global:btn.cancel')}</SecondaryButton>
                <WarningButton
                    disabled={defaultData.blockId === selectedBlock || !selectedBlock}
                    onClick={handleSubmit}
                >
                    {t('global:btn.save')}
                </WarningButton>
            </Modal.Footer>
        </FormModal>
    );

    function onDocumentSelect(newDocumentId) {
        console.log({ newDocumentId });
        if (selectedDocument !== newDocumentId) {
            setSelectedDocument(newDocumentId);
            setSelectedArea(undefined);
            setSelectedBlock(undefined);
        }
    }

    function handleSubmit() {
        const latestContent = JSON.stringify({
            areaId: selectedArea,
            blockId: selectedBlock,
            documentId: selectedDocument,
        });

        handleBlockChange(latestContent);
    }
}

export function DocumentSelect({ defaultDocumentId, onDocumentSelect }) {
    const currentDocument = useGetDocument();
    const { t } = useTranslation('documents');
    const options = getDocumentOptions();

    const defaultDocumentType = getDefaultDocumentType();

    const [documentType, setDocumentType] = useState(defaultDocumentType);
    const [selectedDocumentId, setSelectedDocumentId] = useState(defaultDocumentId ?? currentDocument.id);

    useEffect(() => {
        if (documentType === 'this') {
            setSelectedDocumentId(currentDocument.id);
        } else if (documentType === 'library') {
            setSelectedDocumentId(currentDocument.documentLibrary);
        } else {
            if (!defaultDocumentId || selectedDocumentId !== defaultDocumentId) {
                setSelectedDocumentId(undefined);
            }
        }
    }, [documentType]);

    useEffect(() => {
        onDocumentSelect(selectedDocumentId);
    }, [selectedDocumentId]);

    return (
        <>
            <Form.Group as={Row} className="mb-3">
                <Form.Label column sm={4} htmlFor="labelFolder">
                    {t('documents:document.navbar.main.editor.left.blocks.types.linked.source')}
                </Form.Label>
                <Col sm={8}>
                    <DocRevSelect
                        name="documentType"
                        selectedValue={documentType}
                        options={options}
                        onChange={(selectedValue) => {
                            if (selectedValue.value !== documentType) {
                                setDocumentType(selectedValue.value);
                            }
                        }}
                    />
                </Col>
            </Form.Group>

            {documentType === 'other' && (
                <OtherDocumentSelect
                    currentDocument={currentDocument}
                    selectedDocumentId={selectedDocumentId}
                    setSelectedDocumentId={setSelectedDocumentId}
                />
            )}
        </>
    );

    function getDocumentOptions() {
        const documentOptions = [
            {
                label: t('document.navbar.main.editor.left.blocks.types.linked.currentDocument'),
                value: 'this',
            },
        ];

        if (currentDocument.documentLibrary) {
            documentOptions.push({
                label: t('document.navbar.main.editor.left.blocks.types.linked.library'),
                value: 'library',
            });
        }

        documentOptions.push({
            label: t('document.navbar.main.editor.left.blocks.types.linked.otherDocument'),
            value: 'other',
        });

        return documentOptions;
    }

    function getDefaultDocumentType() {
        if (!defaultDocumentId || defaultDocumentId === currentDocument.id) {
            return 'this';
        }

        if (defaultDocumentId === currentDocument.documentLibrary) {
            return 'library';
        }

        return 'other';
    }
}

function OtherDocumentSelect({ currentDocument, selectedDocumentId, setSelectedDocumentId }) {
    const { t } = useTranslation('documents');
    const allDocuments = useGetDocuments();
    const options = getDocumentOptions();

    return (
        <Form.Group as={Row} className="mb-3">
            <Form.Label column sm={4} htmlFor="labelFolder">
                {t('documents:document.navbar.main.title')}
            </Form.Label>
            <Col sm={8}>
                <DocRevSelect
                    name="otherDocument"
                    selectedValue={selectedDocumentId}
                    options={options}
                    onChange={(selectedValue) => {
                        if (selectedValue.value !== selectedDocumentId) {
                            setSelectedDocumentId(selectedValue.value);
                        }
                    }}
                />
            </Col>
        </Form.Group>
    );

    function getDocumentOptions() {
        let filteredDocuments = allDocuments.filter(
            (document) => document.id !== currentDocument.id && !document.modelId
        );

        if (currentDocument.documentLibrary) {
            filteredDocuments = filteredDocuments.filter((document) => document.id !== currentDocument.documentLibrary);
        }

        return HelperFunctions.prepareDropdownData(filteredDocuments).sort(HelperFunctions.sortByString('name'));
    }
}

function AreaSelect({ selectedDocument, selectedArea, setSelectedArea, setSelectedBlock }) {
    const { areaId } = useParams();
    const currentAreaId = parseInt(areaId);
    const { t } = useTranslation('documents');
    const document = useGetDocument(selectedDocument, true);

    if (!selectedDocument) {
        return null;
    }

    if (!document) {
        return <LoadingSpinner />;
    }

    const options = formatAreas();

    return (
        <Form.Group as={Row} className="mb-3">
            <Form.Label column sm={4} htmlFor="labelFolder">
                {t('document.navbar.main.editor.left.blocks.types.linked.selectArticle')}
            </Form.Label>
            <Col sm={8}>
                <DocRevSelect
                    name="areaId"
                    selectedValue={selectedArea}
                    options={options}
                    onChange={(selectedValue) => {
                        if (selectedValue.value !== selectedArea) {
                            setSelectedArea(selectedValue.value);
                            setSelectedBlock(undefined);
                        }
                    }}
                />
            </Col>
        </Form.Group>
    );

    function formatAreas() {
        if (!selectedDocument) {
            return [];
        }

        const options = [];

        document.sections
            .filter((_section) => !_section.scheduledForDeletion && !_section.deletedAt)
            .forEach((section) => {
                const sectionOptions = [];

                section.areas
                    .filter((_area) => !_area.scheduledForDeletion && !_area.deletedAt)
                    .forEach((area) => {
                        if (area.id !== currentAreaId) {
                            const headingLevel = _.toInteger(_.get(area, 'exportProperties.headingLevel', 2));
                            let label = '';

                            if (headingLevel > 2) {
                                const depth = headingLevel - 1;

                                for (let i = 0; i < depth - 1; i++) {
                                    label += '-- ';
                                }
                            }

                            sectionOptions.push({
                                value: area.id,
                                label: label + area.title,
                                isDisabled: area.type === 'linked_area',
                            });
                        }
                    });

                if (sectionOptions.length > 0) {
                    const group = {
                        value: section.id,
                        label: section.title,
                        options: sectionOptions,
                    };

                    options.push(group);
                }
            });

        return options;
    }
}

function BlockSelect({ selectedArea, selectedBlock, selectedDocument, setSelectedBlock, activeVariant }) {
    const document = useGetDocument(selectedDocument);
    const baseVariant = useGetBaseVariant(selectedDocument);

    const { formBlocks } = useGetAreaBlocksQuery(
        { areaId: selectedArea, variantId: baseVariant?.id },
        {
            selectFromResult: ({ data }) => ({
                formBlocks: data
                    ? data.areaBlocks.filter((block) => {
                          return block.type === Constants.blockTypes.text;
                      })
                    : undefined,
            }),
            skip: !baseVariant || !document || !selectedArea,
        }
    );

    if (!selectedArea) {
        return null;
    }

    if (!formBlocks) {
        return <LoadingSpinner inline={true} size="sm" />;
    }

    return (
        <div className="linked-block-modal-blocks">
            {formBlocks.map((block) => (
                <div
                    className={cx('linked-block', {
                        'is-selected': selectedBlock === block.id,
                    })}
                    onClick={() => setSelectedBlock(block.id)}
                    key={`linked-block-${block.id}`}
                >
                    <Block
                        document={document}
                        activeVariant={activeVariant}
                        activeVariantId={activeVariant.id}
                        block={block}
                        context={Constants.blockContext.list}
                        key={`list-block-${block.id}`}
                    />
                </div>
            ))}
        </div>
    );
}
