import { Button, Form } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import { generatePath, useHistory, useParams } from 'react-router-dom';
import LoadingSpinner from '../../../global/LoadingSpinner';
import { useDispatch } from 'react-redux';
import _ from 'lodash';
import Select from 'react-select';
import Parser from 'html-react-parser';
import HelperFunctions from '../../../global/HelperFunctions';
import Constants from '../../../../config/Constants';
import RestrictedDocumentContent from '../../RestrictedDocumentContent';
import { EDIT_PATH } from 'scenes/DocumentsV2';
import { useGetDocument } from '../../hooks/useGetDocument';
import { useGetBaseVariant } from '../../hooks/useGetBaseVariant';
import {
    documentApi,
    useGetBlockQuery,
    useGetDocumentsBlocksSearchQuery,
    useUpdateBlocksMutation,
} from 'features/documents/documents';
import { useGetDocumentTags } from 'pages/tags/hooks/useGetDocumentTags';
import { useTranslation } from 'react-i18next';
import { StringParam, useQueryParam } from 'use-query-params';
import { PrimaryButton, SecondaryButton, WarningButton } from 'components/Buttons';
import Spinner from 'pages/global/Spinner';

export function FindReplace() {
    const { documentId } = useParams();
    const document = useGetDocument();
    const [searchQuery = ''] = useQueryParam('q', StringParam);

    if (document === undefined) {
        return null;
    }

    return (
        <div className="content-static-body has-subnav">
            <div className="d-flex align-items-stretch h-100">
                <div className="overflow-auto w-100">
                    <div className="mt-5 ml-5 mr-5 mb-4 dr-container p-4">
                        <RestrictedDocumentContent
                            documentId={documentId}
                            roles={Object.values(Constants.userDocumentRole)}
                        >
                            {searchQuery.length > 0 ? (
                                <FindReplaceAction documentId={document.id} searchQuery={searchQuery} />
                            ) : (
                                <div className="p-2">
                                    <FindReplaceForm document={document} />
                                </div>
                            )}
                        </RestrictedDocumentContent>
                    </div>
                </div>
            </div>
        </div>
    );
}

function FindReplaceForm({ document }) {
    const [q, setQ] = useQueryParam('q', StringParam);
    const [searchQuery, setSearchQuery] = useState('');
    const { t } = useTranslation('documents');

    return (
        <Form
            onSubmit={(event) => {
                event.preventDefault();
                setQ(_.trim(searchQuery));
            }}
        >
            <p>
                {t('document.navbar.search.titleOne')}
                &nbsp;<strong>{document.name}</strong>&nbsp;
                {t('document.navbar.search.titleTwo')}
            </p>

            <Form.Group>
                <Form.Control
                    type="search"
                    id="searchQuery"
                    name="searchQuery"
                    placeholder={`${t('document.navbar.search.searchTerm')}...`}
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    autoFocus={true}
                />
            </Form.Group>

            <PrimaryButton type="submit" disabled={searchQuery.length < 3}>
                {t('document.navbar.search.btn.search')}
            </PrimaryButton>
        </Form>
    );
}

function FindReplaceAction({ documentId, searchQuery }) {
    const history = useHistory();
    const { t } = useTranslation('documents');

    const { blockSearchMatches, isFetching, isUninitialized } = useGetDocumentsBlocksSearchQuery(
        { id: documentId, q: searchQuery },
        {
            refetchOnMountOrArgChange: true,
            selectFromResult: ({ data, isFetching, isUninitialized }) => ({
                blockSearchMatches: data,
                isFetching,
                isUninitialized,
            }),
        },
    );

    if (!blockSearchMatches || isFetching || isUninitialized) {
        return <LoadingSpinner size="small" inline={true} />;
    }

    if (blockSearchMatches.length === 0) {
        return (
            <>
                <p>{t('document.navbar.search.results.noResults')}</p>
                <PrimaryButton
                    onClick={() => {
                        history.push(
                            generatePath(EDIT_PATH, {
                                documentId,
                                view: 'find_replace',
                            }),
                        );
                    }}
                >
                    {t('document.navbar.search.results.btn.searchAgain')}
                </PrimaryButton>
            </>
        );
    }

    return <SearchMatches blockSearchMatches={blockSearchMatches} documentId={documentId} searchQuery={searchQuery} />;
}

function SearchMatches({ blockSearchMatches, documentId, searchQuery }) {
    const history = useHistory();
    const [selectedMatchIndex, setSelectedMatchIndex] = useState(0);
    const { t } = useTranslation('documents');
    const baseVariant = useGetBaseVariant();

    if (selectedMatchIndex >= blockSearchMatches.length) {
        return (
            <div>
                <p>{t('document.navbar.search.results.resultsCovered')}</p>

                <PrimaryButton
                    onClick={() => {
                        history.push(
                            generatePath(EDIT_PATH, {
                                documentId,
                                view: 'find_replace',
                            }),
                        );
                    }}
                >
                    {t('document.navbar.search.results.btn.backToStart')}
                </PrimaryButton>
            </div>
        );
    }

    return (
        <div>
            <div className="text-muted small mb-4">
                {t('document.navbar.search.results.resultOne')} {selectedMatchIndex + 1}/{blockSearchMatches.length}{' '}
                {t('document.navbar.search.results.resultTwo')} "{searchQuery}"
            </div>

            {baseVariant && (
                <SelectedBlockMatch
                    baseVariantId={baseVariant.id}
                    blockSearchMatch={blockSearchMatches[selectedMatchIndex]}
                    documentId={documentId}
                    skipOccurrence={skipOccurrence}
                />
            )}
        </div>
    );

    function skipOccurrence() {
        setSelectedMatchIndex((s) => s + 1);
    }
}

function SelectedBlockMatch({ baseVariantId, blockSearchMatch, documentId, skipOccurrence }) {
    const dispatch = useDispatch();
    const [updateBlocks, updateBlocksResult] = useUpdateBlocksMutation();

    const [positionOffset, setPositionOffset] = useState(0);
    const [useTags, setUseTags] = useState(true);
    const [replacementValue, setReplacementValue] = useState('');

    const { blockId, end, start } = blockSearchMatch;
    const startOffset = start + positionOffset;
    const endOffset = end + positionOffset;

    const { currentBlock, isLoading } = useGetCurrentBlock(blockId, baseVariantId);

    useEffect(() => {
        // Reset position offset when switching to a new block
        setPositionOffset(0);
    }, [blockId]);

    if (!currentBlock || currentBlock.block.id !== blockId || isLoading) {
        return <LoadingSpinner size="small" inline={true} />;
    }

    const { areaTitle, block, areaId } = currentBlock;
    const { latestContent } = block;

    const blockMatchText = latestContent.substring(startOffset, endOffset);
    const parsedContent =
        latestContent.substring(0, startOffset) +
        `<mark class="highlight-search">${blockMatchText}</mark>` +
        latestContent.substring(endOffset);

    return (
        <div className="edit-area-block mb-0">
            <div className="font-weight-bold mb-2" style={{ fontSize: '1rem' }}>
                {areaTitle}
            </div>
            <div className="edit-area-block-content p-3 mb-4">
                <div className="tiny-mce-content-body small">{Parser(parsedContent)}</div>
            </div>

            <ReplacementForm
                documentId={documentId}
                skipOccurrence={skipOccurrence}
                replaceOccurrence={replaceOccurrence}
                useTags={useTags}
                setUseTags={setUseTags}
                replacementValue={replacementValue}
                setReplacementValue={setReplacementValue}
                isSubmitting={updateBlocksResult.isLoading}
            />
        </div>
    );

    function replaceOccurrence(replacementValue) {
        const newContent =
            latestContent.substring(0, startOffset) + replacementValue + latestContent.substring(endOffset);
        const formData = {
            id: block.id,
            type: block.type,
            latestContent: newContent,
        };

        const lengthDiff = replacementValue.length - blockMatchText.length + positionOffset;

        updateBlocks({ areaId, variantId: baseVariantId, body: [formData] }).then(() => {
            // Reload data
            dispatch(documentApi.util.invalidateTags([{ type: 'Block', id: block.id }]));

            setPositionOffset(lengthDiff);

            // Move to next occurrence
            skipOccurrence();
        });
    }
}

function ReplacementForm({
    documentId,
    skipOccurrence,
    replaceOccurrence,
    useTags,
    setUseTags,
    replacementValue,
    setReplacementValue,
    isSubmitting = true,
}) {
    const [selectedTag, setSelectedTag] = useState();
    const history = useHistory();
    const documentTags = useGetDocumentTags();
    const { t } = useTranslation('documents');

    const tagOptions = prepareTagOptions();

    useEffect(() => {
        // Autofill the previous selected tag
        if (useTags && replacementValue !== '') {
            const tagValue = replacementValue.substring(1, replacementValue.length - 1);
            const tag = tagOptions.find((_tag) => _tag.value === tagValue);

            if (tag) {
                setSelectedTag(tag);
            }
        }
    }, [replacementValue, useTags]);

    return (
        <div className="pt-3">
            <Form.Switch
                id="useTags"
                name="useTags"
                label={t('document.navbar.search.results.useTag')}
                checked={useTags}
                onChange={() => setUseTags(!useTags)}
            />

            <Form.Label htmlFor="replacementValue" className="mb-2 mt-2">
                {t('document.navbar.search.results.replaceWith')} <strong>{useTags ? 'tag' : 'tekst'}</strong>:
            </Form.Label>

            {useTags ? (
                <Select
                    id="replacementValue"
                    name="replacementValue"
                    options={tagOptions}
                    value={selectedTag}
                    onChange={(selection) => {
                        setSelectedTag(selection);
                        setReplacementValue(`[${selection.value}]`);
                    }}
                    placeholder={`${t('document.navbar.search.results.titleSelect')}...`}
                />
            ) : (
                <Form.Control
                    id="replacementValue"
                    name="replacementValue"
                    placeholder=""
                    value={replacementValue}
                    onChange={(e) => setReplacementValue(e.target.value)}
                    autoFocus={true}
                />
            )}

            <div className="d-flex align-items-center pt-4">
                <WarningButton
                    disabled={replacementValue === '' || isSubmitting}
                    className="mr-2"
                    onClick={() => replaceOccurrence(replacementValue)}
                >
                    {t('document.navbar.search.results.btn.replace')}
                </WarningButton>
                <SecondaryButton disabled={isSubmitting} onClick={skipOccurrence} className="mr-2">
                    {t('document.navbar.search.results.btn.skip')}
                </SecondaryButton>
                <Button
                    variant="link"
                    disabled={isSubmitting}
                    onClick={() => {
                        history.push(
                            generatePath(EDIT_PATH, {
                                documentId,
                                view: 'find_replace',
                            }),
                        );
                    }}
                >
                    {t('document.navbar.search.results.btn.stop')}
                </Button>

                {isSubmitting && <Spinner />}
            </div>
        </div>
    );

    function prepareTagOptions() {
        if (_.isNil(documentTags)) {
            return [];
        }

        const sortedTags = _.cloneDeep(documentTags).sort(HelperFunctions.dynamicSort('name'));

        return sortedTags.map((tag) => {
            return {
                value: tag.name,
                label: tag.name,
                isDisabled: false,
            };
        });
    }
}

function useGetCurrentBlock(blockId, variantId) {
    return useGetBlockQuery(
        { blockId, variantId },
        {
            selectFromResult: ({ currentData, isUninitialized, isFetching }) => ({
                currentBlock: currentData,
                isLoading: isUninitialized || isFetching,
            }),
        },
    );
}
