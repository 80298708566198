import { useGetGuardAuditsQuery } from 'features/publications/guardApi';
import HelperFunctions from '../../../../global/HelperFunctions';
import { NumberParam, useQueryParam } from 'use-query-params';
import { useGetGuardApiToken } from 'pages/publications_v2/views/guard/hooks/useGetGuardApiToken';

export function useGetGuardAudits(uri, type, queryParams = {}, parentUri) {
    const token = useGetGuardApiToken();
    const [page = 1] = useQueryParam('page', NumberParam);

    const params = {
        page,
        ...queryParams,
    };

    return useGetGuardAuditsQuery(
        { uri, type, token, params, parentUri },
        {
            selectFromResult: (result) => HelperFunctions.queryResult(result, false),
        },
    );
}
