import { useGetDocumentVariantsQuery } from 'features/documents/documents';
import { useParams } from 'react-router-dom';
import { filterDeep } from 'deepdash-es/standalone';
import { useMemo } from 'react';
import { createSelector } from '@reduxjs/toolkit';

export function useGetBaseVariant(id = undefined, enabledOnly = false) {
    const { documentId } = useParams();
    const idAsNumber = id ?? parseInt(documentId);

    const findBaseVariant = useMemo(() => {
        // Return a unique selector instance for this page so that
        // the filtered results are correctly memoized
        return createSelector(
            (res) => res.data,
            (res, enabledOnly) => enabledOnly,
            (data, enabledOnly) => {
                const baseVariant = data ? data.find((variant) => variant.parentId === null) : undefined;

                if (baseVariant && enabledOnly) {
                    return filterDeep(baseVariant, (value) => value.enabled, {
                        childrenPath: 'children',
                    });
                }

                return baseVariant;
            },
        );
    }, []);

    const { baseVariant } = useGetDocumentVariantsQuery(idAsNumber, {
        selectFromResult: (result) => ({
            // baseVariant: data ? data.find((variant) => variant.parentId === null) : undefined,
            baseVariant: findBaseVariant(result, enabledOnly),
        }),
        skip: !idAsNumber,
    });

    return baseVariant;
}
