import React, { Component, Fragment } from 'react';
import Parser from 'html-react-parser';
import Constants from '../../../../config/Constants';
import HelperFunctions from '../../../global/HelperFunctions';
import ReimbursementApi from '../../../../api/ReimbursementApi';
import LoadingSpinner from '../../../global/LoadingSpinner';
import InteractivePart from './InteractivePart';
import cx from 'classnames';
import TranslationHelper from '../../../global/TranslationHelper';
import _ from 'lodash';
import { useFetchBlockData } from './ReimbursementHooks';
import { EditPackageReimbursementModal } from '../_EditArea/EditPackageReimbursementModal';
import { useTranslation } from 'react-i18next';
import { useGetReimbursements } from 'pages/reimbursements/hooks/useGetReimbursements';
import { useGetDocument } from 'pages/documents_v2/hooks/useGetDocument';

export default function Reimbursement(props) {
    const document = useGetDocument();

    if (!document) {
        return null;
    }

    return <ReimbursementAsComponent {...props} document={document} />;
}

class ReimbursementAsComponent extends Component {
    constructor(props) {
        super(props);

        this.requiredFormFields = ['reimbursementId'];
        this.state = {
            isLoading: true,
            showPackageReimbursementModal: false,
            reimbursementProducts: [],
            latestRenderedReimbursementProducts: null,
            diffRenderedReimbursementProducts: null,
        };
    }

    componentDidMount() {
        this.loadData();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const previousRenderedDiffContent = prevProps.block.renderedDiffContent ?? '';
        const currentRenderedDiffContent = this.props.block.renderedDiffContent ?? '';

        if (prevProps.activeVariantId !== this.props.activeVariantId) {
            this.setState({
                isLoading: true,
            });

            this.getBlockData().then(([latestRenderedReimbursementProducts, diffRenderedReimbursementProducts]) => {
                this.setState({
                    isLoading: false,
                    latestRenderedReimbursementProducts,
                    diffRenderedReimbursementProducts,
                });
            });
        }

        if (previousRenderedDiffContent !== currentRenderedDiffContent) {
            this.setState({
                isLoading: true,
            });

            this.loadData();
        }
    }

    getBlockData = () => {
        return Promise.resolve([]);

        // Variant is basevariant, do not load data. (Because reimbursement is not shown for baseVariant anyway)
        if (this.props.activeVariant.id === this.props.baseVariantId || this.props.activeVariant.parentId === null) {
            return Promise.resolve([]);
        }

        return Promise.resolve()
            .then(() => {
                // Always show latest content
                const promises = [
                    ReimbursementApi.getReimbursementBlockData(
                        this.state.reimbursement.id,
                        this.props.activeVariant.id,
                        'nl',
                        false,
                        true,
                        null,
                    ),
                ];

                // If document currently has a revision, also render the diffContent for the reimbursement.
                const activeRevisionStart = _.get(this.props.document, 'activeRevision.dateStart');
                if (activeRevisionStart) {
                    const changesFromTimestamp = activeRevisionStart;

                    promises.push(
                        ReimbursementApi.getReimbursementBlockData(
                            this.state.reimbursement.id,
                            this.props.activeVariant.id,
                            'nl',
                            true,
                            true,
                            changesFromTimestamp,
                        ),
                    );
                }

                return Promise.all(promises);
            })
            .then((results) =>
                _.map(results, (result) => {
                    if (
                        result.hasOwnProperty('renderedReimbursementProducts') &&
                        result.renderedReimbursementProducts !== null
                    ) {
                        return result.renderedReimbursementProducts;
                    }

                    return null;
                }),
            );
    };

    handleFormSubmit = (code) => {
        const latestContent = JSON.stringify({
            data: [],
            code,
        });

        // Notify the parent components that the content has changed
        this.props.handleBlockChange(latestContent, false);
        this.loadData();

        // Save state and close modal
        this.setState({
            showPackageReimbursementModal: false,
        });
    };

    loadData = () => {
        this.getBlockData().then(([latestRenderedReimbursementProducts, diffRenderedReimbursementProducts]) => {
            this.setState({
                isLoading: false,
                latestRenderedReimbursementProducts,
                diffRenderedReimbursementProducts,
            });
        });
    };

    togglePackageReimbursement = () => {
        this.setState({
            showPackageReimbursementModal: !this.state.showPackageReimbursementModal,
        });
    };

    render() {
        if (this.state.isLoading) {
            return (
                <div className="uk-padding-small uk-position-relative uk-background-muted uk-text-muted uk-text-center">
                    <LoadingSpinner inline={true} size="sm" />
                </div>
            );
        }

        const { activeVariant, block, blockDisplayOptions, document } = this.props;
        const displayOptions = blockDisplayOptions.table;
        const blockData = HelperFunctions.tryParseJSON(block.latestContent);

        if (blockData === false || !activeVariant) {
            return null;
        }

        if (activeVariant.parentId !== null) {
            return (
                <RenderedReimbursementProductsAsFunction
                    blockId={block.id}
                    blockData={blockData}
                    document={document}
                    markChanges={blockDisplayOptions.text.markChanges}
                    activeVariant={activeVariant}
                />
            );
        }

        return (
            <>
                {displayOptions.editable ? (
                    <EditableContent {...this.props} togglePackageReimbursement={this.togglePackageReimbursement} />
                ) : (
                    <RenderedContent {...this.props} />
                )}

                {this.state.showPackageReimbursementModal && (
                    <EditPackageReimbursementModal
                        showModal={true}
                        handleClose={this.togglePackageReimbursement}
                        categoryId={document.reimbursementCategoryId}
                        formData={{
                            id: block.id,
                            code: blockData?.code ?? '',
                        }}
                        handleFormSubmit={this.handleFormSubmit}
                    />
                )}
            </>
        );
    }
}

function EditableContent(props) {
    return (
        <div onClick={props.togglePackageReimbursement} className="uk-background-hover-parent uk-cursor-pointer">
            <RenderedContent {...props} />
        </div>
    );
}

function RenderedContent(props) {
    const { block, blockDisplayOptions, context, editorDisplaySection, document, readOnly = false } = props;
    const latestContentData = HelperFunctions.tryParseJSON(block.latestContent);
    const renderedDiffContentData = HelperFunctions.tryParseJSON(block.renderedDiffContent);
    const { markChanges } = blockDisplayOptions.text;
    const { t } = useTranslation('reimbursements');

    const createDiffContent = (data, markChanges) => {
        if (data === false) {
            // Parsing failed
            return 'Geen code gevonden';
        }

        let content = '';

        if (_.has(data, 'oldCode')) {
            content += markChanges ? `<del>${data.oldCode}</del>` : data.oldCode;
        }

        if (_.has(data, 'code')) {
            let innerContent = data.code;

            content += _.has(data, 'oldCode') && markChanges ? `<ins>${innerContent}</ins>` : innerContent;
        }

        return content;
    };

    const containerClass = cx(
        'p-3 uk-background-muted uk-background-primary-extra-light-child uk-text-muted uk-text-center',
        {
            'reimbursement-read-ony': readOnly,
        },
    );

    return (
        <div className={containerClass}>
            <div className="font-weight-bold">{t('reimbursement.product.packageReimbursement')}</div>

            {context === Constants.blockContext.editor && editorDisplaySection === 'left' && (
                <>
                    <div>{Parser(createDiffContent(latestContentData, false))}</div>

                    <div>
                        <RenderedReimbursementCode
                            code={latestContentData.code}
                            categoryId={document.reimbursementCategoryId}
                        />
                    </div>
                </>
            )}

            {context === Constants.blockContext.editor && editorDisplaySection === 'right' && (
                <>
                    <div>
                        {Parser(
                            createDiffContent(markChanges ? renderedDiffContentData : latestContentData, markChanges),
                        )}
                    </div>

                    <div>
                        {markChanges && (
                            <>
                                {_.has(renderedDiffContentData, 'oldCode') ? (
                                    <>
                                        <del>
                                            <RenderedReimbursementCode
                                                code={renderedDiffContentData.oldCode}
                                                categoryId={document.reimbursementCategoryId}
                                            />
                                        </del>
                                        <ins>
                                            <RenderedReimbursementCode
                                                code={renderedDiffContentData.code}
                                                categoryId={document.reimbursementCategoryId}
                                            />
                                        </ins>
                                    </>
                                ) : (
                                    <>
                                        <RenderedReimbursementCode
                                            code={renderedDiffContentData.code}
                                            categoryId={document.reimbursementCategoryId}
                                        />
                                    </>
                                )}
                            </>
                        )}

                        {markChanges === false && (
                            <>
                                <RenderedReimbursementCode
                                    code={latestContentData.code}
                                    categoryId={document.reimbursementCategoryId}
                                />
                            </>
                        )}
                    </div>
                </>
            )}

            {context === Constants.blockContext.checkChanges && editorDisplaySection === 'left' && (
                <>
                    {_.has(block, 'diffContent') && _.isEmpty(block.diffContent) === false ? (
                        <>
                            <div>
                                {block.diffContent !== null && block.diffContent !== '' ? (
                                    Parser(block.diffContent, {
                                        replace: (domNode) => {
                                            if (
                                                domNode.name === 'change' &&
                                                domNode.hasOwnProperty('attribs') &&
                                                domNode.attribs.hasOwnProperty('data-diff-id')
                                            ) {
                                                let part = HelperFunctions.getByValue(
                                                    block.changes,
                                                    'diffId',
                                                    parseInt(domNode.attribs['data-diff-id']),
                                                );

                                                console.log(part);

                                                if (part !== undefined) {
                                                    part = {
                                                        ...part,
                                                        newText: part.newText,
                                                        oldText: part.oldText,
                                                    };

                                                    return (
                                                        <InteractivePart
                                                            isSelected={
                                                                props.selectedPart.block === block.id &&
                                                                props.selectedPart.part === part.id
                                                            }
                                                            part={part}
                                                            selectPart={() => props.selectPart(part.id, block.id)}
                                                        />
                                                    );
                                                } else {
                                                    return <Fragment />;
                                                }
                                            } else if (domNode.name === 'change') {
                                                return <Fragment />;
                                            }
                                        },
                                    })
                                ) : block.latestContent !== '' ? (
                                    Parser(createDiffContent(block.latestContent, false))
                                ) : (
                                    <div>{t('reimbursement.product.noProductLinked')}</div>
                                )}
                            </div>
                        </>
                    ) : (
                        <>
                            <div>{Parser(createDiffContent(latestContentData, false))}</div>
                        </>
                    )}
                </>
            )}

            {context === Constants.blockContext.checkChanges && editorDisplaySection === 'right' && (
                <div>{Parser(createDiffContent(latestContentData, false))}</div>
            )}
        </div>
    );
}

function RenderedReimbursementCode({ code, categoryId }) {
    const { reimbursements } = useGetReimbursements(`/api/reimbursement-api/api/categories/${categoryId}`);
    const reimbursement = findReimbursementByCode();

    if (reimbursement === undefined) {
        return null;
    }

    return <>{reimbursement.name}</>;

    function findReimbursementByCode() {
        if (_.isEmpty(code)) {
            return undefined;
        }

        return reimbursements.find((reimbursement) => reimbursement.code === code);
    }
}

function RenderedReimbursementProductsAsFunction({ blockId, blockData, document, markChanges, activeVariant }) {
    let activeRevisionStart = undefined;
    if (markChanges) {
        activeRevisionStart = _.get(document, 'activeRevision.dateStart');
    }

    const { loading, data } = useFetchBlockData(
        blockId,
        blockData.code,
        activeVariant.id,
        document.reimbursementCategoryId,
        activeRevisionStart,
    );

    if (loading) {
        return (
            <div className="pt-3 pl-2">
                <LoadingSpinner inline size="sm" />
            </div>
        );
    }

    return (
        <div className="uk-padding-small uk-position-relative uk-background-muted uk-background-primary-extra-light-child uk-text-muted uk-text-center">
            <RenderedReimbursementProducts reimbursementProducts={data} document={document} />
        </div>
    );
}

export function RenderedReimbursementProducts({ reimbursementProducts = null, document = null }) {
    const { t } = useTranslation('reimbursements');

    if (_.isEmpty(reimbursementProducts)) {
        return <div>{t('reimbursement.product.noProductLinked')}</div>;
    }

    const {
        name = '',
        secondaryName = '',
        products = [],
        secondaryProducts = [],
        showAsSeparate = false,
    } = reimbursementProducts;

    return (
        <div className="uk-text-left">
            <ProductsTable name={name} products={products} document={document} />

            {showAsSeparate && (
                <div className="pt-3">
                    <ProductsTable name={secondaryName ?? name} products={secondaryProducts} document={document} />
                </div>
            )}
        </div>
    );
}

export function ProductsTable({ name = '', products = [], document = null }) {
    const { t } = useTranslation('reimbursements');

    return (
        <>
            {name !== '' && <div className="font-weight-bold mb-2">{Parser(name)}</div>}

            <table className="uk-table uk-table-small uk-table-striped mt-0">
                <thead>
                    <tr>
                        <th />
                        <th>{t('proposition.name')}</th>
                        <th>{t('proposition.editProposition.properties.description')}</th>
                    </tr>
                </thead>
                <tbody>
                    {products.map((_product) => {
                        const {
                            code = '',
                            name = '',
                            identical = false,
                            identicalName = '',
                            identicalCode = '',
                        } = _product;

                        let description = TranslationHelper.translateTags(_product.description);
                        if (document) {
                            description = HelperFunctions.prepareRefs(description, document);
                        }

                        return (
                            <tr
                                key={`tableReimbursementProduct${_product.reimbursementProductId}-${_product.productId}-${_product.variantPackageProductId}`}
                            >
                                <td>
                                    <span className="font-weight-bold pr-2">{code}</span>
                                </td>
                                <td width="50%">
                                    {name}

                                    {identical && (
                                        <div
                                            style={{
                                                fontSize: 9,
                                            }}
                                        >
                                            {t('reimbursement.product.misc.identical', {
                                                identicalCode: identicalCode,
                                                identicalName: identicalName,
                                            })}
                                        </div>
                                    )}
                                </td>
                                <td width="50%">{Parser(description)}</td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </>
    );
}
