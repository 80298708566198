import { generatePath, useHistory, useParams } from 'react-router-dom';
import { useLockAreaMutation } from 'features/documents/documents';
import React, { useEffect, useState } from 'react';
import HelperFunctions from '../../../global/HelperFunctions';
import { VIEW_PATH } from 'scenes/DocumentsV2';
import { useTranslation } from 'react-i18next';

export function LockedArea({ action, children }) {
    const { documentId, areaId } = useParams();
    const [lockArea] = useLockAreaMutation();
    const history = useHistory();
    const { t } = useTranslation('documents');

    const [area, setArea] = useState(undefined);

    useEffect(() => {
        lockArea({
            uri: `/areas/${areaId}/lock`,
            body: {
                action,
            },
        })
            .unwrap()
            .then((data) => {
                setArea(data);
            })
            .catch((exception) => {
                if (exception.status === 400) {
                    HelperFunctions.alertModal(t('document.navbar.main.titles.editedByAnotherUser')).then(() => {
                        history.push(
                            generatePath(VIEW_PATH, {
                                documentId,
                            })
                        );
                    });
                }
            });
    }, [areaId]);

    if (!area) {
        return null;
    }

    return <>{children}</>;
}
