import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import getEnv from '../../config/Env';
import { baseQueryWithReauth, prepareHeaders, providesList } from '../api';

const baseUrl = getEnv('OPERATIONS_API_URL');

const operationListApiQuery = fetchBaseQuery({
    baseUrl,
    prepareHeaders,
});

export const operationListApi = createApi({
    reducerPath: 'operationListApi',
    baseQuery: (args, api, extraOptions) => baseQueryWithReauth(args, api, extraOptions, operationListApiQuery),
    tagTypes: ['OpList'],
    endpoints: (builder) => ({
        getOpLists: builder.query({
            query: () => '/op_lists',
            transformResponse: (response) => response['hydra:member'],
            providesTags: (result) => providesList(result, 'OpList', '@id'),
        }),
    }),
});

export const { useGetOpListsQuery } = operationListApi;
