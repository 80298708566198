import { Nav } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router-dom';
import { BASE_PATH } from 'scenes/DocumentsV2';
import Constants from '../../../../config/Constants';
import { useContext } from 'react';
import { useGetDocument } from '../../hooks/useGetDocument';
import { useGetBaseVariant } from '../../hooks/useGetBaseVariant';
import { AreaContext } from '../Area';
import HelperFunctions from '../../../global/HelperFunctions';
import { actions } from '../../config/views';
import { Notification } from 'components/Notification';
import { useTranslation } from 'react-i18next';
import { useEntityTemplateParameters } from '../../hooks/useEntityTemplateParameters';
import RestrictedContent from 'pages/global/RestrictedContent';
import { useGetOneLiners } from 'pages/documents_v2/views/edit/one_liners/hooks/useGetOneLiners';

export function AreaDisplayMode({ area, dirty = false }) {
    const { areaId, documentId, view } = useParams();
    const history = useHistory();
    const document = useGetDocument();
    const baseVariant = useGetBaseVariant();
    const { setActiveVariantId } = useContext(AreaContext);
    const { t } = useTranslation('documents');
    const { t: tGlobal } = useTranslation('global');
    const restrictions = useEntityTemplateParameters(area, 'area', document);

    if (!document) {
        return null;
    }

    const { currentUserRole, status } = document;

    const unprocessedEdits = _.get(area, 'unprocessedEdits', 0);

    const canEdit =
        area.scheduledForDeletion === false &&
        currentUserRole !== Constants.userDocumentRole.spectator &&
        (status === Constants.documentStatus.reviewInProgress || status === Constants.documentStatus.draft) &&
        (area.properties.isTemplate === false ? restrictions?.canEditArticle : restrictions?.canEditArticleTemplate);

    const canCheck =
        area.scheduledForDeletion === false &&
        status === Constants.documentStatus.reviewInProgress &&
        (currentUserRole === Constants.userDocumentRole.documentManager ||
            currentUserRole === Constants.userDocumentRole.finalEditor);

    const handleSelect = (eventKey) => {
        if (view === eventKey) {
            return;
        }

        if (dirty) {
            HelperFunctions.confirmModal(
                tGlobal('confirm.confirmCancelChanges'),
                'danger',
                false,
                tGlobal('confirm.yesContinue'),
                tGlobal('btn.cancel'),
            )
                .then(() => {
                    processSelect(eventKey);
                })
                .catch(() => {});
        } else {
            processSelect(eventKey);
        }
    };

    const processSelect = (eventKey) => {
        // Always switch back to the base Variant
        setActiveVariantId(baseVariant.id);

        history.push(`${BASE_PATH}/review/id/${documentId}/${eventKey}/areaId/${areaId}`);
    };

    return (
        <div className="d-flex subnav border-bottom mb-3 pt-2" style={{ marginLeft: '2.5rem', marginRight: '2.5rem' }}>
            <Nav activeKey={view} onSelect={handleSelect}>
                <Nav.Item>
                    <Nav.Link eventKey={actions.EDIT} disabled={!canEdit} className="d-flex align-items-center">
                        {t('document.navbar.main.btn.display.edit')}
                    </Nav.Link>
                </Nav.Item>

                <Nav.Item>
                    <Nav.Link eventKey={actions.CHECK} disabled={!canCheck} className="d-flex align-items-center">
                        {t('document.navbar.main.btn.display.control')}
                        {canCheck && unprocessedEdits > 0 && (
                            <div className="ml-1">
                                <Notification count={unprocessedEdits} />
                            </div>
                        )}
                    </Nav.Link>
                </Nav.Item>

                <Nav.Item>
                    <Nav.Link eventKey={actions.VIEW} className="d-flex align-items-center">
                        {t('document.navbar.main.btn.display.read')}
                    </Nav.Link>
                </Nav.Item>

                <RestrictedContent module={Constants.modules['one-liner']}>
                    <OneLinerButton document={document} />
                </RestrictedContent>
            </Nav>
        </div>
    );
}

function OneLinerButton({ document }) {
    const { areaId } = useParams();
    const skip = !areaId || !document || document.properties?.enableOneLiners !== true;
    const oneLiners = useGetOneLiners(parseInt(areaId), skip);

    if (skip) {
        return null;
    }

    return (
        <Nav.Item>
            <Nav.Link eventKey={actions.EDIT_ONE_LINERS} className="d-flex align-items-center">
                One-liners
                {oneLiners.length > 0 && (
                    <div className="ml-1">
                        <div
                            style={{
                                minWidth: '1.6em',
                                height: '1.6em',
                                borderRadius: '0.8em',
                                fontSize: '0.8em',
                                color: 'white',
                            }}
                            className="d-flex justify-content-center align-items-center bg-insert"
                        >
                            {oneLiners.length}
                        </div>
                    </div>
                )}
            </Nav.Link>
        </Nav.Item>
    );
}
