import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';

const AreaAdapter = createEntityAdapter();
const DocumentVariantAdapter = createEntityAdapter();

const documentsSlice = createSlice({
    name: 'documents',
    initialState: {
        areas: AreaAdapter.getInitialState(),
        documents: {},
        documentVariants: DocumentVariantAdapter.getInitialState(),
        documentVariantsByDocument: DocumentVariantAdapter.getInitialState(),
        loadedOrganisation: 0,
        tableTemplates: false,
        blockSearchMatches: null,
        editArea: {
            editVariantsBlockId: null,
        },
        sidebarFilters: {
            showHeading: true,
            showEdits: true,
            showDeletedArticles: true,
            showDeletedChapters: true,
            showComments: true,
            showLabels: true,
            showNumbering: true,
            showArticlePrefix: true,
            showPageForcing: true,
            showTitleInDoc: true,
            showSkippedArticles: true,
        },
        expandedEntities: {},
    },
    reducers: {
        setFilterDoc: {
            reducer(state, action) {
                const { key, value } = action.payload;
                state.sidebarFilters[key] = value;
            },
        },
    },
    extraReducers: {
        ['security/resetState']: (state) => {
            Object.assign(state, documentsSlice.getInitialState());
        },
    },
});

export const { setFilterDoc } = documentsSlice.actions;

export default documentsSlice.reducer;
