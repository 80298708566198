import { Modal } from 'react-bootstrap';
import { FormModal, ModalFooter } from '../../global/FormModal';
import { Form as FForm, Formik, useFormikContext } from 'formik';
import { FieldSelect } from '../../publications_v2/helpers/FieldHelper';
import Constants, { Permissions } from '../../../config/Constants';
import { DocumentVariantOption, MultiVariantSelect } from '../../../components/Select';
import { Area, Document, DocumentVariant } from '../../../models/documents.models';
import * as Yup from 'yup';
import { useEffect, useMemo, useState } from 'react';
import { useHasModuleEnabled, useHasPermission } from '../../global/RestrictedContent';
import HelperFunctions from '../../global/HelperFunctions';
import { filterDeep } from 'deepdash-es/standalone';
import { useGetDocument } from '../hooks/useGetDocument';
import { useGetBaseVariant } from '../hooks/useGetBaseVariant';
import { useTranslation } from 'react-i18next';
import { ImportCSVFileAsBlockLayoutContent } from './ImportCSVFileAsBlockLayoutContent';
import { useGetTableTemplates } from '../../../hooks/useGetTableTemplates';

interface SelectOption {
    label: string;
    value: string;
}

interface Values {
    blockType: string;
    variantOption: 'all' | 'none' | 'select';
    variants: number[];
    templateId?: number;
}

export function AddBlockModal({
    hide,
    onSubmit,
    defaultBlockType,
    defaultVariants,
    defaultVariantOption,
    area,
    canChangeBlockType = true,
    areaBlocks = [],
}: {
    hide: () => void;
    onSubmit: (values: Values) => void;
    defaultBlockType: string;
    defaultVariants: number[];
    defaultVariantOption: string;
    area: Area;
    canChangeBlockType?: boolean;
    areaBlocks?: any[];
}) {
    const isLabelModuleEnabled: boolean = useHasModuleEnabled(Constants.modules.labels);
    const isReimbursementsModuleEnabled: boolean = useHasModuleEnabled(Constants.modules.reimbursements);
    const hasReimbursementPermission: boolean = useHasPermission(Permissions.Reimbursements['Write.All']);
    const document: Document = useGetDocument();
    const baseVariant: DocumentVariant = useGetBaseVariant();
    const { t } = useTranslation('documents');

    const variants: DocumentVariant[] = useMemo(() => {
        return HelperFunctions.flatten([baseVariant]);
    }, [baseVariant]);

    const handleClose = () => {
        hide();
    };

    const handleSubmit = (values: Values) => {
        // Make sure to always add the baseVariant id
        if (values.variantOption === 'select' || values.variantOption === 'none') {
            values.variants.push(baseVariant.id);
        }

        onSubmit(values);
    };

    const blockTypes: SelectOption[] = [
        {
            label: t('document.navbar.main.editor.left.subnav.addBlock.blocks.text'),
            value: Constants.blockTypes.text,
        },
        {
            label: t('document.navbar.main.editor.left.subnav.addBlock.blocks.textWithExplanation'),
            value: Constants.blockTypes.textExplanation,
        },
        {
            label: t('document.navbar.main.editor.left.subnav.addBlock.blocks.introtext'),
            value: 'intro',
        },
        {
            label: t('document.navbar.main.editor.left.subnav.addBlock.blocks.attention'),
            value: 'attention',
        },
        {
            label: t('document.navbar.main.editor.left.subnav.addBlock.blocks.special'),
            value: 'special',
        },
        {
            label: t('document.navbar.main.editor.left.subnav.addBlock.blocks.info'),
            value: 'info',
        },
    ];

    if (document.type !== Constants.documentType.library) {
        blockTypes.push(
            ...[
                {
                    label: t('document.navbar.main.editor.left.subnav.addBlock.blocks.image'),
                    value: Constants.blockTypes.file,
                },
                {
                    label: t('document.navbar.main.editor.left.subnav.addBlock.blocks.tableLayout'),
                    value: Constants.blockTypes.blockLayout,
                },
                {
                    label: t('document.navbar.main.editor.left.subnav.addBlock.blocks.table'),
                    value: Constants.blockTypes.table,
                },
            ],
        );

        if (isLabelModuleEnabled) {
            blockTypes.push({
                label: t('document.navbar.main.editor.left.subnav.addBlock.blocks.options'),
                value: Constants.blockTypes.label,
            });
        }

        if (isReimbursementsModuleEnabled && hasReimbursementPermission && document.reimbursementCategoryId) {
            blockTypes.push({
                label: t('document.navbar.main.editor.left.subnav.addBlock.blocks.package'),
                value: Constants.blockTypes.reimbursement,
            });
        }
    }

    const canAddLinkedBlock = document.type !== Constants.documentType.model && !document.modelId;

    if (canAddLinkedBlock) {
        blockTypes.push({
            label: t('document.navbar.main.editor.left.subnav.addBlock.blocks.linked'),
            value: Constants.blockTypes.linked,
        });
    }

    const variantOptions: SelectOption[] = [
        {
            label: t('document.navbar.main.editor.left.subnav.addBlock.variantSelect.all'),
            value: 'all',
        },
        {
            label: t('document.navbar.main.editor.left.subnav.addBlock.variantSelect.none'),
            value: 'none',
        },
        {
            label: t('document.navbar.main.editor.left.subnav.addBlock.variantSelect.selectVariant'),
            value: 'select',
        },
    ];

    return (
        <FormModal
            show={true}
            onHide={handleClose}
            title={t('document.navbar.main.editor.left.subnav.addBlock.addBlock')}
        >
            <Formik
                initialValues={
                    {
                        blockType: defaultBlockType,
                        variantOption: defaultVariantOption,
                        variants: defaultVariants,
                        templateId: undefined,
                    } as Values
                }
                onSubmit={handleSubmit}
                validationSchema={AddBlockModalSchema}
            >
                {({ isSubmitting, isValid, values, setSubmitting }) => (
                    <FForm autoComplete="off">
                        <Modal.Body>
                            {canChangeBlockType && (
                                <FieldSelect
                                    label={`${t('document.navbar.main.editor.left.subnav.addBlock.blockType')}*`}
                                    name="blockType"
                                    options={blockTypes}
                                />
                            )}

                            {values.blockType === Constants.blockTypes.table && <SelectTableTemplate />}

                            <FieldSelect
                                label={`${t('document.navbar.main.editor.left.subnav.addBlock.variants')}*`}
                                name="variantOption"
                                options={variantOptions}
                            />

                            <SelectVariants variants={variants} defaultVariants={defaultVariants} area={area} />

                            {values.blockType === Constants.blockTypes.blockLayout && canChangeBlockType && (
                                <ImportCSVFileAsBlockLayoutContent
                                    handleClose={handleClose}
                                    values={values}
                                    setSubmitting={setSubmitting}
                                    areaBlocks={areaBlocks}
                                    baseVariantId={baseVariant.id}
                                />
                            )}
                        </Modal.Body>

                        <ModalFooter
                            btnSubmitText={t('document.navbar.main.editor.left.subnav.addBlock.addBlock') || ''}
                            isSubmitting={isSubmitting}
                            onHide={handleClose}
                            isValid={isValid}
                        />
                    </FForm>
                )}
            </Formik>
        </FormModal>
    );
}

function SelectVariants({
    variants,
    defaultVariants,
    area,
}: {
    variants: DocumentVariant[];
    defaultVariants: number[];
    area: Area;
}) {
    const { values, setFieldValue } = useFormikContext<Values>();
    const [firstRender, setFirstRender] = useState(true);

    useEffect(() => {
        if (firstRender) {
            setFirstRender(false);

            if (values.variantOption === 'all') {
                setFieldValue(
                    'variants',
                    variants.map((variant) => variant.id),
                );
            }
        } else {
            if (values.variantOption === 'all') {
                setFieldValue(
                    'variants',
                    variants.map((variant) => variant.id),
                );
            } else if (values.variantOption === 'select') {
                setFieldValue('variants', defaultVariants);
            } else {
                setFieldValue('variants', []);
            }
        }
    }, [values.variantOption]);

    const variantOptions = useMemo(() => {
        return filterDeep(
            variants,
            (variantValue: DocumentVariant, key, parent) => {
                if (variantValue.variantGroup || !variantValue.enabled) {
                    return undefined;
                }

                return area.documentVariants.includes(variantValue.id) ? true : undefined;
            },
            {
                childrenPath: ['children'],
            },
        );
    }, [variants, area]);

    if (values.variantOption !== 'select') {
        return null;
    }

    return (
        <MultiVariantSelect
            variants={variantOptions}
            id="select-variants"
            handleChange={(newOptions: DocumentVariantOption[]) =>
                setFieldValue(
                    'variants',
                    newOptions.map((option) => option.value),
                )
            }
            defaultVariants={defaultVariants}
        />
    );
}

function SelectTableTemplate() {
    const tableTemplates = useGetTableTemplates();

    return (
        <FieldSelect
            label="Template*"
            name="templateId"
            options={HelperFunctions.prepareDropdownData(tableTemplates)}
        />
    );
}

const AddBlockModalSchema = Yup.object().shape({
    blockType: Yup.string().required(),
    variants: Yup.array().when('variantOption', {
        is: (variantOption: string) => variantOption === 'select',
        then: Yup.array().min(1),
    }),
    templateId: Yup.number().when('blockType', {
        is: (blockType: string) => blockType === Constants.blockTypes.table,
        then: Yup.number().required(),
    }),
});
