import React, { useMemo } from 'react';
import { generatePath, Link, useHistory } from 'react-router-dom';
import {
    CheckLg,
    ChevronRight,
    ExclamationTriangleFill,
    Lock,
    Pencil,
    ShieldFillCheck,
    Unlock,
} from 'react-bootstrap-icons';
import Constants, { Permissions } from 'config/Constants';
import HelperFunctions from 'pages/global/HelperFunctions';
import ExpandedAreaRow from 'pages/documents/misc/_ReviewDocument/ExpandedAreaRow';
import cx from 'classnames/bind';
import RestrictedContent from 'pages/global/RestrictedContent';
import { useDispatch, useSelector } from 'react-redux';
import { Dropdown, SplitButton } from 'react-bootstrap';
import { AreaTitleRow } from 'pages/documents_v2/views/view/AreaTitleRow';
import AreaOptionsButton from 'pages/documents/misc/_ReviewDocument/AreaOptionsButton';
import SettingsSidebarButton from 'components/SettingsSidebar/SettingsSidebarButton';
import { documentApi, useLockAreaMutation, useUpdateAreasMutation } from 'features/documents/documents';
import { useUserProfile } from 'hooks/useUserProfile';
import { Draggable } from 'react-beautiful-dnd';
import { RxDragHandleDots2 } from 'react-icons/rx';
import { PrimaryButton } from 'components/Buttons';
import { useEntityTemplateParameters } from 'pages/documents_v2/hooks/useEntityTemplateParameters';
import { useGetDocumentVariants } from 'pages/documents_v2/hooks/useGetDocumentVariants';
import { useGetOpLists } from 'pages/operations_list/hooks/useGetOpLists';
import { useTranslation } from 'react-i18next';
import { NumberParam, useQueryParam } from 'use-query-params';
import { useGetUserFullName } from 'hooks/useGetUserFullName';
import { EDIT_AREA_PATH } from 'scenes/DocumentsV2';

export function Area({ area, canMoveArticles, index, document, toggleMoveModal }) {
    const userProfile = useUserProfile();
    const { sidebarFilters } = useSelector((state) => state.documents);
    const restrictions = useEntityTemplateParameters(area, 'area', document);
    const documentVariants = area.documentVariants ?? [];
    const allDocumentVariants = useGetDocumentVariants();
    const [aid, setAid] = useQueryParam('aid', NumberParam);

    const readOnly = document.status === Constants.documentStatus.reviewFinished;

    const { showSkippedArticles = true, showDeletedArticles = true } = sidebarFilters;

    const activeVariants = documentVariants.filter((variantId) => {
        const variant = HelperFunctions.getByValue(allDocumentVariants, 'id', variantId);
        return (
            variant && variant.parent !== null && variant.parentId !== null && variant.id === sidebarFilters?.variant
        );
    });
    const hasActiveVariants = activeVariants.length > 0;

    // Hide if deleted and filter is applied
    if (!showDeletedArticles && area.scheduledForDeletion) {
        return null;
    }

    // Hide if skipped and filter is applied
    if (!showSkippedArticles && area.currentUserStatus?.skipped) {
        return null;
    }

    const expanded = aid === area.id;

    return (
        <Draggable
            draggableId={`area-${area.id}`}
            index={index}
            isDragDisabled={readOnly || expanded || canMoveArticles === false}
        >
            {(provided, snapshot) => (
                <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    className={cx('section-area-row mb-2', {
                        'is-dragging': snapshot.isDragging,
                        'is-expanded': expanded,
                        'is-expanded-draggable': expanded || canMoveArticles === false,
                    })}
                >
                    <AreaItem
                        area={area}
                        canMoveArticles={canMoveArticles}
                        currentUser={userProfile}
                        document={document}
                        dragHandleProps={provided.dragHandleProps}
                        expandRow={expandRow}
                        expanded={expanded}
                        hasActiveVariants={hasActiveVariants}
                        restrictions={restrictions}
                        toggleMoveModal={toggleMoveModal}
                    />
                </div>
            )}
        </Draggable>
    );

    function expandRow() {
        setAid(aid === area.id ? undefined : area.id);
    }
}

function AreaItem({
    area,
    canMoveArticles,
    currentUser,
    document,
    dragHandleProps,
    expandRow,
    expanded,
    hasActiveVariants,
    restrictions,
    toggleMoveModal,
}) {
    const { t } = useTranslation('documents');
    const { sidebarFilters } = useSelector((state) => state.documents);
    const [updateAreas] = useUpdateAreasMutation();
    const [lockArea] = useLockAreaMutation();
    const dispatch = useDispatch();

    const viewAreaLink = generatePath(EDIT_AREA_PATH, {
        documentId: document.id,
        view: 'viewArea',
        areaId: area.id,
    });

    const { showEdits } = sidebarFilters;
    const { currentUserRole } = document;

    const isExpanded = expanded && area.deletedAt === null;
    const isDeleted = area.deletedAt !== null;
    const isLinkedArea = area.type === Constants.areaTypes.linkedArea;
    const isTemplateArticle = area.properties.isTemplate;
    const isLibraryDocument = document.type === Constants.documentType.library;
    const isTemplateDocument = document.type === Constants.documentType.model;
    const areaIsAttachedToAnyVariant = area.documentVariants.length > 1;
    const hasEdits = area.hasOwnProperty('edits') && area.edits > 0 && area.unprocessedEdits > 0;

    return (
        <div className="d-flex align-items-start">
            <div {...dragHandleProps}>
                <RxDragHandleDots2
                    className={cx('drag-handle flex-shrink-0 mt-2 pt-1 mr-2 text-color', {})}
                    size={20}
                />
            </div>
            <div
                className={cx('uk-border default-transition bg-white flex-grow-1', {
                    'uk-border-theme': isExpanded,
                    'uk-border-theme-light': !isExpanded,
                    'uk-text-muted': area.scheduledForDeletion,
                    'area-include-variants': hasActiveVariants,
                })}
                style={{
                    marginLeft: parseInt(area.exportProperties.headingLevel) * 20 - 40,
                }}
            >
                <div
                    className={cx('d-flex align-items-center px-2 py-2', {
                        'uk-border-bottom uk-border-theme-light uk-border-theme-hover': isExpanded,
                        'uk-background-theme-light-hover uk-cursor-pointer': !isDeleted,
                        'bg-white': isLinkedArea,
                    })}
                    onClick={expandRow}
                >
                    <AreaTitleRow area={area} document={document} expanded={isExpanded} />

                    {area.final ? (
                        <div className="uk-text-xsmall uk-text-success uk-animation-fade uk-animation-fast">
                            <CheckLg className="mr-2" size={14} />
                            {t('document.navbar.main.badges.areaUserStatus.final')}
                        </div>
                    ) : area.locked ? (
                        <LockedByUser area={area} />
                    ) : area.readyForCheck ? (
                        <div className="uk-text-xsmall uk-text-success uk-animation-fade uk-animation-fast">
                            <CheckLg className="mr-2" size={14} />
                            {t('document.navbar.main.badges.areaUserStatus.editedByAllMembers')}
                        </div>
                    ) : (
                        area.currentUserStatus.status === Constants.areaUserStatus.done && (
                            <span className="uk-text-xsmall uk-text-success uk-animation-fade uk-animation-fast">
                                <CheckLg className="mr-2" size={14} />
                                {t('document.navbar.main.badges.areaUserStatus.done')}
                            </span>
                        )
                    )}

                    {document?.activeRevisionId && area.hasChanges && (
                        <RestrictedContent
                            permission={Permissions.OperationListEntry.Create}
                            module={Constants.modules.operations_list}
                        >
                            <AreaChangesForOpList document={document} />
                        </RestrictedContent>
                    )}

                    {area.anyBlockHasNoVariants &&
                        !isTemplateDocument &&
                        !isLibraryDocument &&
                        !isLinkedArea &&
                        areaIsAttachedToAnyVariant &&
                        !isTemplateArticle && (
                            <div
                                data-uk-tooltip={`title: ${t('document.navbar.main.editor.navbar.warningNoVariants')}`}
                            >
                                <ExclamationTriangleFill className="text-danger mx-1" />
                            </div>
                        )}

                    {!isDeleted &&
                        (document.status === Constants.documentStatus.reviewInProgress ||
                            document.status === Constants.documentStatus.reviewFinished) &&
                        area.hasOwnProperty('edits') &&
                        area.edits > 0 &&
                        showEdits && (
                            <div
                                className={cx(
                                    'd-flex small flex-shrink-0 align-items-center font-weight-bold ml-2 pr-1 no-content-wrap',
                                    {
                                        'text-warning bg-transparent-orange': hasEdits,
                                        'text-color bg-transparent-blue': !hasEdits,
                                    },
                                )}
                                data-uk-tooltip={`title: ${t('document.navbar.main.tooltips.articleEdits')}`}
                            >
                                <Pencil className="mr-2 pl-1" size={18} />
                                {area.edits - area.unprocessedEdits} / {area.edits}
                            </div>
                        )}

                    {isLinkedArea && area.hasOwnProperty('linkedTo') && (
                        <div className="small text-muted mr-1 ml-2">
                            {area.linkedTo.document.name} <ChevronRight /> {area.linkedTo.section.title}
                        </div>
                    )}

                    <div className="d-none d-lg-block ml-3 flex-shrink-0" onClick={preventRowToggle}>
                        {document.status !== Constants.documentStatus.reviewFinished ? (
                            !area.final ? (
                                area.locked && area.lockedBy !== currentUser.userId ? (
                                    currentUserRole === Constants.userDocumentRole.documentManager ? (
                                        <PrimaryButton
                                            className="d-flex align-items-center"
                                            size="sm"
                                            onClick={(e) => unlockArea(e)}
                                        >
                                            <Unlock size={14} className="mr-2" />
                                            {t('document.navbar.main.btn.unlock')}
                                        </PrimaryButton>
                                    ) : (
                                        <PrimaryButton size="sm" disabled>
                                            <Lock size={14} className="mr-2" />
                                            {t('document.navbar.main.btn.edit')}
                                        </PrimaryButton>
                                    )
                                ) : currentUserRole === Constants.userDocumentRole.spectator || isDeleted ? (
                                    <Link
                                        to={viewAreaLink}
                                        className="btn btn-sm btn-primary"
                                        onClick={preventRowToggle}
                                    >
                                        {t('document.navbar.main.btn.viewMode')}
                                    </Link>
                                ) : (
                                    restrictions?.canEditArticle && (
                                        <>
                                            <AreaOptionsButton
                                                area={area}
                                                document={document}
                                                restoreArea={restoreArea}
                                                toggleMoveModal={() => {
                                                    toggleMoveModal(area);
                                                }}
                                                toggleFinishArea={toggleFinishArea}
                                                restrictions={restrictions}
                                                isMovable={canMoveArticles}
                                            />
                                        </>
                                    )
                                )
                            ) : (
                                <AreaRestoreButton
                                    area={area}
                                    viewAreaLink={viewAreaLink}
                                    userDocumentRole={currentUserRole}
                                    toggleFinishArea={toggleFinishArea}
                                />
                            )
                        ) : (
                            <Link to={viewAreaLink} className="btn btn-sm btn-primary" onClick={preventRowToggle}>
                                {t('document.navbar.main.btn.viewMode')}
                            </Link>
                        )}
                    </div>
                </div>

                {isExpanded && <ExpandedAreaRow area={area} />}
            </div>

            {!isDeleted && (
                <div className="settings-icon ml-4 flex-shrink-0" style={{ marginTop: 8 }}>
                    <SettingsSidebarButton
                        context={{
                            entity: area,
                            entityType: 'area',
                            documentId: document.id,
                            uri: '/api/areas/' + area.id,
                            parentUri: '/api/document/' + document.id,
                            areaId: area.id,
                        }}
                    />
                </div>
            )}
        </div>
    );

    function preventRowToggle(e) {
        e.stopPropagation();
    }

    function restoreArea() {
        if (area.scheduledForDeletion) {
            // restore area that is marked for deletion
            updateAreas({
                documentId: document.id,
                body: [
                    {
                        id: area.id,
                        scheduledForDeletion: false,
                    },
                ],
            });
        }
    }

    function toggleFinishArea() {
        updateAreas({
            documentId: document.id,
            body: [
                {
                    id: area.id,
                    final: !area.final,
                },
            ],
        });
    }

    function unlockArea(e) {
        e.stopPropagation();

        lockArea({
            uri: `/areas/${area.id}/unlock`,
            body: {
                force: true,
            },
        }).then(() => {
            // Invalidate cache
            dispatch(documentApi.util.invalidateTags([{ type: 'Section', id: area.sectionId }]));
        });
    }
}

function AreaRestoreButton({ area, viewAreaLink, userDocumentRole, toggleFinishArea }) {
    const history = useHistory();
    const { t } = useTranslation('documents');

    const handleClick = () => {
        history.push(viewAreaLink);
    };

    const handleSelect = (eventKey) => {
        switch (eventKey) {
            case 'revert':
                toggleFinishArea();
                return;
        }
    };

    return (
        <SplitButton
            id={`area-${area.id}-restore-btn`}
            variant="primary"
            title={t('document.navbar.main.btn.viewMode')}
            menuAlign="right"
            onSelect={handleSelect}
            onClick={handleClick}
            size="sm"
        >
            <Dropdown.Item eventKey="revert" disabled={userDocumentRole !== Constants.userDocumentRole.documentManager}>
                {t('document.navbar.main.btn.revertFinishArea')}
            </Dropdown.Item>
        </SplitButton>
    );
}

function LockedByUser({ area }) {
    const lockedByName = useGetUserFullName(area.lockedBy, false);
    const creatorName = lockedByName.split(' '); // split the name into an array of names
    const initials = creatorName.slice(0, 2).map((name) => name.charAt(0).toUpperCase()); // get the first two initials
    const initialsString = initials.join(''); // join the initials into a string
    const { t } = useTranslation('documents');

    return (
        <div className="d-flex uk-text-xsmall uk-text-muted uk-animation-fade uk-animation-fast">
            <span className="text-muted mt-1 mr-2">{t('document.navbar.main.badges.workedBy')}</span>
            <Dropdown className="document-avatar text-white">
                <Dropdown.Toggle as="span" variant="link" id="user-dropdown" data-uk-tooltip={lockedByName}>
                    {initialsString}
                </Dropdown.Toggle>
            </Dropdown>
        </div>
    );
}

function AreaChangesForOpList({ document }) {
    const opLists = useGetOpLists();
    const { t } = useTranslation('documents');

    const isLinkedToOpList = useMemo(() => {
        return opLists.some((opList) => opList.documentIds.includes(document.id));
    }, [opLists, document]);

    if (!isLinkedToOpList) {
        return null;
    }

    return (
        <ShieldFillCheck
            className="text-warning ml-2 mr-1"
            style={{ fontSize: 14 }}
            data-uk-tooltip={t('document.navbar.main.tooltips.shield')}
        />
    );
}
