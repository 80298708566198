import { useGetNotificationUsersQuery } from 'features/publications/guardApi';
import { useGetGuardApiToken } from 'pages/publications_v2/views/guard/hooks/useGetGuardApiToken';
import { useGetGuardUser } from 'pages/publications_v2/views/guard/hooks/useGetGuardUser';

const emptyArray = [];

export function useGetNotificationUsers() {
    const token = useGetGuardApiToken();
    const guardUser = useGetGuardUser();

    return useGetNotificationUsersQuery(
        { token, id: guardUser?.id },
        {
            selectFromResult: ({ data, isUninitialized, isLoading, isFetching }) => {
                const items = data ?? emptyArray;

                return {
                    notificationUsers: items,
                    isEmpty: items.length === 0 && !isUninitialized && !isLoading && !isFetching,
                    isLoading: isUninitialized || (isFetching && items.length === 0),
                };
            },
            skip: !guardUser,
        },
    );
}
