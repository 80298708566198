import { useParams } from 'react-router-dom';
import { useGetAreaBlocksQuery } from 'features/documents/documents';
import { useContext, useMemo } from 'react';
import { AreaContext } from '../views/Area';
import { useGetBlockIdsInLayout } from 'pages/documents_v2/hooks/useGetBlockIdsInLayout';

const emptyArray = [];

export function useGetAreaBlocks(returnResults = false) {
    const { areaId } = useParams();
    const { activeVariantId } = useContext(AreaContext);

    const results = useGetAreaBlocksQuery(
        { areaId: parseInt(areaId), variantId: activeVariantId },
        {
            selectFromResult: ({ data, currentData, isUninitialized, isFetching }) => ({
                areaData: data,
                isLoading: isUninitialized || (isFetching && !currentData),
            }),
            skip: activeVariantId === 0,
        },
    );

    if (returnResults) {
        return results;
    }

    return results.areaData;
}

export function useGetAreaBlocksNotInLayout() {
    const areaData = useGetAreaBlocks();
    const blockIdsInLayout = useGetBlockIdsInLayout();

    const areaBlocks = useMemo(() => {
        if (!areaData) {
            return emptyArray;
        }

        return areaData.areaBlocks.filter((_block) => !blockIdsInLayout.includes(_block.key));
    }, [areaData, blockIdsInLayout]);

    return areaBlocks;
}
