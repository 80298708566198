import { BooleanParam, JsonParam, NumberParam, useQueryParam } from 'use-query-params';
import { Badge, Col, Form, Modal, Row } from 'react-bootstrap';
import { LightOutlineButton, PrimaryButton } from 'components/Buttons';
import { ChevronRight, CircleFill, CloudDownload, DashCircle } from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';
import { useGetTranslationContainer } from 'pages/translation/hooks/useGetTranslationContainer';
import {
    defaultFilterOptions,
    useGetTranslationLanguageExportFilter,
} from 'pages/translation/hooks/useGetTranslationLanguageExportFilter';
import { useParams } from 'react-router-dom';
import { PaginatedTable, TableProvider } from 'components/PaginatedTable';
import { TableRowSkeleton } from 'components/Skeleton';
import _ from 'lodash';
import cx from 'classnames';
import { saveAs } from 'file-saver';
import { exportFilterDownload } from 'features/translations/translationApi';
import React, { useContext, useState } from 'react';
import Spinner from '../../global/Spinner';
import { EditContext } from 'pages/translation/views/view/EditContextWrapper';

export function ExportModal() {
    const [showExportModal, setShowExportModal] = useQueryParam('export', BooleanParam);
    const [, setFilterOptions] = useQueryParam('filters', JsonParam);
    const [, setPage] = useQueryParam('page', NumberParam);

    if (!showExportModal) {
        return null;
    }

    return <ViewExportModal close={close} />;

    function close() {
        setFilterOptions(undefined);
        setPage(undefined);
        setShowExportModal(undefined);
    }
}

function ViewExportModal({ close }) {
    const translationContainer = useGetTranslationContainer();
    const { translationLanguageId } = useParams();
    const { items, isLoading, totalItems } = useGetTranslationLanguageExportFilter(translationLanguageId);

    return (
        <Modal className="modal-fullscreen" show={true} onHide={close} size="xl">
            <Modal.Header closeButton>
                <Modal.Title>{translationContainer?.name}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col xs={3}>
                        <ExportFilters isLoading={isLoading} />
                    </Col>
                    <Col xs={9}>
                        <ContainerExportContent isLoading={isLoading} items={items} totalItems={totalItems} />
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <ExportButton />

                <PrimaryButton className="ml-2" onClick={close}>
                    Sluiten
                </PrimaryButton>
            </Modal.Footer>
        </Modal>
    );
}

function ContainerExportContent({ items, isLoading, totalItems }) {
    return (
        <TableProvider>
            <PaginatedTable
                items={items}
                clientSidePagination={false}
                itemsPerPage={15}
                colSpan={6}
                renderSearch={false}
                totalItems={totalItems}
            >
                {({ items = [] }) => (
                    <>
                        <thead>
                            <tr>
                                <th>Referentie</th>
                                <th>Bron</th>
                                <th>Vertaling</th>
                                <th>Status</th>
                                <th>Uitgesloten</th>
                                <th>Bron aangepast</th>
                            </tr>
                        </thead>
                        <tbody>
                            {isLoading && <TableRowSkeleton cellCount={6} />}

                            {items.map((unit) => (
                                <UnitRow unit={unit} key={`unit-${unit.id}`} />
                            ))}
                        </tbody>
                    </>
                )}
            </PaginatedTable>
        </TableProvider>
    );
}

function UnitRow({ unit }) {
    const { t } = useTranslation('translations');
    const { segment, contentPath = [] } = unit;

    return (
        <tr>
            <td className="align-top">
                <div>
                    {contentPath.map((path, index) => (
                        <React.Fragment key={`unit-${unit.id}-${index}`}>
                            {!!index && <ChevronRight />}
                            <span className="font-weight-bold">{path}</span>
                        </React.Fragment>
                    ))}
                </div>
            </td>
            <td className="align-top">
                <div className="text-break">{segment.source}</div>
            </td>
            <td className="align-top">
                <div className="text-break">{segment.target}</div>
            </td>
            <td className="align-top">
                <SegmentStateBadge segment={segment} />
            </td>
            <td className="align-top">
                {unit.enabledForTranslation === false && (
                    <div className="d-flex align-items-center text-secondary">
                        <DashCircle className="flex-shrink-0 mr-1" />
                        <div>Uitgesloten</div>
                    </div>
                )}
            </td>
            <td className="align-top">
                {segment.contentHashChanged && (
                    <Badge variant="danger ml-2">{t('translation.navbar.dashboard.translation.sourceUpdated')}</Badge>
                )}
            </td>
        </tr>
    );
}

function SegmentStateBadge({ segment }) {
    return (
        <div className={cx('d-flex align-items-center', getStatusVariant())}>
            <CircleFill size={9} className="flex-shrink-0 mr-2" />
            {getStatusCode()}
        </div>
    );

    function getStatusCode() {
        switch (segment.state) {
            case 'initial':
                return 'Nog niet vertaald';
            case 'translated':
                return 'Niet gecontroleerd';
            case 'reviewed':
                return 'Gecontroleerd';
        }
    }

    function getStatusVariant() {
        switch (segment.state) {
            case 'initial':
                return 'text-danger';
            case 'translated':
                return 'text-warning';
            case 'reviewed':
                return 'text-success';
        }
    }
}

function ExportFilters({ isLoading }) {
    return (
        <div className="mt-3">
            <CheckboxFilter name="content_verified" label="Toon gecontroleerd" disabled={isLoading} />
            <CheckboxFilter name="content_unverified" label="Toon niet gecontroleerd" disabled={isLoading} />
            <CheckboxFilter name="content_not_translated_yet" label="Toon nog niet vertaald" disabled={isLoading} />

            <hr />

            <CheckboxFilter name="content_excluded" label="Toon uitgesloten van vertaling" disabled={isLoading} />
        </div>
    );
}

function CheckboxFilter({ name, label, disabled = false }) {
    const [filterOptions, setFilterOptions] = useQueryParam('filters', JsonParam);

    const combinedFilterOptions = {
        ...defaultFilterOptions,
        ...filterOptions,
    };

    const checked = _.get(combinedFilterOptions, name, true);

    return (
        <Form.Group controlId={name}>
            <Form.Check
                type="checkbox"
                label={label}
                checked={checked}
                disabled={disabled}
                onChange={() => {
                    setFilterOptions({
                        ...combinedFilterOptions,
                        [name]: !checked,
                    });
                }}
            />
        </Form.Group>
    );
}

function ExportButton() {
    const { t } = useTranslation();
    const { translationLanguageId } = useParams();
    const [filterOptions] = useQueryParam('filters', JsonParam);
    const [isLoading, setIsLoading] = useState(false);
    const { selectedEntities } = useContext(EditContext);

    return (
        <LightOutlineButton onClick={handleDownload} disabled={isLoading}>
            {isLoading ? <Spinner className="mr-2" /> : <CloudDownload />}
            <div>
                {t('global:btn.export')} (.docx)
                {isLoading && <>&hellip;</>}
            </div>
        </LightOutlineButton>
    );

    function handleDownload() {
        setIsLoading(true);

        const body = {
            ...defaultFilterOptions,
            ...filterOptions,
            entities: selectedEntities.map(({ entityReference }) => entityReference.uri),
        };

        exportFilterDownload(translationLanguageId, body)
            .then((response) => {
                saveAs(response.data, response.headers['x-suggested-filename'] ?? 'export.docx');
            })
            .catch(async (error) => {
                console.log('error');
            })
            .finally(() => {
                setIsLoading(false);
            });
    }
}
