import { insert, move, useFormikContext } from 'formik';
import { Block } from './blocks/Block';
import { memo, useCallback, useContext, useMemo } from 'react';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { EmptyAreaPlaceholder } from 'pages/documents/misc/_EditArea/EmptyAreaPlaceholder';
import DocumentHelper from '../../../global/DocumentHelper';
import HelperFunctions from '../../../global/HelperFunctions';
import { findBlockDataFromArray, formatData, parseBlockData } from 'pages/global/BlockLayoutHelper';
import _ from 'lodash';
import { BlockEditModals } from './BlockEditModals';
import { AreaContext } from '../Area';
import { useGetDocument } from '../../hooks/useGetDocument';
import { useEntityTemplateParameters } from '../../hooks/useEntityTemplateParameters';
import { entityTypes } from 'pages/translation/config/Constants';
import { EditAreaContext } from 'pages/documents_v2/views/edit_area/EditArea';

export function AreaBlocks({ showRightSide, canAddFreeBlocks = true, canMoveBlocks = true }) {
    const { values, setFieldValue } = useFormikContext();
    const { setDraggedItemKey } = useContext(AreaContext);
    const { areaBlocks = [] } = values;
    const { addBlock } = useContext(EditAreaContext);

    const blocksToRender = useMemo(() => {
        const blockIdsInLayout = DocumentHelper.getBlockIdsInLayout(areaBlocks);

        if (blockIdsInLayout.length === 0) {
            return areaBlocks;
        }

        return areaBlocks.filter((block) => !blockIdsInLayout.includes(block.key));
    }, [areaBlocks]);

    const document = useGetDocument();
    const documentLevelRestrictions = useEntityTemplateParameters(document, entityTypes.DOCUMENT, document);

    const handleBlockChange = useCallback(
        (content, index) => {
            setFieldValue(`areaBlocks.${index}.latestContent`, content);
        },
        [setFieldValue],
    );

    const canOnlyUseAsWhole = documentLevelRestrictions?.composition
        ? !documentLevelRestrictions?.composition?.includes('canOnlyUseAsWhole')
        : canAddFreeBlocks;

    const showEmptyAreaPlaceholder = blocksToRender.length === 0 ? canOnlyUseAsWhole : false;

    return (
        <>
            <DragDropContext
                onBeforeDragStart={({ draggableId }) => setDraggedItemKey(parseInt(draggableId))}
                onDragEnd={onDragEnd}
                isDropDisabled={canMoveBlocks === false}
            >
                <Droppable type="BLOCK" key={`droppable-area-${values.id}`} droppableId={`area|${values.id}`}>
                    {(provided, snapshot) => (
                        <div
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                            style={getListStyle(snapshot.isDraggingOver)}
                        >
                            <RenderAreaBlocks
                                blocksToRender={blocksToRender}
                                isDraggable={canMoveBlocks}
                                areaBlocks={areaBlocks}
                                handleBlockChange={handleBlockChange}
                                showRightSide={showRightSide}
                            />
                            {provided.placeholder}

                            {showEmptyAreaPlaceholder && <EmptyAreaPlaceholder addBlock={addBlock} />}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>

            <BlockEditModals />
        </>
    );

    function moveBlocks(blocks, fromIndex, toIndex) {
        if (toIndex < 0 || toIndex >= blocks.length) {
            return;
        }

        const movedBlocks = move(blocks, fromIndex, toIndex).map((_block, index) => {
            return {
                ..._block,
                sortOrder: index,
            };
        });

        // Update blocks in form with new data
        const newAreaBlocks = areaBlocks
            .map((block) => {
                const updatedBlock = HelperFunctions.getByValue(movedBlocks, 'key', block.key);

                return updatedBlock ?? block;
            })
            .sort(HelperFunctions.dynamicSort('sortOrder'));

        setFieldValue('areaBlocks', newAreaBlocks);
    }

    function handleBlockLayoutDrag(result) {
        let { source, destination, draggableId } = result;

        const sourceIndex = source.droppableId.split('|');
        const sourceBlockKey = +sourceIndex[1];
        const sourceRowIndex = +sourceIndex[2];
        const sourceColIndex = +sourceIndex[3];

        source.blockKey = sourceBlockKey;
        source.rowIndex = sourceRowIndex;
        source.colIndex = sourceColIndex;

        const destinationIndex = destination.droppableId.split('|');
        const destinationBlockKey = +destinationIndex[1];
        const destinationRowIndex = +destinationIndex[2];
        const destinationColIndex = +destinationIndex[3];

        destination.blockKey = destinationBlockKey;
        destination.rowIndex = destinationRowIndex;
        destination.colIndex = destinationColIndex;

        // Block new in the layout?
        if (source.droppableId.startsWith('area|')) {
            const currentLayoutBlockContent = findBlockDataFromArray(destinationBlockKey, areaBlocks);
            const currentData = currentLayoutBlockContent.data;

            currentData[destinationRowIndex][destinationColIndex] = insertBlockLayout(
                currentData[destinationRowIndex][destinationColIndex],
                destination.index,
                {
                    id: parseInt(draggableId),
                },
            );

            const newContent = formatData(currentData);
            const blockIndex = areaBlocks.findIndex((block) => block.key === destinationBlockKey);

            if (blockIndex >= 0) {
                setFieldValue(`areaBlocks.${blockIndex}.latestContent`, parseBlockData(newContent));
            }

            return;
        }

        // Block moved within the same cell?
        if (
            sourceBlockKey === destinationBlockKey &&
            sourceRowIndex === destinationRowIndex &&
            sourceColIndex === destinationColIndex
        ) {
            const currentLayoutBlockContent = findBlockDataFromArray(sourceBlockKey, areaBlocks);
            const currentData = currentLayoutBlockContent.data;

            currentData[sourceRowIndex][sourceColIndex] = reorderBlockLayout(
                currentData[sourceRowIndex][sourceColIndex],
                source.index,
                destination.index,
            );

            const newContent = formatData(currentData);
            const blockIndex = areaBlocks.findIndex((block) => block.key === destinationBlockKey);

            if (blockIndex >= 0) {
                setFieldValue(`areaBlocks.${blockIndex}.latestContent`, parseBlockData(newContent));
            }

            return;
        }

        // Block moved another cell
        const currentBlockContent = findBlockDataFromArray(sourceBlockKey, areaBlocks);
        const currentData = currentBlockContent.data;

        const items = moveBlockLayout(
            currentData[sourceRowIndex][sourceColIndex],
            currentData[destinationRowIndex][destinationColIndex],
            source,
            destination,
        );

        currentData[sourceRowIndex][sourceColIndex] = items[0];
        currentData[destinationRowIndex][destinationColIndex] = items[1];

        const newContent = formatData(currentData);
        const blockIndex = areaBlocks.findIndex((block) => block.key === destinationBlockKey);

        if (blockIndex >= 0) {
            setFieldValue(`areaBlocks.${blockIndex}.latestContent`, parseBlockData(newContent));
        }
    }

    function removeBlockFromLayout(result) {
        let { source, destination, draggableId } = result;

        const sourceIndex = source.droppableId.split('|');
        const sourceBlockKey = +sourceIndex[1];
        const sourceRowIndex = +sourceIndex[2];
        const sourceColIndex = +sourceIndex[3];

        const currentLayoutBlockContent = findBlockDataFromArray(sourceBlockKey, areaBlocks);

        const newSourceState = currentLayoutBlockContent.data;
        removeBlockLayout(newSourceState[sourceRowIndex][sourceColIndex], source.index);

        const newContent = formatData(newSourceState);
        const blockIndex = areaBlocks.findIndex((block) => block.key === sourceBlockKey);

        if (blockIndex >= 0) {
            // Update sort order
            const draggedBlock = HelperFunctions.getByValue(areaBlocks, 'key', parseInt(draggableId));

            if (draggedBlock) {
                const movedBlocks = insert(blocksToRender, destination.index, draggedBlock).map((_block, index) => {
                    return {
                        ..._block,
                        sortOrder: index,
                    };
                });

                const newAreaBlocks = areaBlocks
                    .map((block) => {
                        const updatedBlock = HelperFunctions.getByValue(movedBlocks, 'key', block.key);
                        const _block = updatedBlock ?? block;

                        if (_block.key === sourceBlockKey) {
                            // Update latest content of block layout
                            _block.latestContent = parseBlockData(newContent);
                            return _block;
                        }

                        return _block;
                    })
                    .sort(HelperFunctions.dynamicSort('sortOrder'));

                setFieldValue('areaBlocks', newAreaBlocks);
            }
        }
    }

    function onDragEnd(result) {
        setDraggedItemKey(undefined);

        const { source, destination } = result;

        if (!destination) {
            return;
        }

        // Dropped on area
        if (destination.droppableId.startsWith('area|')) {
            if (source.droppableId.startsWith('block|')) {
                removeBlockFromLayout(result);
                return;
            }

            moveBlocks(blocksToRender, source.index, destination.index);
            return;
        }

        // Dropped on block layout
        if (destination.droppableId.startsWith('block|')) {
            handleBlockLayoutDrag(result);
            return;
        }
    }
}

const BlockMemo = memo(Block);

function RenderAreaBlocks({
    blocksToRender = [],
    isDraggable = true,
    areaBlocks = [],
    handleBlockChange,
    showRightSide,
}) {
    return (
        <>
            {blocksToRender.map((block, index) => (
                <BlockMemo
                    isDraggable={isDraggable}
                    block={block}
                    renderedIndex={index}
                    index={areaBlocks.findIndex((_block) => _block.key === block.key)}
                    handleBlockChange={handleBlockChange}
                    key={`block-${block.key}-index-${index}`}
                    showRightSide={showRightSide}
                />
            ))}
        </>
    );
}

const getListStyle = (isDraggingOver) => ({
    borderRadius: 5,
    border: isDraggingOver ? '2px dashed #00e676' : '2px solid transparent',
    background: isDraggingOver ? '#00e67614' : undefined,
    paddingTop: '1.5rem',
});

/**
 * Moves an item from one list to another list.
 */
const moveBlockLayout = (source, destination, droppableSource, droppableDestination) => {
    const sourceClone = Array.from(source);
    const destClone = Array.from(destination);
    const [removed] = sourceClone.splice(droppableSource.index, 1);

    destClone.splice(droppableDestination.index, 0, removed);

    return [sourceClone, destClone];
};

const insertBlockLayout = (list, index, item) => {
    const workingCopy = _.cloneDeep(list);
    workingCopy.splice(index, 0, item);

    return workingCopy;
};

const removeBlockLayout = (list, index) => {
    list.splice(index, 1);
};

const reorderBlockLayout = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};
