import { useState } from 'react';
import { Modal } from 'react-bootstrap';
import Setup from './Setup';
import Searched from './Searched';
import Loading from './Loading';
import Error from './Error';
import { useTranslation } from 'react-i18next';
import { documentApi } from 'features/documents/documents';

/**
 * Search tags (only the specified) for the given searchquery, shows the occurrances which can then be replaced.
 * This modal works both for global tags and document tags of a specified document.
 *
 * @param handleClose
 * @param documentId
 * @returns {JSX.Element}
 * @constructor
 */
function TagSearchAndReplaceModal({ handleClose, documentId = null }) {
    const { t } = useTranslation('documents');
    const [getDocumentTagSearchQuery] = documentApi.useLazyGetDocumentTagSearchQuery();

    const [searchMatches, setSearchMatches] = useState();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState();

    /**
     * Reset the state parameters so the 'wizard' can be run through from the beginning.
     *
     * We won't have to reset the state parameters of the child components as they will be reset automatically once
     * the component is unmounted.
     */
    const resetState = () => {
        setError(null);
        setSearchMatches(null);
    };

    const search = (searchQuery, tagField, variantId) => {
        setLoading(true);

        getDocumentTagSearchQuery({
            documentId,
            params: {
                q: searchQuery,
                tagField,
                variantId,
            },
        })
            .then((result) => {
                setSearchMatches(result?.data ?? []);
                setLoading(false);
            })
            .catch(() => {
                setError(true);
            });
    };

    return (
        <Modal show={true} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>{t('document.navbar.tags.searchModal.title')}</Modal.Title>
            </Modal.Header>

            {error ? (
                <Error close={handleClose} resetState={resetState} />
            ) : (
                <>
                    {loading ? (
                        <Loading />
                    ) : (
                        <>
                            {!searchMatches ? (
                                <Setup close={handleClose} search={search} documentId={documentId} />
                            ) : (
                                <Searched
                                    close={handleClose}
                                    setError={setError}
                                    resetState={resetState}
                                    searchMatches={searchMatches}
                                    documentId={documentId}
                                />
                            )}
                        </>
                    )}
                </>
            )}
        </Modal>
    );
}

export default TagSearchAndReplaceModal;
