import React from 'react';
import HelperFunctions from '../../../global/HelperFunctions';
import DocumentHelper from '../../../global/DocumentHelper';
import { useParams } from 'react-router-dom';
import { Link45deg } from 'react-bootstrap-icons';
import { useGetBaseVariant } from 'pages/documents_v2/hooks/useGetBaseVariant';
import { useGetDocument } from 'pages/documents_v2/hooks/useGetDocument';

const emptyText = (
    <>
        <span className="linked-icon">
            <Link45deg size={24} />
        </span>
    </>
);

export const LinkedBlockLabel = ({ block }) => {
    if (!block.hasOwnProperty('latestContent') || block.latestContent === null || block.latestContent === '') {
        return <ToolbarLabel text={emptyText} />;
    }

    const data = HelperFunctions.tryParseJSON(block.latestContent);

    if (data === false || !data.hasOwnProperty('areaId')) {
        return <ToolbarLabel text={emptyText} />;
    }

    return <LinkedBlockLabelContent data={data} />;
};

function LinkedBlockLabelContent({ data }) {
    const { documentId } = useParams();
    const blockDocumentId = data.documentId ?? documentId;
    const document = useGetDocument(undefined, true);
    const baseVariant = useGetBaseVariant();

    if (document === undefined || baseVariant === undefined) {
        return <ToolbarLabel text={emptyText} />;
    }

    const area = HelperFunctions.findInDocument({ type: 'area', id: data.areaId }, document);

    if (area === undefined) {
        return <ToolbarLabel text={emptyText} />;
    }

    const prefix = DocumentHelper.getPrefix(area, baseVariant.id);
    let title = (prefix ? prefix + ': ' : '') + area.title;

    if (parseInt(documentId) !== parseInt(blockDocumentId)) {
        title = 'Bibliotheek > ' + title;
    }

    const tooltip = `Gekoppeld aan "${title}"`;

    return <ToolbarLabel text={emptyText} tooltip={tooltip} />;
}

export const ToolbarLabel = ({ className = 'uk-background-theme-light flex-shrink-0', text, tooltip }) => {
    return (
        <span className={'px-2 mr-1 uk-text-xsmall ' + className} data-uk-tooltip={tooltip}>
            {text}
        </span>
    );
};
