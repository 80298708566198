import { createEntityAdapter, createSelector, createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';
import { views } from 'pages/documents_v2/config/views';

const DocumentAdapter = createEntityAdapter();
const SectionAdapter = createEntityAdapter();
const AreaAdapter = createEntityAdapter();
const DocumentGroupAdapter = createEntityAdapter();
const PackageGroupAdapter = createEntityAdapter();
const ChangesAdapter = createEntityAdapter();

/**
 * Documents
 */
const documentsSlice = createSlice({
    name: 'documents_v2',
    initialState: {
        documents: DocumentAdapter.getInitialState(),
        sections: SectionAdapter.getInitialState(),
        areas: AreaAdapter.getInitialState(),
        documentGroups: DocumentGroupAdapter.getInitialState(),
        packageGroups: PackageGroupAdapter.getInitialState(),
        changes: ChangesAdapter.getInitialState(),
        rightSideEditorView: views.CHANGES,
        blockPositions: {},
        loading: {
            fetchDocuments: false,
        },
        filters: {
            search: '',
        },
    },
    reducers: {
        setFilter: {
            reducer(state, action) {
                const { key, value } = action.payload;
                state.filters[key] = value;
            },
        },
        setRightSideEditorView: {
            reducer(state, action) {
                state.rightSideEditorView = action.payload;
            },
        },
        setBlockPosition: {
            reducer(state, action) {
                const { blockId, rect, view } = action.payload;
                const currentRect = state.blockPositions[blockId] ?? {};

                state.blockPositions[blockId] = {
                    ...currentRect,
                    [view]: rect,
                };
            },
        },
    },
    extraReducers: {
        ['security/resetState']: (state) => {
            Object.assign(state, documentsSlice.getInitialState());
        },
    },
});

export const { setFilter, setRightSideEditorView, setBlockPosition } = documentsSlice.actions;

export default documentsSlice.reducer;

export const getBlockHeight = (blockId, view) =>
    createSelector([(state) => state.documents_v2.blockPositions], (blockPositions) => {
        return _.get(blockPositions, [blockId, view, 'height'], 0);
    });
