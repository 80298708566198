import React, { memo, useMemo } from 'react';
import {
    ExclamationTriangleFill,
    FileEarmarkBreak,
    Grid,
    Link45deg,
    TagsFill,
    UiChecksGrid,
} from 'react-bootstrap-icons';
import { Badge } from 'react-bootstrap';
import { useGetAreaBlocks } from '../../../hooks/useGetAreaBlocks';
import { useGetDocument } from '../../../hooks/useGetDocument';
import { BlockType } from './BlockType';
import { useTranslation } from 'react-i18next';
import { useGetLabelEntitiesQuery } from '../../../../../features/metadata/metadata';
import { entityTypes } from '../../../../translation/config/Constants';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import isEqual from 'react-fast-compare';
import Constants from '../../../../../config/Constants';

export const BlockLabels = memo(({ block, isInsideBlockLayout, parentBlock }) => {
    const { documentId } = useParams();
    const { t } = useTranslation('documents');
    const areaData = useGetAreaBlocks();
    const document = useGetDocument();
    const { activeOrganisation } = useSelector((state) => state.security);

    const documentVariantsFromArea = areaData?.area?.documentVariants ?? [];

    const blockHasAllVariants = useMemo(() => {
        return documentVariantsFromArea.length <= block.documentVariants.length;
    }, [block.documentVariants, documentVariantsFromArea]);

    const hasLinkedBlocks = block.hasOwnProperty('linkedBlocks') && block.linkedBlocks.length > 0;
    const hasVariants = block.documentVariants.length > 1;
    const areaIsTemplate = areaData?.area.properties.isTemplate;
    const documentIsTemplate = document.type === Constants.documentType.model;
    const forceBreak = useMemo(() => {
        if (block) {
            const hasPropertiesForceNewPage = block.properties.forceNewPage && block.properties.forceNewPage === true;

            const hasPropertiesForceNewPageVariants =
                block.properties.forceNewPageVariants !== undefined && block.properties.forceNewPageVariants.length > 0;

            return hasPropertiesForceNewPage || hasPropertiesForceNewPageVariants;
        }
        return false;
    }, [block]);

    const documentIsLibrary = document.type === Constants.documentType.library;

    const { entityLabelEntities } = useGetLabelEntitiesQuery(
        {
            entityType: entityTypes.BLOCK,
            organisationId: activeOrganisation,
            documentId: parseInt(documentId),
        },
        {
            selectFromResult: ({ data }) => ({
                entityLabelEntities: data
                    ? data.filter((labelEntity) => parseInt(labelEntity.entityId) === block.id)
                    : [],
            }),
        },
    );

    // Hide variants icon for the first block in a text explanation block
    const hideVariantsIcon = useMemo(() => {
        if (
            isInsideBlockLayout &&
            parentBlock &&
            parentBlock.properties.type === 'textExplanation' &&
            block.properties.textLayout === 'default'
        ) {
            return true;
        }

        return false;
    }, [isInsideBlockLayout, parentBlock, block]);

    const hasLabels = entityLabelEntities.length > 0;

    return (
        <>
            <div className="block-edit-labels mr-1 uk-button-group uk-button-group-small uk-margin-small-right px-2 align-items-center">
                {block.new && (
                    <Badge
                        variant="success"
                        style={{ paddingTop: 2, paddingBottom: 2, lineHeight: '18px' }}
                        className="mr-2 px-2"
                    >
                        {t('document.navbar.main.editor.left.blocks.badges.newBlock')}
                    </Badge>
                )}

                {forceBreak && (
                    <button className="uk-button uk-button-small uk-button-icon">
                        <span className="uk-icon">
                            <FileEarmarkBreak size={15} />
                        </span>
                    </button>
                )}

                {hasLabels && (
                    <button className="uk-button uk-button-small uk-button-icon uk-cursor-pointer">
                        <span className="uk-icon">
                            <TagsFill size={15} />
                        </span>
                    </button>
                )}

                {hasLinkedBlocks && (
                    <button className="uk-button uk-button-small uk-button-icon">
                        <span className="uk-icon text-danger">
                            <Link45deg size={16} />
                        </span>
                    </button>
                )}

                {!hideVariantsIcon && (
                    <>
                        {!hasVariants && !areaIsTemplate && !documentIsTemplate && !documentIsLibrary && (
                            <button className="uk-button uk-button-small uk-button-icon uk-cursor-pointer btn-edit-variants btn-edit-variants-warning btn-edit-variants-warning-icon">
                                <span className="uk-icon">
                                    <Grid size={15} />
                                </span>

                                <ExclamationTriangleFill size={16} className="triangle-icon" />
                            </button>
                        )}

                        {!blockHasAllVariants && hasVariants && !areaIsTemplate && !documentIsTemplate && (
                            <button className="uk-button uk-button-small uk-button-icon uk-cursor-pointer btn-edit-variants btn-edit-variants-warning">
                                <span className="uk-icon">
                                    <UiChecksGrid size={15} />
                                </span>
                            </button>
                        )}
                    </>
                )}

                <BlockType block={block} isInsideBlockLayout={isInsideBlockLayout} />
            </div>
        </>
    );
}, isEqual);

export function ToolbarLabel({ text, className = 'uk-background-theme-light' }) {
    return <span className={`px-2 mr-1 uk-text-xsmall ${className}`}>{text}</span>;
}
