import Constants from '../../../../../config/Constants';
import { ToolbarLabel } from './BlockLabels';
import HelperFunctions from '../../../../global/HelperFunctions';
import { useGetDocument } from '../../../hooks/useGetDocument';
import React from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export function BlockType({ block, isInsideBlockLayout = false }) {
    const { type, properties = {} } = block;
    const { textLayout = 'default' } = properties;
    const { t } = useTranslation('documents');

    if (type === Constants.blockTypes.blockLayout && properties.type === Constants.blockTypes.textExplanation) {
        return (
            <ToolbarLabel
                text={t('document.navbar.main.editor.left.blocks.badges.blockType.textWithExplanation')}
                className="uk-background-theme-light"
            />
        );
    }

    if (type === Constants.blockTypes.linked) {
        return <LinkedBlockType block={block} />;
    }
    const blockTypeNames = {
        [Constants.blockTypes.text]: t('document.navbar.main.editor.left.blocks.badges.blockType.text'),
        [Constants.blockTypes.file]: t('document.navbar.main.editor.left.blocks.badges.blockType.image'),
        [Constants.blockTypes.label]: t('document.navbar.main.editor.left.blocks.badges.blockType.options'),
        [Constants.blockTypes.table]: t('document.navbar.main.editor.left.blocks.badges.blockType.table'),
        [Constants.blockTypes.reimbursement]: t('document.navbar.main.editor.left.blocks.badges.blockType.package'),
        [Constants.blockTypes.blockLayout]: t('document.navbar.main.editor.left.blocks.badges.blockType.tableLayout'),
    };

    const blockLayoutNames = {
        default: t('document.navbar.main.editor.left.blocks.badges.blockType.text'),
        intro: t('document.navbar.main.editor.left.blocks.badges.blockType.introtext'),
        attention: t('document.navbar.main.editor.left.blocks.badges.blockType.attention'),
        info: t('document.navbar.main.editor.left.blocks.badges.blockType.info'),
        'more-info': t('document.navbar.main.editor.left.blocks.badges.blockType.moreInfo'),
        special: t('document.navbar.main.editor.left.blocks.badges.blockType.special'),
    };

    const blockLayoutClasses = {
        default: 'uk-background-theme-light',
        intro: 'uk-background-theme-light uk-text-bold',
        attention: 'uk-background-warning-light',
        info: 'uk-background-special',
        'more-info': 'uk-background-special',
        special: 'uk-background-special',
    };

    const text = type === Constants.blockTypes.text ? blockLayoutNames[textLayout] : blockTypeNames[type];
    const className =
        type === Constants.blockTypes.text
            ? blockLayoutClasses[textLayout]
            : type === Constants.blockTypes.linked
            ? blockLayoutClasses.info
            : undefined;

    if (isInsideBlockLayout && text === t('document.navbar.main.editor.left.blocks.badges.blockType.text')) {
        return null; // Return null to prevent rendering the ToolbarLabel
    }

    return (
        <ToolbarLabel
            text={text ?? t('document.navbar.main.editor.left.blocks.badges.blockType.unknown')}
            className={className}
        />
    );
}

function LinkedBlockType({ block }) {
    const { documentId } = useParams();
    const data = HelperFunctions.tryParseJSON(block.latestContent, undefined);
    const blockDocumentId = data?.documentId ?? documentId;
    const document = useGetDocument(data?.documentId, true);
    const { t } = useTranslation('documents');

    const emptyText = (
        <ToolbarLabel
            text={t('document.navbar.main.editor.left.blocks.badges.blockType.linked')}
            className="uk-background-special"
        />
    );

    if (!document) {
        return emptyText;
    }

    const area = HelperFunctions.findInDocument({ type: 'area', id: data.areaId }, document);

    if (area === undefined) {
        return emptyText;
    }

    const section = HelperFunctions.findInDocument({ type: 'section', id: area.sectionId }, document);

    if (section === undefined) {
        return emptyText;
    }

    let title;

    if (parseInt(documentId) !== parseInt(blockDocumentId)) {
        title = `${document.name} > ${section.title} > ${area.title}`;
    } else {
        title = `${section.title} > ${area.title}`;
    }

    return (
        <ToolbarLabel
            text={`${t('document.navbar.main.editor.left.blocks.badges.blockType.linked')} ${title}`}
            className="uk-background-special"
        />
    );
}
